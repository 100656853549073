import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { IconButton, Button, Hidden, Container, Paper, CircularProgress, Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useLoading } from '../../components/utils/LoadingContext';
import { Development, DevelopmentSearchResponse, LookupItem, SiteSettings } from '../../types/TypesIndex';
import ListingDetailContent from '../../types/Listing/ListingDetailContent';
import MetaTags from '../../components/utils/seo/MetaTags';
import { DevelopmentDetailBreadcrumb, DevelopmentDetailDescription, DevelopmentDetailToolBar, DevelopmentDetailAvailableUnit, DevelopmentDetailMultiMedia } from '../../components/development/DevelopmentComponentsIndex';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchDevelopmentDataByDevelopmentId } from '../../services/DevelopmentService';
import { fetchListingDataByDevelopmentId } from '../../services/ListingService';
import ListingDetail from '../../types/Listing/ListingDetail';
import Location from '../../components/utils/Location';
import { PAGE_TYPE } from '../../constants/constants';


// SEO Shortlink Pattern
interface DevelopmentParams extends Record<string, string | undefined> {
    developmentUrl: string;
    developmentId: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const DevelopmentDetail: React.FC = () => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const { showLoading, hideLoading } = useLoading();
    const params = useParams<DevelopmentParams>();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Hook Declaration
    const [development, setDevelopment] = useState<Development | any>();
    const [listings, setListings] = useState<ListingDetail[]>([]);
    const [developmentListingCount, setDevelopmentListingCount] = useState(0);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);

    // UseEffect Data Loading
    useEffect(() => {
        showLoading();
        // Simulate fetching data
        setTimeout(() => {
            hideLoading();
        }, 1000);
    }, []);

    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    useEffect(() => {

        const loadDataSource = async () => {

            try {

                setIsLoading(true);

                await fetchLookupTranslations().then((lookupList) => {
                    if (lookupList == null) {
                        console.log("lookupList loads nothing");
                    } else {
                        setLookupList(lookupList);
                    }
                });

                await fetchSiteSettings().then((siteSettings) => {

                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                        noDevelopmentFound();
                        return null;
                    } else {

                        setSiteSettings(siteSettings);

                        // To Do: Url with Developemnt Virtual Url
                        var developmentId = params.developmentId ?? params.url;
                        if (developmentId === undefined || developmentId === null) {
                            noDevelopmentFound();
                            return null;
                        } else {

                            fetchDevelopmentDataByDevelopmentId(developmentId).then(async (development) => {

                                if (development === null) {
                                    noDevelopmentFound();
                                    return null;
                                } else {

                                    setDevelopment(development);
                                    setIsLoading(false);

                                    if (developmentId !== null && typeof (developmentId) !== "undefined") {
                                        const listingData = await fetchListingDataByDevelopmentId(developmentId);
                                        if (listingData !== null && listingData.value !== null && listingData.value.length > 0) {
                                            setListings(listingData.value?.map(l => l.content));
                                        }

                                        setDevelopmentListingCount(listingData['@odata.count']);
                                    }
                                }
                            });

                        }
                    }
                });

            } catch (error) {
                console.error('Development Detail: failed to load data source', error);
            }
        };

        loadDataSource();

    }, [i18n.language]);

    // Events
    const noDevelopmentFound = async () => {
        let navigateUrl = "/DevelopmentSearch";
        navigate(navigateUrl);
        return null;
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
            </div>
        );
    }

    return (
        <div>
            {/* To Do: SEO Meta Tags via Helmet */}
            <MetaTags metaTags={{ title: development?.DevelopmentName, description: development?.DevelopmentName }} />

            <Container maxWidth="xl" className="text-align-left light" sx={{ paddingTop: { xs: '1rem', md: '3rem' }, paddingBottom: { xs: '2.5rem', md: '3rem' } }}>

                <Stack direction="column" spacing={5}>

                    <Stack direction="column" spacing={{ xs: 2, md: 5 }}>
                        <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" alignItems="center" justifyContent="space-between">

                            {/* Breadcrumbs (component) */}
                            <DevelopmentDetailBreadcrumb development={development} />

                            {/* Toolbar (component) */}
                            {/*<DevelopmentDetailToolBar toolBar={{}} />*/}

                        </Stack>
                        <DevelopmentDetailMultiMedia multiMedia={{ development: development, siteSettings: siteSettings }} />
                    </Stack>

                    {/* Description (component) */}
                    <DevelopmentDetailDescription description={{ development: development, developmentListingCount: developmentListingCount, siteSettings: siteSettings, scrollToElement }} />

                    {/* Available Units (component) */}
                    <DevelopmentDetailAvailableUnit detailAvailableUnit={{ listings: listings, development: development, siteSettings: siteSettings, lookupList: lookupList }} />

                    {/* Property Location (component) */}
                    {/* <DevelopmentDetailLocation location={{ development: development, siteSettings: siteSettings, scrollToElement }} /> */}
                    <Location
                        longitude={development?.Coordinates.coordinates[0]}
                        latitude={development?.Coordinates.coordinates[1]}
                        scrollToElement={scrollToElement}
                        siteSettings={siteSettings}
                        title={t('Listing_PropertyLocation')}
                    />

                </Stack>

            </Container>

        </div>

    );

};
export default DevelopmentDetail;