import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    type?: string;
    ogTitle?: string;
}

const MetaTags: React.FC<{ metaTags: MetaTagsProps }> = ({ metaTags }) => {

    if (metaTags.type == null) {
        metaTags.type = 'website';
    }
    // To Do: Populate the SEO Meta Tags items 
    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <title>{metaTags.title}</title>
                <meta name="title" content={metaTags.title} />
                <meta name="description" content={metaTags.description} />

                <meta property="og:url" content={metaTags.url} />
                <meta property="og:type" content={metaTags.type} />
                <meta property="og:title" content={metaTags.ogTitle ? metaTags.ogTitle : metaTags.title} />
                <meta property="og:description" content={metaTags.description} />
                <meta property="og:image" content={metaTags.image} />
            </Helmet>
        </ >
    );
};

export default MetaTags;
