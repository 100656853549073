import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Stack, Paper, Hidden, useMediaQuery, useTheme, Skeleton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { ProfilePropertiesCountWithLink, ProfileContactInfo, ProfilePhoto, ProfileSocialChatIntegration, ProfileTeamNameAndLogo, ProfileSocialMediaLinks, ProfileBreadcrumbs } from '../../components/profile/ProfileComponentsIndex';
import { ContactMePopup, ContactMobileDrawer } from '../utils';
import { Team, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { PAGE_TYPE } from '../../constants/constants';


interface ProfileTeamCardProps {
    team?: Team;
    teamListingCount: number;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ProfileTeamCard: React.FC<{ profileTeamCard: ProfileTeamCardProps }> = ({ profileTeamCard }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or perform data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    // Hook Declaration
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // Event Handlers
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (isLoading) {
        return (
            <Grid container spacing={8} alignItems="center">
                {/* Left Column */}
                <Grid item xs={12} md={8}>
                    <Stack direction="column" spacing={5} useFlexGap>
                        <Skeleton variant="text" width="60%" />
                        <Stack spacing={3}>
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="rectangular" width={120} height={40} />
                            <Skeleton variant="text" width={180} />
                            <Skeleton variant="text" width={180} />
                        </Stack>
                    </Stack>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems="center">
                        <Skeleton variant="rectangular" width={240} height={320} />
                        <Skeleton variant="text" width="60%" />
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {/* Desktop Layout */}
            <Hidden mdDown>

                {/* Two column grid */}
                <Grid container spacing={8} alignItems="center">

                    {/* Left column */}
                    <Grid item xs={12} md={8}>

                        <Stack direction="column" spacing={5} useFlexGap>

                            {/* Breadcrumbs */}
                            <ProfileBreadcrumbs
                                profileBreadcrumbs={{
                                    page: PAGE_TYPE.TEAM,
                                    name: profileTeamCard.team?.TeamName.toString(),
                                    team: profileTeamCard.team,
                                    siteSettings: profileTeamCard.siteSettings
                                }} />

                            <Stack spacing={3}>

                                <Stack spacing={2}>

                                    {/* Team Name */}
                                    {/* To Do: SEO Micro Data */}
                                    <Typography className="h2" variant="h2">
                                        {profileTeamCard.team?.TeamName}
                                    </Typography>


                                </Stack>

                                <Stack spacing={2}>

                                    <Stack
                                        useFlexGap
                                        direction={{ xs: 'column', md: 'row' }} // Column layout for mobile, row layout for desktop
                                        alignItems={{ xs: 'flex-start', md: 'center' }} // Align items to flex-start on mobile
                                        spacing={{ xs: 1, md: 2 }} // Spacing between items
                                        className="gridItem"
                                        sx={{ marginTop: '1.5rem', marginBottom: '1rem', alignItems: 'center' }}
                                    >

                                        <Button type="button" role="button" aria-label={t('OfficeFull_ContactUs')} className="primary-button" variant="contained" onClick={handleOpenDialog}>{t('OfficeFull_ContactUs')}</Button>

                                        {/* Comments: Team chat integration  */}
                                        {profileTeamCard.team && <ProfileSocialChatIntegration
                                            phone={profileTeamCard.team?.Phone}
                                            email={profileTeamCard.team?.Email}
                                            whatsApp={profileTeamCard.team?.WhatsAppNumber}
                                            facebookMessengerID=''
                                            viberID=''
                                            telegramID=''
                                            lineChat=''
                                            lineChatID={profileTeamCard.team?.LINEChat}
                                            weChat={profileTeamCard.team?.WeChat}
                                            weChatID=''
                                            isMobile={isMobile}
                                        />}

                                    </Stack>

                                    {/* Global Profilce Contact Info (component)  */}
                                    {profileTeamCard.team &&
                                        <ProfileContactInfo
                                            hitType={'TeamProfileViewTeamPhone_New'}
                                            contactInfo={{
                                                AgentPhone: profileTeamCard.team?.Phone,
                                                DirectDialPhoneNumber: '',
                                                OfficePhone: profileTeamCard.team?.OfficePhone,
                                                Email: profileTeamCard.team?.Email,
                                                siteSettings: profileTeamCard.siteSettings,
                                                TeamId: profileTeamCard.team?.TeamId,
                                                OfficeId: profileTeamCard.team?.OfficeId,
                                                RegionId: profileTeamCard.team?.RegionId
                                            }} />}


                                </Stack>

                            </Stack>

                        </Stack>

                    </Grid>

                    {/* Right column */}
                    <Grid item xs={12} md={4}>

                        <Stack spacing={2}>

                            {/* Team Name */}
                            {/* To Do: SEO Micro Data */}
                            {/* Global Agent/Office/Team Photos (component) */}
                            {profileTeamCard.team && <ProfilePhoto
                                profilePhoto={{
                                    imagePath: getProfileImagePath(profileTeamCard.team.RegionId, profileTeamCard.team.TeamPhotoID),
                                    alt: profileTeamCard.team?.TeamName,
                                    title: profileTeamCard.team?.TeamName
                                }} />}


                            {/* Global Agent/Office Properties Link (component) */}
                            {profileTeamCard.team && <ProfilePropertiesCountWithLink
                                team={profileTeamCard.team}
                                page={PAGE_TYPE.TEAM}
                                listingCount={profileTeamCard.teamListingCount}
                            />}


                            {/* To Do: Social media links */}
                            {/* Global Social Media Links (component) */}
                            {profileTeamCard.team && <ProfileSocialMediaLinks
                                socialMedias={profileTeamCard.team?.SocialMedias}
                                isSocialMediaEnabled={profileTeamCard.team?.IsSocialMediaEnabled}
                                lookupList={profileTeamCard.lookupList}
                                siteSettings={profileTeamCard.siteSettings}
                            />}

                        </Stack>

                    </Grid>

                    {/* Global Contact Me Popup (component) */}
                    <ContactMePopup data={{
                        team: profileTeamCard.team,
                        open: openDialog,
                        handleOpenDialog: handleOpenDialog,
                        handleCloseDialog: handleCloseDialog
                    }} />


                </Grid>

            </Hidden>

            {/* Mobile Layout */}
            <Hidden mdUp>

                <Stack direction="column" spacing={5} useFlexGap>

                    {/* Breadcrumbs */}
                    <ProfileBreadcrumbs
                        profileBreadcrumbs={{
                            page: PAGE_TYPE.TEAM,
                            name: profileTeamCard.team?.TeamName.toString(),
                            team: profileTeamCard.team,
                            siteSettings: profileTeamCard.siteSettings
                        }} />

                    {profileTeamCard.team && <ProfilePhoto
                        profilePhoto={{
                            imagePath: getProfileImagePath(profileTeamCard.team.RegionId, profileTeamCard.team.TeamPhotoID),
                            alt: profileTeamCard.team?.TeamName,
                            title: profileTeamCard.team?.TeamName
                        }} />}


                </Stack>

                <Stack direction="column" spacing={2} textAlign="center" mt={3}>

                    <Stack direction="column" spacing={1}>

                        <Typography className="h3" variant="h3" >
                            {profileTeamCard.team?.TeamName}
                        </Typography>

                    </Stack>

                    <Stack direction="column" spacing={3}>

                        <Stack spacing={2} alignItems="center">

                            {/* Team Logo  */}
                            {profileTeamCard.team && profileTeamCard.team.TeamLogo !== null &&
                                <Box sx={{ marginTop: '.5rem !important', marginBottom: '1rem !important' }}>
                                    <ProfileTeamNameAndLogo
                                        teamId={profileTeamCard.team.TeamId}
                                        teamName=""
                                        teamRegionId={profileTeamCard.team.RegionId}
                                        teamPhotoOrLogo={profileTeamCard.team.TeamLogo}
                                    />
                                </Box>
                            }

                            {/* Contact Us Button */}
                            <Button type="button" role="button" aria-label={t('OfficeFull_ContactUs')} className="primary-button" variant="contained" style={{ width: '100%', marginTop: '.5rem', marginBottom: '.5rem' }} onClick={toggleDrawer}>
                                {t('OfficeFull_ContactUs')}
                            </Button>

                            {/* Global Contact Me Mobile Drawer (component) */}
                            <ContactMobileDrawer data={{ team: profileTeamCard.team, open: openDrawer, toggleDrawer: toggleDrawer, page: PAGE_TYPE.TEAM }} />


                            {/* Global Agent/Office Properties Link (component) */}
                            {profileTeamCard.team && <ProfilePropertiesCountWithLink
                                team={profileTeamCard.team}
                                page={PAGE_TYPE.TEAM}
                                listingCount={profileTeamCard.teamListingCount}
                            />}

                            {/* Comments: Team chat integration  */}
                            {profileTeamCard.team && <ProfileSocialChatIntegration
                                phone={profileTeamCard.team?.Phone}
                                email={profileTeamCard.team?.Email}
                                whatsApp={profileTeamCard.team?.WhatsAppNumber}
                                facebookMessengerID=''
                                viberID=''
                                telegramID=''
                                lineChat=''
                                lineChatID={profileTeamCard.team?.LINEChat}
                                weChat={profileTeamCard.team?.WeChat}
                                weChatID=''
                                isMobile={isMobile}
                            />}

                            {/* Global Social Media Links (component) */}
                            {profileTeamCard.team && <ProfileSocialMediaLinks
                                socialMedias={profileTeamCard.team?.SocialMedias}
                                isSocialMediaEnabled={profileTeamCard.team?.IsSocialMediaEnabled}
                                lookupList={profileTeamCard.lookupList}
                                siteSettings={profileTeamCard.siteSettings}
                            />}


                            {/* Global Show More Buttons (component)  */}
                            {profileTeamCard.team &&
                                <ProfileContactInfo
                                    hitType={'TeamProfileViewTeamPhone_New'}
                                    contactInfo={{
                                        AgentPhone: profileTeamCard.team?.Phone,
                                        DirectDialPhoneNumber: '',
                                        OfficePhone: profileTeamCard.team?.OfficePhone,
                                        Email: profileTeamCard.team?.Email,
                                        siteSettings: profileTeamCard.siteSettings,
                                        TeamId: profileTeamCard.team?.TeamId,
                                        OfficeId: profileTeamCard.team?.OfficeId,
                                        RegionId: profileTeamCard.team?.RegionId
                                    }} />}
                        </Stack>

                    </Stack>

                </Stack>

            </Hidden>

        </>
    );
};

export default ProfileTeamCard;