import React, { useEffect, useState } from 'react';
import { fetchAgentListData, fetchOfficeListData } from '../../services/ProfileService';
import ProfileSearchOfficeCard from './ProfileSearchOfficeCard';
import { SiteSettings, OfficeSearchResponse } from '../../types/TypesIndex';
import { escapeSpecialCharacters } from '../utils/helper/DataFormatHelper';

interface ProfileSearchOfficeListProps {
    searchType?: string;
    country?: string;
    countryId?: number;
    //region?: string; // To Do: not in phase I
    //province?: string;
    //city?: string;
    //localZone?: string;
    //streetName?: string;
    name?: string;
    spokenLanguageUID?: string
    page?: number;
    onTotalMatchCount: (matchCount: number) => void;
    totalRecordsPerPage?: number;
    onTotalPageCount: (data: number) => void;
    siteSettings?: SiteSettings
}

const ProfileSearchOfficeList: React.FC<ProfileSearchOfficeListProps> = ({
    searchType,
    country,
    countryId,
    //region,
    //province,
    //city,
    //localZone,
    //streetName,
    name,
    spokenLanguageUID,
    page = 1,
    totalRecordsPerPage = 10,
    onTotalMatchCount,
    onTotalPageCount,
    siteSettings
}) => {

    // Parameters Declaration
    let officeIds: string[] = [];

    const searchParams = new URLSearchParams(window.location.search);
    const province = searchParams.get('province');
    const city = searchParams.get('city');
    const localzone = searchParams.get('localzone');
    const streetName = searchParams.get('streetName');

    // Hook Declaration 
    const [offices, setOffices] = useState<OfficeSearchResponse | undefined>();
    // const [totalPages, setTotalPages] = useState<number>(0);
    // const [currentPage, setCurrentPage] = useState<number>(1);

    // Use Effect Declaration 

    const getFilter = () => {
        let filter = '';

        filter += `content/TenantId eq ${window.tenantid}`;


        if (countryId && countryId > 0) {
            filter = filter + (` and content/MacroRegionId eq ${countryId}`);
        }

        // Location Filter
        if (province) {
            filter = filter + (` and search.ismatch('${escapeSpecialCharacters(province)}', 'content/Province')`);
        }
        if (city) {
            filter = filter + (` and search.ismatch('${escapeSpecialCharacters(city)}', 'content/City')`);
        }
        if (localzone) {
            filter = filter + (` and search.ismatch('${escapeSpecialCharacters(localzone)}', 'content/LocalZone')`);
        }
        if (streetName) {
            filter = filter + (` and search.ismatch('${escapeSpecialCharacters(streetName)}', 'content/OfficeAddress')`);
        }

        if (spokenLanguageUID !== null && spokenLanguageUID !== '' && officeIds !== undefined && officeIds.length > 0) {
            filter = filter + (` and (`);
            let i;

            for (i = 0; i < officeIds.length; i++) {
                if (i == officeIds.length - 1) {
                    filter = filter + (` content/OfficeId eq ${officeIds[i]}`);
                }
                else {
                    filter = filter + (` content/OfficeId eq ${officeIds[i]} or`);
                }
            }
            filter = filter + (` )`);

        }

        // Office Name
        if (name) {
            filter = filter + ` and search.ismatch('${escapeSpecialCharacters(name)}*', 'content/OfficeName', 'full', 'any')`;
        }

        // Common Filter a. content/IsRegionalOffice eq false 
        filter = filter + ` and content/IsRegionalOffice eq false and content/Disabled eq false`;

        return filter;
    }

    const getOfficeIdListFilter = () => {
        let filter = `content/TenantId eq ${siteSettings?.TenantID}`;

        if (countryId && countryId > 0) {
            filter = filter + (` and content/MacroRegionId eq ${countryId}`);
        } else {
            filter = filter + (` and content/MacroRegionId eq ${siteSettings?.CountryID ?? 55}`);
        }

        if (spokenLanguageUID !== null && spokenLanguageUID !== '') {
            filter = filter + (`and search.ismatch('${spokenLanguageUID?.toString()}', 'content/SpokenLanguages', 'full', 'any')`);
        }

        filter = filter + `and content/Category eq 1 and content/ExcludeAgentTraining eq false nd content/Disabled eq false and content/IsRegionalOffice eq false and content/AgentName ne null`;

        return filter;
    }

    useEffect(() => {

        const loadOfficeList = async () => {

            let searchQuery = '*';

            const searchRequestBody = {
                count: true,
                skip: ((page ?? 1) - 1) * 10,
                top: 10,
                searchMode: 'any',
                queryType: 'simple',
                search: searchQuery,
                filter: getFilter(),
                orderby: 'content/OfficeName asc'
            };

            try {
                const result = await fetchOfficeListData(searchRequestBody);

                setOffices(result);
                onTotalMatchCount(result['@odata.count']);
                onTotalPageCount(Math.ceil(result['@odata.count'] / (totalRecordsPerPage || 10)));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const loadOfficeListBySpokenLanguageUID = async () => {

            const searchOfficeIdListBySpokenLanguageUIDBody = {
                count: true,
                searchMode: 'any',
                queryType: 'simple',
                facets: ["content/OfficeId,count:1000,sort:count"],
                filter: getOfficeIdListFilter(),
                select: "content/OfficeId"
            };

            try {
                const officeListWithSpokenLanugage = await fetchAgentListData(searchOfficeIdListBySpokenLanguageUIDBody);


                if (officeListWithSpokenLanugage !== undefined
                    && officeListWithSpokenLanugage['@search.facets'] !== undefined
                    && officeListWithSpokenLanugage['@search.facets']?.['content/OfficeId'] !== undefined) {

                    officeIds = officeListWithSpokenLanugage['@search.facets']?.['content/OfficeId'].map(o => o.value);
                    loadOfficeList();
                }
            } catch (error) {
                console.error('Error fetching spokenLanguageUID:', error);
            }

        };

        if (spokenLanguageUID !== null && spokenLanguageUID !== '') {
            loadOfficeListBySpokenLanguageUID();
        } else {
            loadOfficeList();
        }

    }, [searchType, country, countryId, province, city, localzone, streetName, name, spokenLanguageUID, page, onTotalMatchCount, totalRecordsPerPage, onTotalPageCount]);



    return (
        <>
            {offices && typeof (offices?.value) !== 'undefined' && offices?.value.map((item) => (
                <ProfileSearchOfficeCard key={item.content.OfficeId} office={item.content} />
            ))}
        </>
    );
}


export default ProfileSearchOfficeList;
