import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import ListingSlider from './ListingSlider';
import ListingDetailData from '../../types/Listing/ListingDetailData';
import { useTranslation } from 'react-i18next';


interface SimilarNearbyCarouselsProps {
    listingDetailData: ListingDetailData
}

interface HomeProps {
    children?: React.ReactNode;
    index?: number;
    value: number;

}

const SimilarNearbyCarousels: React.FC<SimilarNearbyCarouselsProps> = ({ listingDetailData }) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Stack direction="column" spacing={5}>
                {/* Similar Nearby For Sale Listings */}
                <ListingSlider title={t("FeaturedItems_RecentlyListedAndNearbyProperties")}
                    geoName={listingDetailData.value[0].content.City}
                    geoType='City'
                    geoId={listingDetailData.value[0].content.CityID}
                    numberOfResults={20}
                    tenantId={listingDetailData.value[0].content.TenantId}
                    macroRegionId={listingDetailData.value[0].content.MacroRegionId}
                    numberOfBedrooms={listingDetailData.value[0].content.NumberOfBedrooms}
                    numberOfBathrooms={listingDetailData.value[0].content.NumberOfBathrooms}
                    totalNumOfRooms={listingDetailData.value[0].content.TotalNumOfRooms}
                    listingPrice={listingDetailData.value[0].content.ListingPrice}
                    itemsToShow={4}
                    transactionType={261}
                >
                </ListingSlider>

                {/* Similar Nearby Sold Listings */}
                <ListingSlider title={t("FeaturedItems_RecentlySoldAndNearbyProperties")}
                    geoName={listingDetailData.value[0].content.City}
                    geoType='City'
                    geoId={listingDetailData.value[0].content.CityID}
                    numberOfResults={20}
                    tenantId={listingDetailData.value[0].content.TenantId}
                    macroRegionId={listingDetailData.value[0].content.MacroRegionId}
                    numberOfBedrooms={listingDetailData.value[0].content.NumberOfBedrooms}
                    numberOfBathrooms={listingDetailData.value[0].content.NumberOfBathrooms}
                    totalNumOfRooms={listingDetailData.value[0].content.TotalNumOfRooms}
                    listingPrice={listingDetailData.value[0].content.ListingPrice}
                    soldPrice={listingDetailData.value[0].content.SoldPrice}
                    listingStatusUID={169}
                    itemsToShow={4}
                >
                </ListingSlider>
            </Stack>
        </>
    );
};

export default SimilarNearbyCarousels;
