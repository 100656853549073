import i18n from '../i18n/i18n';
import memoize from 'memoizee';
import { Country, LookupItem, Province } from '../types/TypesIndex';
import { TENANTS } from '../constants/constants';

const fetchOptions: RequestInit = {
  headers: {
    'Accept-Encoding': 'gzip, deflate'
  }
};

const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour

// Correctly defining the fetchWithCache function with memoize
const fetchWithCache = memoize(
  async function (url: string): Promise<LookupItem[]> {
    const res = await fetch(url, fetchOptions);
    const data: LookupItem[] = await res.json();
    return data;
  },
  {
    promise: true,
    maxAge: cacheDuration,
  }
);

const fetchCountryWithCache = memoize(
  async function (url: string): Promise<Country[]> {
    const res = await fetch(url, fetchOptions);
    const data: Country[] = await res.json();
    return data;
  },
  {
    promise: true,
    maxAge: cacheDuration,
  }
);

// To Do: Combine those methods into one as setting services
const fetchProvinceWithCache = memoize(
  async function (url: string): Promise<Province[]> {
    const res = await fetch(url, fetchOptions);
    const data: Province[] = await res.json();
    return data;
  },
  {
    promise: true,
    maxAge: cacheDuration,
  }
);


const fetchLookupTranslations = async (): Promise<LookupItem[]> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/locales/${i18n.language}/lookups.json`;

  if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        if (window.tenantid === TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${i18n.language}/lookups.json`;
        } else {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/lookups.json`;
        }
      });
  }
  else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/lookups.json`;
  }

  return fetchWithCache(url);
};

const fetchMacroPropertyTypesTranslations = async (): Promise<LookupItem[]> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/locales/${i18n.language}/macropropertytype.json`;
  
  if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        if (data.tenantid !== TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/macropropertytype.json`;
        } else {
          url = `${process.env.REACT_APP_BLOB_URL}/${i18n.language}/macropropertytype.json`;
        }
      });

  } else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/macropropertytype.json`;
  } 

  return fetchWithCache(url);
};

const fetchFileByTypeAndName = async (filename: string): Promise<LookupItem[]> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/locales/${i18n.language}/${filename}`;

  if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        if (data.tenantid !== TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/${filename}`;
        }
        else {
          url = `${process.env.REACT_APP_BLOB_URL}/${i18n.language}/${filename}`;
        }
      });
  } else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${i18n.language}/${filename}`;
  } 

  return fetchWithCache(url);
};

const featchCountryList = async (LanguageCode: string): Promise<Country[]> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/locales/${LanguageCode}/${window.macroregionid}/contrylist.json`;

 if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        if (window.tenantid !== TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${LanguageCode}/${window.macroregionid}/contrylist.json`;

        }
        else {
          url = `${process.env.REACT_APP_BLOB_URL}/locales/${LanguageCode ?? "en-US"}/${window.macroregionid}/contrylist.json`;

        }
      });
  } else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/locales/${window.tenantid}/${LanguageCode}/${window.macroregionid}/contrylist.json`;
  }

  return fetchCountryWithCache(url);
};

const featchProvinceList = async (LanguageCode: string): Promise<Province[]> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/locales/${LanguageCode}/${window.macroregionid}/provincelist.json`;

  if (window.tenantid === undefined || window.macroregionid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        url = `${process.env.REACT_APP_BLOB_URL}/locales/${LanguageCode}/${window.macroregionid}/provincelist.json`;
      });
  }
  
  return fetchProvinceWithCache(url);
};

export { fetchLookupTranslations, fetchMacroPropertyTypesTranslations, fetchFileByTypeAndName, featchCountryList, featchProvinceList };
