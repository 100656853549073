import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { Hidden, Typography, Button, Grid, Link, Container, Stack, Paper, Skeleton, Chip, Box, DialogContent, Toolbar, IconButton, Dialog } from '@mui/material';
import { PAGE_TYPE } from '../../constants/constants';
import { Development, SiteSettings } from '../../types/TypesIndex';
import ImageGallery from 'react-image-gallery';
import { getListingDefaultImagePath, getListingImagePath } from '../utils/helper/ImageHelper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Navigation, Pagination, Scrollbar, A11y, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
// import { Close as CloseIcon } from '@mui/icons-material';
import { getYouTubeEmbedUrl, isValidYouTubeUrl } from '../utils/helper/FunctionHelper';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined';


import 'swiper/css';
import 'swiper/css/bundle';

interface DevelopmentDetailMultiMediaProps {
    development: Development;
    siteSettings?: SiteSettings;
}

const DevelopmentDetailMultiMedia: React.FC<{ multiMedia: DevelopmentDetailMultiMediaProps }> = ({ multiMedia }) => {

    // Parameters Declaration
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true); //State to manage loading for skeleton
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);// State to hold the thumbnail swiper instance
    const hasVideo = multiMedia.development?.VideoUrl && isValidYouTubeUrl(multiMedia.development?.VideoUrl); 
    const [objectFit, setObjectFit] = useState<'cover' | 'contain'>('cover');

    // State for media type, dialog visibility, current Swiper index
    const [activeMediaType, setActiveMediaType] = useState(hasVideo ? 'video' : 'images');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const hasMultipleMediaTypes = hasVideo;

    // States for zooming and panning
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [lastTouchDistance, setLastTouchDistance] = useState(0);
    const [lastTouchPosition, setLastTouchPosition] = useState({ x: 0, y: 0 });


    useEffect(() => {
        const updateObjectFit = () => {
          if (window.innerWidth < 600) {
            setObjectFit('cover'); // Mobile
          } else {
            setObjectFit('contain'); // Desktop
          }
        };
    
        updateObjectFit(); // Initial check
        window.addEventListener('resize', updateObjectFit); // Listen for resize events
    
        return () => window.removeEventListener('resize', updateObjectFit); // Clean up listener on unmount
    }, []);

    // Timer for skeleton
    React.useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    const DesktopSkeleton = (
        <Skeleton variant="rectangular" width="100%" height={860}></Skeleton>
    );

    const MobileSkeleton = (
        <Skeleton variant="rectangular" width="100%" height={218}></Skeleton>
    );



    const handleViewAllPhotos = () => {
        setIsDialogOpen(true);
    };

    



    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    let galleryItems = [
        {
            original: getListingDefaultImagePath()
        }
    ];

    if (multiMedia.development?.DevelopmentImages !== null && multiMedia.development?.DevelopmentImages.length !== 0 && multiMedia.development?.RegionId !== null) {
        galleryItems = multiMedia.development?.DevelopmentImages.map(item => ({
            original: getListingImagePath(item.ImageFileName, multiMedia.development?.RegionId, item.HasLargeImage, "0"),
        }));
    }
   


    
    // Zoom in and out using the mouse wheel
    const handleWheelZoom = (e) => {
        e.preventDefault();
        setScale(prevScale => Math.min(Math.max(prevScale + e.deltaY * -0.01, 1), 5));
    };

    // Handle mouse drag for panning
    const handleMouseMove = (e) => {
        if (scale > 1) {
            const imageContainer = e.currentTarget.getBoundingClientRect();
            const imgWidth = imageContainer.width * scale;
            const imgHeight = imageContainer.height * scale;
    
            // Calculate max boundaries for panning
            const maxX = (imgWidth - imageContainer.width) / 2;
            const maxY = (imgHeight - imageContainer.height) / 2;
    
            // Constrain the panning position within bounds
            const newX = Math.min(maxX, Math.max(-maxX, position.x + e.movementX));
            const newY = Math.min(maxY, Math.max(-maxY, position.y + e.movementY));
    
            setPosition({ x: newX, y: newY });
        }
    };

    // Handle touch start for panning and pinch zoom
    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            setLastTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        } else if (e.touches.length === 2) {
            const touchDistance = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY
            );
            setLastTouchDistance(touchDistance);
        }
    };

    // Handle touch move for panning and pinch zoom
    const handleTouchMove = (e) => {
        if (e.touches.length === 2) {
            const newTouchDistance = Math.hypot(
                e.touches[0].clientX - e.touches[1].clientX,
                e.touches[0].clientY - e.touches[1].clientY
            );
            const scaleChange = newTouchDistance / lastTouchDistance;
            setScale(prevScale => Math.min(Math.max(prevScale * scaleChange, 1), 5));
            setLastTouchDistance(newTouchDistance);
        } else if (scale > 1 && e.touches.length === 1) {
            const imageContainer = e.currentTarget.getBoundingClientRect();
            const imgWidth = imageContainer.width * scale;
            const imgHeight = imageContainer.height * scale;
    
            // Calculate max boundaries for panning
            const maxX = (imgWidth - imageContainer.width) / 2;
            const maxY = (imgHeight - imageContainer.height) / 2;
    
            // Calculate new position and constrain within boundaries
            const newX = Math.min(maxX, Math.max(-maxX, position.x + e.touches[0].clientX - lastTouchPosition.x));
            const newY = Math.min(maxY, Math.max(-maxY, position.y + e.touches[0].clientY - lastTouchPosition.y));
    
            setPosition({ x: newX, y: newY });
            setLastTouchPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        }
    };

    // Reset zoom and panning position
    const resetZoom = () => {
        setScale(1);
        setPosition({ x: 0, y: 0 });
    };

    // Open dialog for fullscreen view
    const handleOpenDialog = (index: number) => {
        setCurrentIndex(index);
        setIsDialogOpen(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };


    // Render media content based on active media type
    const renderMediaContent = () => {
        if (activeMediaType === 'video' && hasVideo) {
            return (
                <iframe
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                    allow="encrypted-media"
                    src={getYouTubeEmbedUrl(multiMedia.development?.VideoUrl)}
                    title="Video"
                    allowFullScreen
                />
            );
        
        } else if (activeMediaType === 'images') {
            return (
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={true}
                    navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }}
                    pagination={{ type: 'fraction' }}
                    style={{ height: '100%' }}
                    onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
                >
                    {galleryItems.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img
                                src={item.original}
                                alt="Image"
                                style={{ objectFit: objectFit, height: '100%', width: '100%' }}
                                onClick={() => handleOpenDialog(index)}
                            />
                        </SwiperSlide>
                    ))}
                     {/* View All Photos Button */}
                     <div style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 2 }}>
                        <Button
                            variant="outlined"
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: "white",
                                borderRadius: '25px',
                                padding: '4px 12px',
                                fontSize: '0.813rem',
                                border: 'none',
                                display: 'flex',
                                gap: '0.25rem',
                                fontWeight: '400'
                            }}
                            onClick={() => handleOpenDialog(currentIndex)}  // Open dialog on button click
                        >
                            <InsertPhotoOutlinedIcon />
                            <Typography sx={{ color: "white !important", fontSize: '0.813rem' }}>+{galleryItems.length}</Typography>
                        </Button>
                    </div>

                    
                </Swiper>
            );
        }
        return null;
    };

    

    return (
        <>
        {/* Mobile Layout */}
        <Hidden mdUp>
            <Box sx={{  position: 'relative', width: '100vw', marginLeft: '-16px !important' }}>
                    <Box sx={{ height: '300px', overflow: 'hidden', position: 'relative', backgroundColor: 'white' }}>
                        {renderMediaContent()}
                    </Box>

                    {/* Media Toggle Buttons */}
                    {hasMultipleMediaTypes && (
                        <Box sx={{ display: 'flex', gap: '1rem', pb: 2, pt: 2, overflowX: 'auto', paddingLeft: '1rem', paddingRight: '1rem', scrollSnapType: 'x mandatory', borderBottom: '1px solid', borderColor: 'divider' }} className="mobile-media-toggle">
                            {hasVideo && (
                                <Box onClick={() => setActiveMediaType('video')} className={activeMediaType === 'video' ? 'active-media-button' : ''}>
                                    <PlayArrowOutlinedIcon />{t('Video')}
                                </Box>
                            )}
                            <Box onClick={() => setActiveMediaType('images')} className={activeMediaType === 'images' ? 'active-media-button' : ''}>
                                <ImageOutlinedIcon />{t('Images')}
                            </Box>
                        </Box>
                    )}
            </Box>
            {/* Fullscreen Gallery Dialog */}
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullScreen className="fullscreen-gallery slider-fullscreen" style={{zIndex: '9999999'}}>
                <DialogContent sx={{ backgroundColor: 'black', color: 'white', alignContent: 'center', maxWidth: '100vw', maxHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', overflow: 'hidden !important' }}>
                    {/* Toolbar with Pagination and Close Button */}
                    <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', paddingBottom: '1.5rem', justifyContent: 'space-between', width: '100%' }}>
                        <Box className="custom-pagination" sx={{ padding: '8px', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', color: 'white', display: 'inline-block', fontSize: '1rem' }} />
                        <IconButton onClick={handleCloseDialog}>
                            <CloseIcon sx={{ color: 'white !important' }} />
                        </IconButton>
                    </Toolbar>

                    {/* Swiper in Dialog */}
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        loop={true}
                        navigation
                        pagination={{ type: 'fraction', el: '.custom-pagination' }}
                        initialSlide={currentIndex}
                        style={{ width: '100%', height: '100%' }}
                        onSlideChange={() => {
                            resetZoom();
                        }}
                        onSlideChangeTransitionEnd={(swiper) => setCurrentIndex(swiper.activeIndex)}
                    >
                        {galleryItems.map((item, index) => (
                            <SwiperSlide key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div
                                    onWheel={handleWheelZoom}
                                    onMouseMove={scale > 1 && index === currentIndex ? handleMouseMove : null}
                                    onTouchStart={index === currentIndex ? handleTouchStart : null}
                                    onTouchMove={index === currentIndex ? handleTouchMove : null}
                                    className='fullscreen-mobile-gallery-wrapper'
                                    style={{
                                        transform: `scale(${index === currentIndex ? scale : 1}) translate(${position.x}px, ${position.y}px)`,
                                        transition: 'transform 0.1s ease-out',
                                        maxHeight: '100%',
                                        maxWidth: '100%',
                                        touchAction: 'none',
                                    }}
                                >
                                    <img src={item.original} alt="Fullscreen Image" style={{ objectFit: 'contain', width: '100%', aspectRatio: '3 / 2', maxHeight: '100%' }} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </DialogContent>
            </Dialog>
        </Hidden>

        {/* Desktop Layout */}
        <Hidden mdDown>

        <Box>
                <Box className="swiper-container" sx={{ position: 'relative' }}>

                        <div>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y, Thumbs]}
                                spaceBetween={50}
                                slidesPerView={1}
                                loop={true}
                                navigation={{
                                    nextEl: '.custom-next',
                                    prevEl: '.custom-prev',
                                }}
                                pagination={{ type: 'fraction' }}
                                thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}  // Only pass thumbsSwiper if it's initialized
                                allowTouchMove={false}
                                style={{ backgroundColor: '#fff', borderRadius: '1rem' }}
                            >

                                {/* Video Slides */}
                                {multiMedia.development?.VideoUrl && isValidYouTubeUrl(multiMedia.development?.VideoUrl) && (
                                    <SwiperSlide>
                                        <Box sx={{ backgroundColor: '#000', height: '600px', display: 'flex', borderRadius: '1rem' }}>
                                            <iframe style={{ height: '100%', width: '100%', margin: 'auto', borderRadius: '1rem' }}
                                                allow="encrypted-media"
                                                src={getYouTubeEmbedUrl(multiMedia.development?.VideoUrl) + "?autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0&loop=1&mute=0"}
                                                title={t("SocialMedia_YouTube")}
                                                allowFullScreen
                                            ></iframe>
                                        </Box>
                                    </SwiperSlide>
                                )}

                                {/* Video Slides - HARDCORED - REMOVE LATER */}
                                {/* {
                            <SwiperSlide>
                                <Box sx={{backgroundColor: '#000', height: '600px', display: 'flex', borderRadius: '1rem'}}>
                                    <iframe style={{ height: '100%', width: '100%', margin: 'auto', borderRadius: '1rem'}}
                                        allow="encrypted-media"
                                        src={"https://www.youtube.com/embed/i-KELQtFReY?autoplay=1&controls=0&modestbranding=1&showinfo=0&rel=0&loop=1&mute=0"}
                                        title={t("SocialMedia_YouTube")}
                                    ></iframe>
                                </Box>
                            </SwiperSlide>
                        } */}

                                {/* Image Slides */}
                                {
                                    galleryItems.length > 0 && (
                                        galleryItems.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={item.original}
                                                    alt="Image"
                                                    style={{ objectFit: 'contain', height: '600px', width: '100%', borderRadius: '16px', aspectRatio: '3 / 2' }}
                                                />
                                            </SwiperSlide>
                                        ))
                                    )
                                }

                            </Swiper>

                            {/* Custom Navigation Buttons - Arrows */}
                            <Box sx={{ display: 'flex', position: 'absolute', top: '45%', justifyContent: 'space-between', width: '100%', zIndex: '1', transform: 'translateY(-55%)' }}>
                                <Box className="custom-prev"
                                    sx={{
                                        width: '100px',
                                        height: '300px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        left: '16px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        zIndex: 10
                                    }}
                                >
                                    <ArrowBackIosNewIcon sx={{ color: '#fff', fontSize: '50px', filter: 'drop-shadow(rgba(0, 0, 0, 0.7) 0px 0px 3px)' }} />
                                </Box>

                                <Box className="custom-next"
                                    sx={{
                                        width: '100px',
                                        height: '300px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '16px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        zIndex: 10
                                    }}
                                >
                                    <ArrowForwardIosIcon sx={{ color: '#fff', fontSize: '50px', filter: 'drop-shadow(rgba(0, 0, 0, 0.7) 0px 0px 3px)' }} />
                                </Box>
                            </Box>

                            {/* Thumbnail Swiper */}
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={16}
                                slidesPerView={8}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[Thumbs]}
                                className="mySwiper myswiperthumbnails"
                            >
                                {/* Video Slides */}
                                {multiMedia.development?.VideoUrl && isValidYouTubeUrl(multiMedia.development?.VideoUrl) && (
                                    <SwiperSlide>
                                        <Box sx={{ position: 'relative', width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000', overflow: 'hidden', borderRadius: '16px', cursor: 'pointer' }}>
                                            <iframe style={{ height: '250px', width: '100%', pointerEvents: 'none' }}
                                                allow="encrypted-media"
                                                src={getYouTubeEmbedUrl(multiMedia.development?.VideoUrl) + "?autoplay=0&controls=0&modestbranding=1&showinfo=0&rel=0"}
                                                title={t("SocialMedia_YouTube")}
                                                
                                            ></iframe>
                                        </Box>
                                    </SwiperSlide>
                                )}

                                
                                {
                                    galleryItems.length > 0 && (
                                        galleryItems.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={item.original}
                                                    alt="Thumbnail"
                                                    style={{ objectFit: 'cover', height: '100px', width: '100%', borderRadius: '16px', cursor: 'pointer' }}
                                                />
                                            </SwiperSlide>
                                        ))
                                    )
                                }

                            </Swiper>
                        </div>
                 

                    {/* Development logo */}
                    {multiMedia.development?.DevelopmentLogo !== null && (
                        <div style={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }}>
                            <Paper sx={{ padding: '8px', maxWidth: '94px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                    src={multiMedia.development?.DevelopmentLogo}
                                    alt="Development logo">
                                </img>
                            </Paper>
                        </div>

                    )}

                    {/* View All Photos Button*/}
                    <Box sx={{ position: 'absolute', bottom: { xs: 107, md: 148 }, right: { xs: 0, md: 24 }, zIndex: 2 }}>
                        <Button variant="outlined" className="brand-primary" sx={{ border: '1px solid var(--primary-color)', background: 'white !important' }} onClick={handleViewAllPhotos}>
                            {t('ListingFull_ViewPhotoGallery')}
                        </Button>
                    </Box>

                </Box>

                <div style={{ position: 'relative' }}>
                <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullScreen className="fullscreen-gallery slider-fullscreen-desktop" style={{zIndex: '9999999'}}>
                    <DialogContent sx={{ backgroundColor: 'black', color: 'white', alignContent: 'center', maxWidth: '100vw', maxHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'  }}>
                        {/* Toolbar with Pagination and Close Button */}
                        <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', paddingBottom: '1.5rem', justifyContent: 'space-between', width: '100%' }}>
                            <Box className="custom-pagination"
                                sx={{
                                    padding: '8px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    borderRadius: '4px',
                                    color: 'white',
                                    display: 'inline-block',
                                    fontSize: '1rem',
                                }}
                            />
                            <IconButton onClick={handleCloseDialog}>
                                <CloseIcon sx={{ color: 'white !important' }} />
                            </IconButton>
                        </Toolbar>

                        {/* Swiper in Dialog */}
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            navigation
                            pagination={{
                                type: 'fraction',
                                el: '.custom-pagination',
                            }}
                            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {
                                    galleryItems.length > 0 && (
                                        galleryItems.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <Box sx={{ height: { xs: '300px', md: 'calc(100vh - 200px)', display: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                                                    <img
                                                        src={item.original}
                                                        alt="Thumbnail"
                                                        style={{ objectFit: 'contain', height: '100%', margin: '0 auto' }}
                                                    />
                                                </Box>
                                            </SwiperSlide>
                                        ))
                                    )
                                }
                        </Swiper>
                    </DialogContent>
                </Dialog>

                    
                </div>

            </Box>

        </Hidden>





            
        </>
    );
};

export default DevelopmentDetailMultiMedia;