import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Stack, Paper, Hidden, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { ProfilePropertiesCountWithLink, ProfileContactInfo, ProfilePhoto, ProfileSocialChatIntegration, ProfileSocialMediaLinks, ProfileBreadcrumbs } from '../../components/profile/ProfileComponentsIndex';
import { ContactMePopup, ContactMobileDrawer } from '../utils';
import { Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { PAGE_TYPE } from '../../constants/constants';

interface ProfileOfficeCardProps {
    office?: Office;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    officeListingCount: number;
    macroOfficeListingCount?: number;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ProfileOfficeCard: React.FC<{ profileOfficeCard: ProfileOfficeCardProps }> = ({ profileOfficeCard }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or perform data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    // Hook Declaration
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // Event Handlers
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (isLoading) {
        return (
            <Grid container spacing={8} alignItems="center">
                {/* Left Column */}
                <Grid item xs={12} md={8}>
                    <Stack direction="column" spacing={5} useFlexGap>
                        <Skeleton variant="text" width="60%" />
                        <Stack spacing={3}>
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="rectangular" width={120} height={40} />
                            <Skeleton variant="text" width={180} />
                            <Skeleton variant="text" width={180} />
                        </Stack>
                    </Stack>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems="center">
                        <Skeleton variant="rectangular" width={240} height={320} />
                        <Skeleton variant="text" width="60%" />
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {/* Desktop Layout */}
            <Hidden mdDown>

                {/* Two column grid */}
                <Grid container spacing={8} alignItems="center">

                    {/* Left column */}
                    <Grid item xs={12} md={8}>

                        <Stack direction="column" spacing={5} useFlexGap>

                            {/* Breadcrumbs */}
                            <ProfileBreadcrumbs profileBreadcrumbs={{ page: PAGE_TYPE.OFFICE, name: profileOfficeCard.office?.OfficeName.toString(), siteSettings: profileOfficeCard.siteSettings }} />

                            <Stack spacing={3}>

                                <Stack spacing={2}>

                                    {/* Office Name */}
                                    {/* To Do: SEO Micro Data */}
                                    <Typography className="h2" variant="h2">
                                        {profileOfficeCard.office?.OfficeName}
                                    </Typography>

                                </Stack>

                                <Stack spacing={2}>

                                    <Stack useFlexGap
                                        direction={{ xs: 'column', md: 'row' }} // Column layout for mobile, row layout for desktop
                                        alignItems={{ xs: 'flex-start', md: 'center' }} // Align items to flex-start on mobile
                                        spacing={{ xs: 1, md: 2 }} // Spacing between items
                                        className="gridItem"
                                        sx={{ marginTop: '1.5rem', marginBottom: '1rem', alignItems: 'center' }}
                                    >

                                        <Button type="button" role="button" aria-label={t('OfficeFull_ContactUs')} className="primary-button" variant="contained" onClick={handleOpenDialog}>{t('OfficeFull_ContactUs')}</Button>

                                        {/* Comments: Office has not chat integration */}
                                        {profileOfficeCard.office && <ProfileSocialChatIntegration
                                            phone={profileOfficeCard.office?.Phone}
                                            email={profileOfficeCard.office?.Email}
                                            whatsApp=''
                                            facebookMessengerID=''
                                            viberID=''
                                            telegramID=''
                                            lineChat=''
                                            lineChatID=''
                                            weChat=''
                                            weChatID=''
                                            isMobile={isMobile}
                                        />}

                                    </Stack>

                                    {/* Global Profilce Contact Info with/without Show More Buttons (component)  */}
                                    {profileOfficeCard.office && <ProfileContactInfo
                                        hitType={'OfficeProfileViewOfficePhone_New'}
                                        contactInfo={{
                                            AgentPhone: '',
                                            DirectDialPhoneNumber: '',
                                            OfficePhone: profileOfficeCard?.office.Phone,
                                            Email: profileOfficeCard?.office.Email,
                                            OfficeId: profileOfficeCard?.office.OfficeId,
                                            RegionId: profileOfficeCard?.office.RegionId,
                                            siteSettings: profileOfficeCard?.siteSettings,
                                        }} />}

                                </Stack>

                            </Stack>

                        </Stack>

                    </Grid>

                    {/* Right column */}
                    <Grid item xs={12} md={4}>

                        <Stack spacing={2}>

                            {/* Office Name */}
                            {/* To Do: SEO Micro Data */}
                            {/* Global Agent/Office/Team Photos (component) */}
                            {profileOfficeCard.office && <ProfilePhoto
                                profilePhoto={{
                                    imagePath: getProfileImagePath(profileOfficeCard.office.RegionId, profileOfficeCard.office.PhotoId),
                                    alt: profileOfficeCard.office?.OfficeName,
                                    title: profileOfficeCard.office?.OfficeName
                                }} />}

                            {/* Global Agent/Office Properties Link (component) */}
                            {profileOfficeCard.office && <ProfilePropertiesCountWithLink
                                office={profileOfficeCard.office}
                                page="office"
                                listingCount={profileOfficeCard.officeListingCount}
                                macroOfficeListingCount={profileOfficeCard.macroOfficeListingCount} />}

                            {/* Global Social Media Links (component)  */}
                            {profileOfficeCard.office && <ProfileSocialMediaLinks
                                socialMedias={profileOfficeCard.office?.SocialMedias}
                                isSocialMediaEnabled={profileOfficeCard.office?.IsSocialMediaSecurityEnabled}
                                lookupList={profileOfficeCard.lookupList}
                                siteSettings={profileOfficeCard.siteSettings}
                            />}

                        </Stack>

                    </Grid>

                    {/* Global Contact Me Popup (component) */}
                    {/* To Do:  Implment the Lead Agent Info for Office Contact Us Form */}
                    <ContactMePopup data={{ office: profileOfficeCard.office, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

                </Grid>

            </Hidden>

            {/* Mobile Layout */}
            <Hidden mdUp>

                <Stack direction="column" spacing={5} useFlexGap>

                    {/* Breadcrumbs */}
                    <ProfileBreadcrumbs profileBreadcrumbs={{ page: PAGE_TYPE.OFFICE, name: profileOfficeCard.office?.OfficeName.toString(), siteSettings: profileOfficeCard.siteSettings }} />

                    {/* Global Agent/Office Photos (component) */}
                    {profileOfficeCard.office &&
                        <ProfilePhoto profilePhoto={{
                            imagePath: getProfileImagePath(profileOfficeCard.office.RegionId, profileOfficeCard.office.PhotoId),
                            alt: profileOfficeCard.office?.OfficeName,
                            title: profileOfficeCard.office?.OfficeName
                        }} />}

                </Stack>

                <Stack direction="column" spacing={2} textAlign="center" mt={3}>

                    <Stack direction="column" spacing={1}>
                        <Typography className="h3" variant="h3" >
                            {profileOfficeCard.office?.OfficeName}
                        </Typography>

                        {profileOfficeCard.office?.OfficeLicenseNumber !== null && (
                            <Typography className="body2 secondary-text" variant="body2">
                                {t('OfficeFull_LicenseNumber')} {profileOfficeCard.office?.OfficeLicenseNumber}
                            </Typography>
                        )}

                    </Stack>

                    <Stack direction="column" spacing={3}>

                        <Stack spacing={2} alignItems="center">

                            {/* Contact Us Button */}
                            <Button type="button" role="button" aria-label={t('OfficeFull_ContactUs')} className="primary-button" variant="contained" style={{ width: '100%', marginTop: '.5rem', marginBottom: '.5rem' }} onClick={toggleDrawer}>
                                {t('OfficeFull_ContactUs')}
                            </Button>

                            {/* Global Contact Me Mobile Drawer (component)     */}
                            <ContactMobileDrawer data={{ office: profileOfficeCard.office, open: openDrawer, toggleDrawer: toggleDrawer }} />

                            {/* Global Agent/Office Properties Link (component) */}
                            {profileOfficeCard.office && <ProfilePropertiesCountWithLink office={profileOfficeCard.office} page="office" listingCount={profileOfficeCard.officeListingCount} macroOfficeListingCount={0} />}

                            {/* Global Chat Integration Links (component) */}
                            {/* Office has not chat integration */}
                            {profileOfficeCard.office && <ProfileSocialChatIntegration
                                phone={profileOfficeCard.office?.Phone}
                                email={profileOfficeCard.office?.Email}
                                whatsApp=''
                                facebookMessengerID=''
                                viberID=''
                                telegramID=''
                                lineChat=''
                                lineChatID=''
                                weChat=''
                                weChatID=''
                                marginTop="1rem !important"
                                isMobile={isMobile}
                            />}

                            {/* Global Social Media Links (component) */}
                            {profileOfficeCard.office && <ProfileSocialMediaLinks
                                socialMedias={profileOfficeCard.office?.SocialMedias}
                                isSocialMediaEnabled={profileOfficeCard.office?.IsSocialMediaSecurityEnabled}
                                lookupList={profileOfficeCard.lookupList}
                                siteSettings={profileOfficeCard.siteSettings}
                            />}

                            {/* Global Show More Buttons (component)*/}
                            {profileOfficeCard.office && <ProfileContactInfo
                                hitType={'OfficeProfileViewOfficePhone_New'}
                                contactInfo={{
                                    AgentPhone: '',
                                    DirectDialPhoneNumber: '',
                                    OfficePhone: profileOfficeCard?.office.Phone,
                                    Email: profileOfficeCard?.office.Email,
                                    OfficeId: profileOfficeCard?.office.OfficeId,
                                    RegionId: profileOfficeCard?.office.RegionId,
                                    siteSettings: profileOfficeCard.siteSettings,
                                }} />}

                        </Stack>

                    </Stack>

                </Stack>

            </Hidden>

        </>
    );
};

export default ProfileOfficeCard;