import React, { useEffect, useState } from 'react';
import { Avatar, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ContactForm } from '../../components/utils';
import Agent from '../../types/Profile/AgentFull';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { PAGE_TYPE } from '../../constants/constants';
import { ProfileSocialChatIntegration } from '../profile/ProfileComponentsIndex';
import ListingDetailData from '../../types/Listing/ListingDetailData';


interface Props {
    agent?: Agent;
    property?: ListingDetailData;
    page?: string;
}

const ListingContactAgent: React.FC<Props> = ({ agent, property, page }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <Stack id="contact-agent" direction="column" spacing={1} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '1rem !important' }}>

                <Typography className="h4" variant="h4">
                    {t('ContactMe_ManagingAgent')}
                </Typography>

                <Stack direction="row" spacing={2} alignItems="center" useFlexGap flexWrap="wrap" justifyContent="space-between" sx={{ marginTop: '1.5rem !important' }}>

                    <Stack spacing={2} direction="row" alignItems="center" useFlexGap flexWrap="wrap">

                        <Avatar sx={{ width: 52, height: 52 }}
                            title={agent?.AgentName}
                            alt={agent?.AgentName}
                            src={getProfileImagePath(agent?.RegionId, agent?.AgentPhotoId)} />

                        <Stack spacing={.5} direction="column" alignItems="flex-start">

                            <Typography className="h5" variant="h5">
                                <Link underline="hover" title={agent?.AgentName} aria-label={agent?.AgentName} href={`agent/${agent?.AgentId}`}>
                                    {agent?.AgentName}
                                </Link>
                            </Typography>

                            <Typography className="body2 secondary-text" variant="body2">
                                <Link className="secondary-text" title={agent?.OfficeName} aria-label={agent?.OfficeName} underline="hover" href={`office/${agent?.OfficeId}`}>
                                    {agent?.OfficeName}
                                </Link>
                            </Typography>

                        </Stack>

                    </Stack>

                    {/* Global Chat Integration Links (component) */}
                    {agent && <ProfileSocialChatIntegration
                        phone={agent?.AgentPhone}
                        email={agent?.AgentEmail}
                        whatsApp={agent?.WhatsApp}
                        facebookMessengerID={agent?.FacebookMessengerID}
                        viberID={agent?.ViberID}
                        telegramID={agent?.TelegramID}
                        lineChat={agent?.LINEChat}
                        lineChatID={agent?.LineChatID}
                        weChat={agent.WeChat}
                        weChatID={agent.WeChatID}
                        isMobile={isMobile}
                    />}

                </Stack>

                {/* Global Contact Form (component) */}
                <ContactForm data={{
                    Tenant: agent?.TeamName, 
                    tenantId: agent?.TenantId,
                    regionId: agent?.RegionId,
                    agentId: property?.value[0].content.RepresentingAgentID,
                    agentName: agent?.AgentName,
                    listingId: property?.value[0].content.MLSID,
                    listingKey: property?.value[0].content.ListingKey,
                    page: PAGE_TYPE.LISTING_DETAIL
                }} />

            </Stack>
        </>
    );
};

export default ListingContactAgent;
