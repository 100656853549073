import React, { } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { ContactForm } from '../utils';
import { useTranslation } from 'react-i18next';
import ListingDetail from '../../types/Listing/ListingDetail';
import { Agent, Team, Office } from '../../types/TypesIndex';


interface AgentContactMePopupProps {
    agentdata?: Agent | undefined;
    team? : Team | undefined;
    office?: Office | undefined;
    open: boolean;
    hideOfficeUrl?: boolean;
    hideOfficeLicense?: boolean;
    handleCloseDialog: () => void;
    handleOpenDialog: () => void;
    page?: string;
    property?: ListingDetail | undefined;
}

// To Do: Contact Me Form should not related with Agent Full or Team Full or Office Full or Listing Full

const AgentContactMePopup: React.FC<{ data: AgentContactMePopupProps }> = ({ data }) => {
    const isOfficeProfile = data.office !== undefined && data.agentdata === undefined && data.team === undefined;
    const isTeamProfile = data.team !== undefined && data.office === undefined && data.agentdata === undefined;
    const isAgentProfile = data.agentdata !== undefined && data.office === undefined && data.team === undefined;

    const { t } = useTranslation();
    return (
        <>
            {/* Render Agent Profile Popup Only If URL Does Not Contain '/office' */}
            {/* Agent Profile Contact Us Popup */}
            {isAgentProfile && (

                <Dialog className="customBox" open={data.open} onClose={data.handleCloseDialog} /*sx={{direction: 'rtl'} }*/>

                    {/* Modal Title */}
                    <DialogTitle sx={{ m: 0, p: 3 }} component="div">
                        <Typography className="h5" variant="h5">
                            {t('ContactMe_SendMessage')}
                        </Typography>
                    </DialogTitle>

                    {/* Close Button */}
                    <IconButton
                        aria-label="Close"
                        role="button"
                        onClick={data.handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 24,
                            top: 16,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {/* Modal Content */}
                    <DialogContent dividers>

                        {/* Global Contact Form Component */}
                        {data.agentdata &&
                            <ContactForm data={{
                                Tenant: data.agentdata?.TeamName,
                                tenantId: data.agentdata?.TenantId,
                                regionId: data.agentdata?.RegionId,
                                agentId: data.agentdata?.AgentId.toString(),
                                agentName: data.agentdata?.AgentName,
                                page: data.page,
                                listingId: data.property?.MLSID,
                                listingKey: data.property?.ListingKey,
                                handleCloseDialog: data.handleCloseDialog
                            }} />}

                    </DialogContent>

                </Dialog>

            )}

            {/* * Team Profile Contact Us Popup */}
            {isTeamProfile && (

                <Dialog className="customBox" open={data.open} onClose={data.handleCloseDialog}>

                    {/* Modal Title */}
                    <DialogTitle sx={{ m: 0, p: 3 }} component="div">
                        <Typography className="h5" variant="h5">
                            {t('ContactMe_SendMessage')}
                        </Typography>
                    </DialogTitle>

                    {/* Close Button */}
                    <IconButton
                        aria-label="Close"
                        role="button"
                        onClick={data.handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 24,
                            top: 16,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogContent dividers>

                        {/* Global Contact Form Component */}
                        {data.team && <ContactForm data={{
                            Tenant: data.team?.TenantId.toString(), // To Do: Bug fix?
                            tenantId: data.team?.TenantId,
                            regionId: data.team?.RegionId,
                            agentId: (
                                (data.team?.OfficeLeadAdminAgentID && data.team?.OfficeLeadAdminAgentID > 0) ? data.team?.OfficeLeadAdminAgentID.toString() 
                                : ((data.team?.TeamLeadsAdmin && data.team?.TeamLeadsAdmin > 0) ? data.team?.TeamLeadsAdmin.toString() 
                                    : ((data.team?.TeamLeaderID && data.team?.TeamLeaderID > 0) ? data.team?.TeamLeaderID.toString() : ""))),
                            agentName: data.team?.TeamName, // To Do: bug fix
                            page: data.page,
                            listingId: data.property?.MLSID,
                            listingKey: data.property?.ListingKey,
                            handleCloseDialog: data.handleCloseDialog
                        }} />}

                    </DialogContent>

                </Dialog>

            )}

            {/* * Office Profile Contact Us Popup */}
            {isOfficeProfile && (

                <Dialog className="customBox" open={data.open} onClose={data.handleCloseDialog}>

                    {/* Modal Title */}
                    <DialogTitle sx={{ m: 0, p: 3 }} component="div">
                        <Typography className="h5" variant="h5">
                            {t('ContactMe_SendMessage')}
                        </Typography>
                    </DialogTitle>

                    {/* Close Button */}
                    <IconButton
                        aria-label="Close"
                        role="button"
                        onClick={data.handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 24,
                            top: 16,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogContent dividers>

                        {/* Global Contact Form Component */}
                        {data.office && <ContactForm data={{
                            Tenant: data.office?.TenantId.toString(), // To Do: Bug fix?
                            tenantId: data.office?.TenantId,
                            regionId: data.office?.RegionId,
                            officeId: data.office?.OfficeId,
                            page: data.page,
                            listingId: data.property?.MLSID,
                            listingKey: data.property?.ListingKey,
                            handleCloseDialog: data.handleCloseDialog
                        }} />}

                    </DialogContent>

                </Dialog>

            )}

        </>
    );
};

export default AgentContactMePopup;
