import { BlogPost } from '@/types/BlogPost';
import MetaTags from '../components/utils/seo/MetaTags';
import { Pagination, CircularProgress, Hidden, useMediaQuery, useTheme, Avatar, ButtonBase, Box, Tabs, Tab, Chip, Card, CardContent, CardMedia, Container, Grid, Typography, Link, Stack, Divider, Paper, Button } from '@mui/material';
import { max } from 'date-fns';
import { useEffect, useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';


const Blog: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [value, setValue] = useState('one');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const perPage = 10;

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchTotalRecords = async () => {
      try {
        const response = await fetch(`/cms/api/v1/blog?perPage=1&page=1`);
        if (!response.ok) {
          return null;
        }
        const data = await response.json();
        setTotalRecords(data.pages);
        console.log(data.totalRecords);
        console.log(data.pages);
      } catch (error) {
        console.error('Error fetching total records:', error);
      }
    };
    fetchTotalRecords();
  }, []);

  // Fetch posts for the current page
  useEffect(() => {
    const route = `/api/v1/blog?perPage=${perPage}&page=${currentPage}`;
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(`/cms/${route}`);
        if (!response.ok) {
          return null;
        }
        const data = await response.json();
        setPosts(data.results);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };
    fetchBlogPosts();
  }, [currentPage]);

  const totalPages = Math.ceil(totalRecords / perPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 200);
    return () => clearTimeout(timer);
  }, []);


  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
      </div>
    );
  }

  return (

    <div>
      <MetaTags metaTags={{
        //title: post.seoTitle ?? post.title,
        //ogTitle: post.seoTitle ?? post.title,
        //description: post.seoDescription ?? post.description,
        url: window.location.href,
        // image: post.image?._rendered, TODO: Add image to the post object
      }}
      />
      <Container maxWidth="xl" className="text-align-left" sx={{ paddingTop: { xs: '2.5rem', md: '3rem' }, paddingBottom: { xs: '2.5rem', md: '3rem' }, }}>

        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={3} useFlexGap alignItems="center" sx={{ marginBottom: '2.5rem' }}>

          {/* Page Header*/}
          <Typography className={isMobile ? "h3" : "h2"} variant={isMobile ? "h3" : "h1"} sx={{ width: '100%' }}>
            Blog
          </Typography>

        </Stack>

        {/* Mobile Card */}
        <Hidden mdUp>

          {posts.map((post) => (

            // Link to Blog Post
            <Link underline="none" href={`/blog/${post.aposDocId}`} sx={{ textDecoration: 'none !important;' }}>

              {/* Blog Card */}
              <Card
                sx={{
                  borderRadius: '1rem',
                  padding: '1.5rem',
                  background: 'white !important',
                  marginBottom: '1.5rem',
                }} key={post.aposDocId}>


                {/* Blog Image */}
                {post.image && post.image.items.length > 0 && (
                  <CardMedia
                    component="img"
                    //image="https://mui.com/static/images/cards/paella.jpg"
                    image={post.image.items[0]._image[0].attachment._urls["one-third"]}
                    alt={post.title}
                    title={post.title}
                  />
                )}

                <CardContent sx={{ padding: '0 !important;' }}>

                  <Stack direction="column" spacing={2} mt={2}>

                    {/* Category Chip */}
                    {post.category && (
                      <Box>
                        <Chip label={post.category} variant="outlined" />
                      </Box>
                    )}
                    {/* Blog Post Title */}
                    {post.title && (
                      <Typography className="h4" variant="h4">
                        {post.title}
                      </Typography>
                    )}
                    {/* Blog Post Description */}
                    {post.description && (
                      <Typography className="body1" variant="body1">
                        {post.description}
                      </Typography>
                    )}
                    <Stack direction="row" spacing={1} alignItems="center" useFlexGap>

                      {/* Blog Author */}
                      {post.author && (
                        <Typography className="body2 secondary-text" variant="body2">
                          {post.author}
                        </Typography>
                      )}
                      {post.author && post.publishedAt && (
                        <Typography className="body2 secondary-text" variant="body2">
                          /
                        </Typography>
                      )}
                      {/* Published Date */}
                      {post.publishedAt && (
                        <Typography className="body2 secondary-text" variant="body2">
                          {post.publishedAt}
                        </Typography>
                      )}
                    </Stack>

                  </Stack>

                </CardContent>

              </Card>

            </Link>

          ))}

        </Hidden>


        {/* Desktop Card */}
        <Hidden mdDown>
          {posts.map((post) => (

            //Link to Blog Post
            <Link underline="none" href={`/blog/${post.aposDocId}`} sx={{ textDecoration: 'none !important;' }}>

              {/* Blog Card */}
              <Card
                sx={{
                  borderRadius: '1rem',
                  padding: '1.5rem',
                  display: 'flex',
                  background: 'white !important',
                  marginBottom: '1.5rem',
                }} key={post.aposDocId}>

                <Grid container spacing={5}>

                  <Grid item xs={8}>

                    <CardContent sx={{ padding: '0 !important;' }}>

                      <Stack direction="column" spacing={3}>

                        {/* Category Chip */}
                        {post.category && (
                          <Box>
                            <Chip label={post.category} variant="outlined" />
                          </Box>
                        )}
                        <Stack direction="column" spacing={2}>

                          {/* Blog Post Title */}
                          {post.title && (
                            <Typography className="h4" variant="h4">
                              {post.title}
                            </Typography>
                          )}
                          {/* Blog Post Description */}
                          {post.description && (
                            <Typography className="body1" variant="body1">
                              {post.description}
                            </Typography>
                          )}
                        </Stack>

                        <Stack direction="row" spacing={1} alignItems="center" useFlexGap>

                          {/* Blog Author */}
                          {post.author && (
                            <Typography className="body2 secondary-text" variant="body2">
                              {post.author}
                            </Typography>
                          )}
                          {post.author && post.publishedAt && (
                            <Typography className="body2 secondary-text" variant="body2">
                              /
                            </Typography>
                          )}
                          {/* Published Date */}
                          {post.publishedAt && (
                            <Typography className="body2 secondary-text" variant="body2">
                              {post.publishedAt}
                            </Typography>
                          )}
                        </Stack>

                      </Stack>

                    </CardContent>

                  </Grid>

                  <Grid item xs={4}>

                    {/* Blog Image */}
                    {post.image && post.image.items.length > 0 && (
                      <CardMedia
                        component="img"
                        //image="https://mui.com/static/images/cards/paella.jpg"
                        image={post.image.items[0]._image[0].attachment._urls["one-third"]}
                        style={{ width: 350, height: 200 }}
                        alt={post.title}
                        title={post.title}
                      />
                    )}

                  </Grid>

                </Grid>

              </Card>

            </Link>

          ))}
        </Hidden>
        {/* Pagination Controls */}
        <Pagination count={totalRecords} shape="rounded" />

        {/* <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            {'<'}
          </Button>
          <Typography>{`${currentPage} of ${totalPages}`}</Typography>
          <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
            {'>'}
          </Button>
        </Stack> */}
      </Container>
    </div>



  );
};

export default Blog;