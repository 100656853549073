import React, { useState, useEffect } from 'react';
import {
    Autocomplete,
    TextField,
    Box,
    Stack,
    IconButton,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { PAGE_TYPE } from '../../constants/constants';

interface Props {
    apiBaseUrl?: string;
    onOptionSelect?: (value: string) => void;
    onDetailsSelect?: (value: any) => void;
    page?: string;
    clearSearch?: boolean;
    countryId?: number;
}

interface Option {
    group: string;
    title: string;
    type: string;
    expression: string;

}
const ProfileSearchFreeTextSearch: React.FC<Props> = ({ apiBaseUrl = '', onOptionSelect, onDetailsSelect, page = PAGE_TYPE.AGENT, clearSearch = false, countryId = 0 }) => {

    const { t } = useTranslation();
    const { state, dispatch } = useSearch();
    const [options, setOptions] = useState<Option[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const emptyOption: Option = { title: '', group: '', type: '', expression: '' };
    const groupOrder = ['province', 'city', 'localzone', 'streetName'];
    const [value, setValue] = useState<any>(null);
    useEffect(() => {
        if (clearSearch) {
            setValue(null);
            setInputValue('');  // Reset the input text as well
            setOptions([]);
        }
    }, [clearSearch]);

    useEffect(() => {
        if (inputValue.trim() === '') {
            setOptions([]);
            return;
        }

        const fetchOptions = async () => {
            let url = "";
            if (page === PAGE_TYPE.OFFICE) {
                url = `${process.env.REACT_APP_API_SEARCH_OFFICE}`;
            } else {
                url = `${process.env.REACT_APP_API_SEARCH_AGENT}`;
            }

            const searchTerms = []
            if (page === PAGE_TYPE.OFFICE) {
                searchTerms.push('content/OfficeAddress');
                searchTerms.push('content/City');
                searchTerms.push('content/Province');
                searchTerms.push('content/RegionZone');
                searchTerms.push('content/LocalZone');
                searchTerms.push('content/OfficeDescription');
                //'content/District',
                //'content/StreetName',
            } else {
                searchTerms.push('content/OfficeAddress');
                searchTerms.push('content/OfficeCity');
                searchTerms.push('content/OfficeProvince');
                searchTerms.push('content/OfficeRegionZone');
                searchTerms.push('content/OfficeLocalZone');
                searchTerms.push('content/Designations/Description');
            }


            //const filter = searchTerms.map(term => `search.ismatch('${inputValue}*', '${term}')`).join(' or ');
            const searchFilter = searchTerms.map(term => `search.ismatch('${inputValue}*', '${term}')`).join(' or ');
            let filter = '';

            const select = [];
            if (page === PAGE_TYPE.OFFICE) {
                filter = `(${searchFilter}) and content/TenantId eq 6 and content/CountryID eq ${countryId}`; //remove hardcode tenantid
                select.push('content/RegionZone');
                select.push('content/Province');
                select.push('content/City');
                select.push('content/LocalZone');
                select.push('content/OfficeAddress');
            } else {
                filter = `(${searchFilter}) and content/TenantId eq 6 and content/OfficeCountryID eq ${countryId}`; //remove hardcode tenantid
                select.push('content/OfficeProvince');
                select.push('content/OfficeCity');
                select.push('content/OfficeLocalZone');
                select.push('content/OfficeAddress');
                // 'content/District',
                // 'content/FullAddress',
                // 'content/StreetName',
            }

            const data = {
                count: true,
                skip: 0,
                top: 200,
                filter: filter,
                select: select.join(', ')
            };
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "referer": window.location.href,
                        "origin": window.location.origin,
                        "User-Agent": window.navigator.userAgent
                    },
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                const uniqueOptions = new Set<string>();
                const transformedOptions = result.value.flatMap((item: any) => {
                    const optionsArray = [];
                    if (page === PAGE_TYPE.OFFICE) {
                        optionsArray.push({ title: item.content.Province, group: t('FreeTextSearch_Provinces'), type: 'province', expression: item.content.Province });

                        if (item.content.City !== null) {
                            optionsArray.push({ title: item.content.City + ', ' + item.content.Province, group: t('FreeTextSearch_Cities'), type: 'city', expression: item.content.City });
                        }

                        if (item.content.LocalZone !== null) {
                            optionsArray.push({ title: item.content.LocalZone + ', ' + item.content.City + ', ' + item.content.Province, group: t('FreeTextSearch_LocalZones'), type: 'localzone', expression: item.content.LocalZone });

                        }
                        optionsArray.push({ title: item.content.City + ', ' + (item.content.LocalZone !== null ? item.content.LocalZone + ', ' : '') + item.content.OfficeAddress, group: t('FreeTextSearch_Street'), type: 'streetName', expression: item.content.OfficeAddress });
                    } else {
                        optionsArray.push({ title: item.content.OfficeProvince, group: t('FreeTextSearch_Provinces'), type: 'province', expression: item.content.OfficeProvince });

                        if (item.content.OfficeCity !== null) {
                            optionsArray.push({ title: item.content.OfficeCity + ', ' + item.content.OfficeProvince, group: t('FreeTextSearch_Cities'), type: 'city', expression: item.content.OfficeCity });
                        }

                        if (item.content.OfficeLocalZone !== null) {
                            optionsArray.push({ title: item.content.OfficeLocalZone + ', ' + item.content.OfficeCity + ', ' + item.content.OfficeProvince, group: t('FreeTextSearch_LocalZones'), type: 'localzone', expression: item.content.OfficeLocalZone });

                        }
                        optionsArray.push({ title: item.content.OfficeCity + ', ' + (item.content.OfficeLocalZone !== null ? item.content.OfficeLocalZone + ', ' : '') + item.content.OfficeAddress, group: t('FreeTextSearch_Street'), type: 'streetName', expression: item.content.OfficeAddress });
                    }



                    optionsArray.forEach(option => uniqueOptions.add(JSON.stringify(option)));
                    return optionsArray;
                });
                const sortedOptions: Option[] = [...uniqueOptions].map(optionString => JSON.parse(optionString) as Option).sort((a, b) => a.title.localeCompare(b.title));
                setOptions(sortedOptions);
            } catch (error) {
                console.error('Failed to fetch autocomplete suggestions:', error);
            }
        };

        fetchOptions();
    }, [inputValue, apiBaseUrl]);



    const handleClick = (option: Option) => (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.preventDefault();
        dispatch({ type: SET_SEARCH_STATE, payload: { ...state.searchState, FreeText: option.expression } });

        let pageName = 'agent';
        if (page === PAGE_TYPE.OFFICE) {
            pageName = 'office';
        }

        const searchParams = new URLSearchParams(window.location.search);

        const locationList = ['province', 'city', 'localzone', 'streetName'];

        searchParams.forEach((value, key) => {
            if (key !== option.type) {
                if (!locationList.includes(key)) {
                    searchParams.set(key, value);
                } else {
                    searchParams.delete(key);
                }
            }
        });
        let targetUrl = location.pathname + '?' + searchParams.toString();
        if (option.expression) {
            searchParams.set(option.type, option.expression);
            searchParams.set('searchType', pageName);
            targetUrl = location.pathname + '?' + searchParams.toString();
            console.log('targetUrl', targetUrl);
        } else if (option.title === '') {
            searchParams.forEach((value, key) => {
                if (locationList.includes(key)) {
                    searchParams.delete(key);
                }
            });
            targetUrl = location.pathname + '?' + searchParams.toString();
            console.log('targetUrl', targetUrl);
        }
        navigate(targetUrl + `&page=1`);
    };

    const handleInputChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setInputValue(newValue);
        setOpen(newValue.trim().length > 0);
    };

    const renderBoldText = (text: string, highlight: string) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

        return (
            <Typography component="span">
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <Typography component="span" sx={{ fontWeight: 'bold' }} key={index}>
                            {part}
                        </Typography>
                    ) : (
                        <Typography component="span" key={index}>
                            {part}
                        </Typography>
                    )
                )}
            </Typography>
        );
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }} useFlexGap>

            <Box sx={{ width: '100%' }}>
                <Autocomplete
                    fullWidth
                    clearOnBlur={false}  // Disable automatic clear on blur
                    sx={{
                        maxWidth: '100% !important',
                        width: '100% !important',
                        '& .MuiOutlinedInput-root': {
                            paddingRight: '14px !important',
                        },
                        '& .MuiAutocomplete-endAdornment': {
                            display: 'none', // Hide the dropdown arrow
                        },
                    }}
                    id="agent-search-autocomplete"
                    noOptionsText=""
                    value={value}
                    options={options.sort((a, b) => {
                        return groupOrder.indexOf(a.type) - groupOrder.indexOf(b.type);
                    })}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.title}
                    inputValue={inputValue}
                    onChange={(event, newValue) => {
                        if (newValue !== null) {
                            handleClick(newValue)(event as React.MouseEvent<HTMLDivElement>);
                        } else {
                            // Trigger cleanFilter when the user clears the value
                            handleClick(emptyOption)(event as React.MouseEvent<HTMLDivElement>);
                        }
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    onInputChange={(event, newInputValue) => {
                        handleInputChange(event, newInputValue);

                        // If inputValue becomes empty, trigger the cleanFilter or empty search
                        if (newInputValue === '') {
                            handleClick(emptyOption)(event as React.MouseEvent<HTMLDivElement>);
                        }
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            const matchedOption = options.find((option) => option.title === inputValue);
                            if (matchedOption) {
                                handleClick(matchedOption)(event as unknown as React.MouseEvent<HTMLDivElement>);
                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: { fontFamily: 'var(--font-family)' },
                            }}
                            label={t('Search_Location')}
                            aria-label={t('Search_Location')}
                            type="search"
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {renderBoldText(option.title, inputValue)}
                        </li>
                    )}
                    renderGroup={(params) => (
                        <li key={params.key}>
                            <div style={{ color: 'var(--primary-color)', backgroundColor: 'var(--select-menu-active-color)', padding: '4px 10px' }}>{params.group}</div>
                            <div>{params.children}</div>
                        </li>
                    )}
                />
            </Box>
        </Stack>
    );
};

export default ProfileSearchFreeTextSearch;
