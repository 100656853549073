import React from 'react';
import { Agent, Team, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import MetaTags from '../utils/seo/MetaTags';

interface ProfileMetaTagsProps {
    agent?: Agent;
    team?: Team;
    office?: Office
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
}

const ProfileMetaTags: React.FC<{ profileMetaTags: ProfileMetaTagsProps }> = ({ profileMetaTags }) => {



    // To Do: Meta Tag Data Populate
    /*
    SetSeoNoIndexTag
    
    pageTitle

    metaTitle
    metaDescription
    ogTags
    keyWords

                placeholder["{seo:GeoLocationRepeat}"] = string.Format("{0}, {1}", CurrentAgent["City"], CurrentAgent["Country"]);
                placeholder["{seo:GeoLocation}"] = string.Format("{0}, {1}", CurrentAgent["City"], CurrentAgent["Country"]);
                placeholder["{seo:O.OfficeName}"] = CurrentAgent["OfficeName"].ToString();
                placeholder["{seo:O.OfficeLocation}"] = string.Format("{0}, {1}", CurrentAgent["City"].ToString(), CurrentAgent["Province"].ToString());
                placeholder["{seo:O.Address}"] = CurrentAgent["Address"].ToString();
                placeholder["{seo:O.Address2}"] = CurrentAgent["Address2"].ToString();
                placeholder["{seo:O.LocalZone}"] = CurrentAgent["LocalZone"].ToString();
                placeholder["{seo:O.City}"] = CurrentAgent["City"].ToString();
                placeholder["{seo:O.Province}"] = CurrentAgent["Province"].ToString();
                placeholder["{seo:O.Region}"] = CurrentAgent["OfficeRegion"].ToString();
                placeholder["{seo:O.Country}"] = CurrentAgent["Country"].ToString();
                placeholder["{seo:A.ListingCount}"] = CurrentAgent["InternetListingCount"].ToString();
                placeholder["{seo:O.DisplayTitle}"] = DisplayTitle;
                placeholder["{seo:A.AgentName}"] = CurrentAgent["AgentName"].ToString();
                placeholder["{seo:SiteName}"] = CurrentAgent["Country"].ToString();

                metaTitle = SeoService.GetSeoTag(AgentID, Convert.ToDateTime(CurrentAgent["LastUpdatedOnWeb"]), SeoConstants.SeoTemplateType.MetaTitle, SeoConstants.SeoTarget.AgentDetails, placeholder);
                metaDescription = SeoService.GetSeoTag(AgentID, Convert.ToDateTime(CurrentAgent["LastUpdatedOnWeb"]), SeoConstants.SeoTemplateType.MetaDescription, SeoConstants.SeoTarget.AgentDetails, placeholder);
                ogUrl = Agent.GetAgentProfileURL(CurrentAgent["AgentID"] == null ? 0 : Convert.ToInt32(CurrentAgent["AgentID"]), CurrentAgent["OfficeID"] == null ? 0 : Convert.ToInt32(CurrentAgent["OfficeID"]), CurrentAgent["AgentVirtualUrl"] == null ? string.Empty : CurrentAgent["AgentVirtualUrl"].ToString(), CurrentAgent["AgentShortLink"] == null ? string.Empty : CurrentAgent["AgentShortLink"].ToString(), RemaxRequestContext.RegionId, CurrentAgent["RegionID"] == null ? 0 : Convert.ToInt32(CurrentAgent["RegionID"]), true, true, false);
                if (GTTMMetaTag.Translate("186.Keywords") != null)
                    keyWords = GTTMMetaTag.Translate("186.Keywords").Replace("<span class=\"remaxRed\">RE</span><span class=\"remaxBlue\">/</span><span class=\"remaxRed\">MAX</span>", "TeanantName");
               
                
    */
    const metaTitle = "" // profileMetaTags.agent ?  profileMetaTags.agent.AgentName :  (profileMetaTags.team? profileMetaTags.team.TeamName:  profileMetaTags.office?.OfficeName.toString());
    const metaDescription = "" // profileMetaTags.agent ?  profileMetaTags.agent.AgentName :  (profileMetaTags.team? profileMetaTags.team.TeamName:  profileMetaTags.office?.OfficeName.toString());

    return (

        <MetaTags metaTags={{ title: metaTitle, description: metaDescription }} />

    );
};

export default ProfileMetaTags;
