
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


// TODO: path to the iamge must be dynamic
L.Icon.Default.mergeOptions({
  iconRetinaUrl: `/images/${window.theme !== undefined ? window.theme : ''}/pointer.svg`,
  iconUrl: `/images/${window.theme !== undefined ? window.theme : ''}/pointer.svg`,
  iconSize: [48, 48],
  shadowUrl: `/images/${window.theme !== undefined ? window.theme : ''}/pointer.svg`,

});

interface LeafletMapProps {
  latitude?: number | undefined;
  longitude?: number | undefined;
  zoom: number | undefined;
  scrollWheelZoom: boolean | undefined;
  height: string
  width: string
  zoomControl?: boolean
}

const LeafletMap: React.FC<LeafletMapProps> = ({ longitude, latitude, zoom, scrollWheelZoom, height, width, zoomControl = true }) => {


  // TODO We can default the location to the region
  if (latitude === undefined) {
    latitude = 0
  }
  if (longitude === undefined) {
    longitude = 0
  }

  return (
    <MapContainer
      center={[longitude, latitude]}
      zoom={zoom}
      scrollWheelZoom={scrollWheelZoom}
      zoomControl={zoomControl}
      style={{ height: height, width: width, zIndex: '0' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[longitude, latitude]}>
        {/* <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup> */}
      </Marker>
    </MapContainer>
  );
};

export default LeafletMap;
