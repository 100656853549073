import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import CardImageMottoAddress from '../CardImageMottoAddress';
import { useTranslation } from 'react-i18next';
import { Development } from '../../types/TypesIndex';
import { fetchDevelopmentDataByDevelopmentId } from '../../services/DevelopmentService';
import { PAGE_TYPE } from '../../constants/constants';
import { debug } from 'console';

interface Prop {
    developmentId: number;
    tenantId: number;
    macroRegionId: number;
}

const ListingDevelopment: React.FC<Prop> = ({ developmentId, tenantId, macroRegionId }) => {
    const { t, i18n } = useTranslation();
    const [development, setDevelopment] = useState<Development>();

    useEffect(() => {
        if (developmentId === null || developmentId === 0) return;
        fetchDevelopmentDataByDevelopmentId(developmentId.toString()).then(async (development) => {

            if (development === null) {
                return null;
            } else {
                setDevelopment(development);
            }
        });

    }, [developmentId, i18n.language]);

    if (!development) return null;

    let photoId = "";
    if (development.DevelopmentImages !== null && development.DevelopmentImages.length > 0 && development.DevelopmentImages.filter(d => d.IsMain === "1") !== null && development.DevelopmentImages.filter(d => d.IsMain === "1")[0].ImageFileName !== null) {
        photoId = development.DevelopmentImages.filter(d => d.IsMain === "1")[0].ImageFileName;
    }


    return (
        <>
            <Stack direction="column" spacing={3} sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '2.5rem !important' }}>

                <Typography className="h4" variant="h4">
                    {t('Search_Development')}
                </Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} useFlexGap spacing={3} alignItems="center">
                    {development && (
                        <CardImageMottoAddress mobileWidth='100%' desktopWidth='150px' data={{
                            RegionId: development.RegionId,
                            PhotoId: photoId,
                            PhotoAltText: development.DevelopmentName,
                            Name: development.DevelopmentName,
                            ShortLink: development.DevelopmentShortlink,
                            Address: development.ProvinceName, //+' '+ development.City, 
                            //Motto: development.DevelopmentMotto ?? 0, 
                            PageType: PAGE_TYPE.DEVELOPMENT,

                        }} />
                    )}

                </Stack>
            </Stack>
        </>
    );
};

export default ListingDevelopment;