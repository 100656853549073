import React from 'react';
import { Link, Box, Stack } from '@mui/material';
import { t } from 'i18next';

interface ProfileSocialChatIntegrationProps {
    phone?: string;
    email?: string;
    whatsApp: string;
    lineChat: string;
    lineChatID: string;
    telegramID: string;
    facebookMessengerID: string;
    weChat: string;
    weChatID: string;
    viberID: string;
    marginTop?: string;
    isMobile?: boolean;
}


const ProfileSocialChatIntegration: React.FC<ProfileSocialChatIntegrationProps> = (
    { phone, email, whatsApp, lineChat, lineChatID, telegramID, facebookMessengerID, weChat, weChatID, viberID, marginTop = '0', isMobile = false }) => {

    const ViberUrl = process.env.REACT_APP_VIBERURL + viberID;
    const FbMessengerUrl = process.env.REACT_APP_FACEBOOKMESSENGERURL + facebookMessengerID;
    const TelegramUrl = process.env.REACT_APP_TELEGRAMURL + telegramID;
    const LineChatIDUrl = process.env.REACT_APP_LINECHATURL + lineChatID;
    const WhatsappUrl = process.env.REACT_APP_WHATSAPP + whatsApp;
    const WhatsappMobileUrl = process.env.REACT_APP_WHATSAPP_MOBILE + whatsApp;

    return (
        <Stack direction="row" spacing={.5} useFlexGap flexWrap="wrap" justifyContent="center" sx={{ marginTop: marginTop }}>

            {/* Phone icon */}
            {phone && (
                <Box mx={.5}>
                    <Link href={`tel:${phone}`} title={t('AgentFull_Phone')} aria-label={t('AgentFull_Phone')}>
                        <img src='/images/common/icon-phone.svg' alt={t('AgentFull_Phone')} height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* Email icon */}
            {email && (
                <Box mx={.5}>
                    <Link href={`mailto:${email}`} title={t('AgentFull_Email')} aria-label={t('AgentFull_Email')}>
                        <img src='/images/common/icon-email.svg' alt={t('AgentFull_Email')} height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* Whatsapp icon */}
            {whatsApp && (
                <Box mx={.5}>
                    <Link href={`${isMobile === false ? WhatsappUrl : WhatsappMobileUrl}`} target="_blank" title="WhatsApp" aria-label="WhatsApp">
                        <img src='/images/common/icon-whatsapp.svg' alt="WhatsApp" height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* LineChat ID Icon */}
            {lineChatID && (
                <Box mx={.5}>
                    <Link href={`${LineChatIDUrl}`} target="_blank" title={t('SalesAssociate_LineChat')} aria-label={t('SalesAssociate_LineChat')}>
                        <img src='/images/common/icon-linechat.svg' alt={t('SalesAssociate_LineChat')} height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* Facebook Messenger chat icon */}
            {facebookMessengerID && (
                <Box mx={.5}>
                    <Link href={`${FbMessengerUrl}`} target="_blank" title={t('SocialMedia_FaceBook')} aria-label={t('SocialMedia_FaceBook')}>
                        <img src='/images/common/icon-fbmessenger.svg' alt={t('SocialMedia_FaceBook')} height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* Viber chat icon */}
            {viberID && (
                <Box mx={.5}>
                    {/* To do - No Existing Translation */}
                    <Link href={`${ViberUrl}`} target="_blank" title='Viber' aria-label="Viber">
                        <img src='/images/common/icon-viber.svg' alt='Viber' height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* Telegram chat icon */}
            {telegramID && (
                <Box mx={.5}>
                    {/* To do - No Existing Translation */}
                    <Link href={`${TelegramUrl}`} target="_blank" title='Telegram' aria-label="Telegram">
                        <img src='/images/common/icon-telegram.svg' alt='Telegram' height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* WeChat chat icon */}
            {weChat && (
                <Box mx={.5}>
                    {/* To do - Add title and aria-label translations */}
                    <Link href={`${weChat}`} target="_blank" title="WeChat" aria-label="WeChat">
                        <img src='/images/common/icon-wechat.svg' alt='WeChat' height="24" width="24" loading='lazy' />
                    </Link>
                </Box>
            )}

            {/* To Do: We Chat QR Code */}
            {/* To Do: Line Chat QR Code */}
        </Stack>
    );
};

export default ProfileSocialChatIntegration;
