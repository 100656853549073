// src/utils/handleClick.ts
import { LISTINGSEARCHFILTER } from '../../../constants/constants';
import { SET_SEARCH_STATE } from '../../../reducer/actionTypes';
import { useLocation, useNavigate } from 'react-router-dom';

interface Option {
  group: string;
  title: string;
  type: string;
  expression: string;
  id: string;
}

export const handleClickSearch = (
  option: Option | null,
  search: boolean,
  state: any,
  dispatch: any,
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>
) => {
  return (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    event.preventDefault();
    const payload = { ...state.searchState };
    const searchParams = new URLSearchParams(location?.search || '');

    if (option) {
      switch (option.type) {
        case 'province':
          payload.Province = Array.isArray(payload.Province) ? payload.Province : [];
          if (!payload.Province.includes(option.id)) {
            payload.Province.push(option.id);
          }
          payload.ProvinceNM = Array.isArray(payload.ProvinceNM) ? payload.ProvinceNM : [];
          if (!payload.ProvinceNM.includes(option.id + "-" + option.title)) {
            payload.ProvinceNM.push(option.id + "-" + option.title);
          }
          break;
        case 'city':
          payload.City = Array.isArray(payload.City) ? payload.City : [];
          if (!payload.City.includes(option.id)) {
            payload.City.push(option.id);
          }
          payload.CityNM = Array.isArray(payload.CityNM) ? payload.CityNM : [];
          if (!payload.CityNM.includes(option.id + "-" + option.title)) {
            payload.CityNM.push(option.id + "-" + option.title);
          }
          break;
        case 'localzone':
          payload.LocalZone = Array.isArray(payload.LocalZone) ? payload.LocalZone : [];
          if (!payload.LocalZone.includes(option.id)) {
            payload.LocalZone.push(option.id);
          }
          payload.LocalZoneNM = Array.isArray(payload.LocalZoneNM) ? payload.LocalZoneNM : [];
          if (!payload.LocalZoneNM.includes(option.id + "-" + option.title)) {
            payload.LocalZoneNM.push(option.id + "-" + option.title);
          }
          break;
        case 'streetName':
          payload.StreetName = option.expression;
          break;
        case 'MLSID':
          payload.MLSID = option.expression;
          break;
        case 'DevelopmentName':
          payload.DevelopmentName = option.expression;
          break;
        case 'FreeText':
          payload.FreeText = option.expression;
          break;
        default:
          break;
      }

      dispatch({ type: SET_SEARCH_STATE, payload });

      // Update query string
      searchParams.set('City', Array.isArray(payload.City) ? payload.City.join(',') : '');
      searchParams.set('CityNM', Array.isArray(payload.CityNM) ? payload.CityNM.join(',') : '');
      searchParams.set('Province', Array.isArray(payload.Province) ? payload.Province.join(',') : '');
      searchParams.set('ProvinceNM', Array.isArray(payload.ProvinceNM) ? payload.ProvinceNM.join(',') : '');
      searchParams.set('LocalZone', Array.isArray(payload.LocalZone) ? payload.LocalZone.join(',') : '');
      searchParams.set('LocalZoneNM', Array.isArray(payload.LocalZoneNM) ? payload.LocalZoneNM.join(',') : '');
      if (payload.StreetName) searchParams.set('StreetName', payload.StreetName);
      if (payload.DevelopmentName) searchParams.set('DevelopmentName', payload.DevelopmentName);
      if (payload.MLSID) searchParams.set('MLSID', payload.MLSID);
      if (payload.FreeText) searchParams.set('FreeText', payload.FreeText);

      navigate({ search: searchParams.toString() });
    }

    if (search) {
      let targetUrl = '/listings';

      if (payload.DevelopmentName) {
        targetUrl = '/DevelopmentSearch';
      }

      const queryParams = [
        ...(Array.isArray(payload.City) ? payload.City.map((city: string) => `City=${city}`) : []),
        ...(Array.isArray(payload.CityNM) ? payload.CityNM.map((cityNM: string) => `CityNM=${cityNM}`) : []),
        ...(Array.isArray(payload.Province) ? payload.Province.map((province: string) => `Province=${province}`) : []),
        ...(Array.isArray(payload.ProvinceNM) ? payload.ProvinceNM.map((provinceNM: string) => `ProvinceNM=${provinceNM}`) : []),
        ...(Array.isArray(payload.LocalZone) ? payload.LocalZone.map((localZone: string) => `LocalZone=${localZone}`) : []),
        ...(Array.isArray(payload.LocalZoneNM) ? payload.LocalZoneNM.map((localZoneNM: string) => `LocalZoneNM=${localZoneNM}`) : []),
        payload.StreetName && `StreetName=${payload.StreetName}`,
        payload.DevelopmentName && `DevelopmentName=${payload.DevelopmentName}`,
        payload.MLSID && `${LISTINGSEARCHFILTER.MLSID}=${payload.MLSID}`,
        payload.TransactionTypeUID && `TransactionTypeUID=${payload.TransactionTypeUID}`,
        payload.PriceMin && parseFloat(payload.PriceMin) > 0 && `${LISTINGSEARCHFILTER.PRICEMIN}=${payload.PriceMin}`,
        payload.PriceMax && parseFloat(payload.PriceMax) > 0 && `${LISTINGSEARCHFILTER.PRICEMAX}=${payload.PriceMax}`,
        payload.Bedrooms && parseFloat(payload.Bedrooms) > 0 && `${LISTINGSEARCHFILTER.BEDROOMS}=${payload.Bedrooms}`,
        payload.Bathrooms && parseFloat(payload.Bathrooms) > 0 && `${LISTINGSEARCHFILTER.BATHROOMS}=${payload.Bathrooms}`,
        payload.FreeText && `FreeText=${payload.FreeText}`
      ].filter(Boolean);

      if (queryParams.length > 0) {
        targetUrl += `?${queryParams.join('&')}`;
      }

      navigate(targetUrl);
    }
  };
};
