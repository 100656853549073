import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Hidden, Link, Typography, Stack, Paper, useTheme, useMediaQuery, Skeleton } from '@mui/material';
import { ContactMePopup } from '../utils';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { PAGE_TYPE } from '../../constants/constants';
import { Agent } from '../../types/TypesIndex';
import { ProfileOfficeCardForNameAddressLicense, ProfileSocialChatIntegration, ProfileContactInfo, ProfileTeamNameAndLogo } from '../profile/ProfileComponentsIndex';

interface ProfileSearchAgentCardProps {
    agent: Agent;
}

const ProfileSearchAgentCard: React.FC<ProfileSearchAgentCardProps> = ({ agent }) => {

    // Parameters Declaration
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a data fetch or initialization
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200); // Replace with actual loading logic

        return () => clearTimeout(timer);
    }, []);

    let agentShortLink = `/${PAGE_TYPE.AGENT}/` + agent.AgentId;
    if (agent.AgentShortLinks !== null
        && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        agentShortLink = `/${PAGE_TYPE.AGENT}/` + agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    let teamShortLink = "";
    if (agent.TeamId !== null && agent.TeamId > 0) {
        teamShortLink = `/${PAGE_TYPE.TEAM}/` + agent.TeamId;
        if (agent.TeamShortLinks !== null
            && agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode) !== null
            && agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
            teamShortLink = `/${PAGE_TYPE.TEAM}/` + agent.TeamShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;
    }


    // Hook Declaration
    const [openDialog, setOpenDialog] = useState(false);

    // Events Handlers
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const DesktopSkeleton = (
        <Paper
            sx={{
                p: 3,
                margin: 'auto',
                borderRadius: '1rem',
                marginBottom: '1.5rem',
                maxWidth: '100%',
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
        >
            <Stack direction="column">
                <Grid container spacing={3}>
                    <Grid item xs="auto" alignItems="center">
                        <Skeleton variant="rectangular" width={240} height={180} sx={{ borderRadius: '1rem' }} />
                    </Grid>
                    <Grid item xs={6} alignItems="center" alignContent="center">
                        <Stack spacing={3} direction="column">
                            <Stack spacing={1} direction="column">
                                <Skeleton variant="text" width={200} />
                                <Skeleton variant="text" width={150} />
                            </Stack>
                            <Stack spacing={2} direction="column">
                                <Skeleton variant="rectangular" width={120} height={40} />
                                <Skeleton variant="text" width={180} />
                                <Skeleton variant="text" width={180} />
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="start">
                    <Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: '1.5rem' }} />
                </Grid>
            </Stack>
        </Paper>
    );

    // Mobile Skeleton
    const MobileSkeleton = (
        <Paper
            sx={{
                p: 3,
                margin: 'auto',
                borderRadius: '1rem',
                marginBottom: '1.5rem',
                maxWidth: '100%',
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
        >
            <Grid container spacing={2} alignItems="center" direction="column" textAlign="center" justifyContent="center">
                <Grid item>
                    <Skeleton variant="rectangular" width={240} height={180} sx={{ borderRadius: '1rem' }} />
                </Grid>
                <Grid item xs={12} sm container alignItems="center" alignContent="center" justifyContent="center">
                    <Grid item xs container direction="column" spacing={2} alignItems="center" alignContent="center" justifyContent="center">
                        <Grid item>
                            <Stack spacing={3} direction="column" alignItems="center" alignContent="center" justifyContent="center">
                                <Stack spacing={1} direction="column" alignItems="center" alignContent="center">
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={150} />
                                </Stack>
                                <Stack direction="column" alignItems="center" alignContent="center" justifyContent="center" spacing={2}>
                                    <Skeleton variant="rectangular" width={120} height={40} />
                                    <Skeleton variant="text" width={180} />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    return (
        <>
            {/* Desktop Card */}
            <Hidden lgDown>

                <Paper
                    sx={{
                        p: 3,
                        margin: 'auto',
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        maxWidth: '100%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >

                    <Stack direction="column">

                        <Grid container spacing={3} sx={{flexWrap: 'nowrap !important'}}>

                            {/* First Column */}
                            <Grid item xs="auto" alignItems="center">

                                <Link underline="none" href={`${agentShortLink}`} title={agent.AgentName} aria-label={agent.AgentName} sx={{ textDecoration: 'none !important' }}>
                                    <img height="320" width="240" style={{ borderRadius: '1rem', width: '240px', height: 'auto', maxWidth: '100%' }} alt={agent.AgentName} title={agent.AgentName}
                                        src={getProfileImagePath(agent.RegionId, agent.AgentPhotoId)} />
                                </Link>

                            </Grid>

                            {/* Second Column */}
                            <Grid item  alignItems="center" alignContent="center" sx={{width: '100% !important'}}>

                                <Stack spacing={3} direction="column">

                                    <Stack spacing={1} direction="column">

                                        {/* Agent Name */}
                                        <Link underline="hover" href={`${agentShortLink}`} title={t('ProfileSearch_ShortLink')} aria-label={t('ProfileSearch_ShortLink')} >
                                            <Typography className="h4" component="span">
                                                {agent.AgentName}
                                            </Typography>
                                        </Link>

                                        {/* Agent License Number */}
                                        {agent.AgentLicenseNumber && (
                                            <Typography className="body2 secondary-text" variant="body2">
                                                {t('AgentFull_LicenseNumber')} {agent.AgentLicenseNumber}
                                            </Typography>
                                        )}

                                    </Stack>

                                    <Stack spacing={2} direction="column">

                                        <Stack spacing={{ xs: 2, md: 0 }} sx={{ gap: '1rem !important' }} direction="row" alignItems="center" useFlexGap flexWrap="wrap" justifyContent="flex-start">

                                            {/* Contact Me Button */}
                                            <Button fullWidth={true}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    width: 'auto',
                                                    maxWidth: 'fit-content',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                role="button"
                                                aria-label={t('AgentFull_ContactMe')}
                                                className="primary-outlined-button"
                                                variant="outlined"
                                                onClick={handleOpenDialog}>
                                                {t('ListingFull_ContactMe')}
                                            </Button>

                                            {/* Global Contact Me Popup */}
                                            <ContactMePopup data={{ agentdata: agent, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

                                            {/* Chat Integration Links */}
                                            {agent && <ProfileSocialChatIntegration
                                                phone={agent?.AgentPhone}
                                                email={agent?.AgentEmail}
                                                whatsApp={agent?.WhatsApp}
                                                facebookMessengerID={agent?.FacebookMessengerID}
                                                viberID={agent?.ViberID}
                                                telegramID={agent?.TelegramID}
                                                lineChat={agent?.LINEChat}
                                                lineChatID={agent?.LineChatID}
                                                weChat={agent?.WeChat}
                                                weChatID={agent?.WeChatID}
                                                isMobile={isMobile}
                                            />}

                                        </Stack>

                                        {/* Agent Contact Info */}
                                        {agent && <ProfileContactInfo
                                            hitType={'AgentProfileViewDirectPhone_New'}
                                            contactInfo={{
                                                AgentPhone: agent?.AgentPhone,
                                                DirectDialPhoneNumber: agent?.AgentDirectDialPhone,
                                                OfficePhone: agent?.OfficePhone,
                                                Email: agent?.AgentEmail,
                                                AgentId: agent?.AgentId,
                                                OfficeId: agent?.OfficeId,
                                                RegionId: agent?.RegionId
                                            }} />}

                                    </Stack>

                                    {/* Office Information, such as Office Name and Office Address */}
                                    {agent && agent && <ProfileOfficeCardForNameAddressLicense
                                        data={{
                                            agent: agent,
                                            page: PAGE_TYPE.AGENT,
                                            isSeo: true,
                                            hideOfficeLicenseNumber: true,
                                            hideAgentLicenseNumber: true,
                                            hideUrl: true,
                                        }}
                                    />}

                                </Stack>

                            </Grid>

                        </Grid>

                        {/* Second Row - Agent team information */}
                        <Grid item xs container direction="row" alignItems="start" sx={{width: '100% !important', display: 'block !important'}}>

                            {/* Global Team Name and Logo (component) */}
                            {agent && agent.TeamId !== null && agent.TeamId > 0 && <ProfileTeamNameAndLogo
                                teamId={agent.TeamId}
                                teamName={agent.TeamName}
                                teamRegionId={agent.RegionId}
                                teamPhotoOrLogo={agent.TeamLogo}
                                teamUrl={teamShortLink}
                                alignment="center"
                                direction="row"
                                borderTop="1px solid rgba(0, 0, 0, 0.12)"
                                marginTop="1.5rem"
                                paddingTop="1.5rem"
                                margin="0"
                            />}

                        </Grid>

                    </Stack>

                </Paper>

            </Hidden>

            {/* Mobile Card */}

            <Hidden lgUp>

                <Paper
                    sx={{
                        p: 3,
                        margin: 'auto',
                        borderRadius: '1rem',
                        marginBottom: '1.5rem',
                        maxWidth: '100%',
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    }}
                >
                    <Grid container spacing={2} alignItems="center" direction="column" textAlign="center" justifyContent="center">

                        {/* First Column*/}
                        <Grid item justifyContent="center">
                            {/* To do - Add title and aria-label translations */}
                            <Link underline="none" href={`${agentShortLink}`} title={t('ProfileSearch_ShortLink')} aria-label={t('ProfileSearch_ShortLink')} sx={{ textDecoration: 'none !important' }}>
                                <img style={{ borderRadius: '1rem', width: '240px', height: 'auto', maxWidth: '100%' }}
                                    alt={agent.AgentName}
                                    title={agent.AgentName}
                                    src={getProfileImagePath(agent.RegionId, agent.AgentPhotoId)} />
                            </Link>
                        </Grid>

                        <Grid item xs={12} sm container alignItems="center" justifyContent="center">

                            <Grid item xs container direction="column" spacing={2} justifyContent="center">

                                <Grid item justifyContent="center">

                                    <Stack spacing={3} direction="column">

                                        <Stack spacing={1} direction="column">

                                            {/* Agent Name */}
                                            <Link underline="hover" href={`${agentShortLink}`} title={t('ProfileSearch_ShortLink')} aria-label={t('ProfileSearch_ShortLink')}>
                                                <Typography className="h4" component="span">
                                                    {agent.AgentName}
                                                </Typography>
                                            </Link>

                                            {/* Agent License Number */}
                                            {agent.AgentLicenseNumber && (
                                                <Typography className="body2 secondary-text" variant="body2">
                                                    {t('AgentFull_LicenseNumber')} {agent.AgentLicenseNumber}
                                                </Typography>
                                            )}

                                        </Stack>

                                        <Stack direction="column" alignItems="center" spacing={2}>

                                            {/* Contact Me Button */}
                                            <Button fullWidth={false}
                                                sx={{
                                                    marginBottom: '.5rem !important',
                                                    whiteSpace: 'nowrap',
                                                    width: 'auto',
                                                    maxWidth: 'fit-content',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                className="primary-outlined-button"
                                                variant="outlined"
                                                role="Button"
                                                aria-label={t('ListingFull_ContactMe')}
                                                onClick={handleOpenDialog}>{t('ListingFull_ContactMe')}</Button>

                                            {/* Global Contact Me Popup */}
                                            <ContactMePopup data={{ agentdata: agent, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

                                            {/* * Global Chat Integration Links */}
                                            {agent && <ProfileSocialChatIntegration
                                                phone={agent?.AgentPhone}
                                                email={agent?.AgentEmail}
                                                whatsApp={agent?.WhatsApp}
                                                facebookMessengerID={agent?.FacebookMessengerID}
                                                viberID={agent?.ViberID}
                                                telegramID={agent?.TelegramID}
                                                lineChat={agent?.LINEChat}
                                                lineChatID={agent?.LineChatID}
                                                weChat={agent?.WeChat}
                                                weChatID={agent?.WeChatID}
                                                isMobile={isMobile}
                                            />
                                            }

                                            {/* Global Show More Buttons */}
                                            {agent && <ProfileContactInfo
                                                hitType={'AgentProfileViewDirectPhone_New'}
                                                contactInfo={{
                                                    AgentPhone: agent?.AgentPhone,
                                                    DirectDialPhoneNumber: agent?.AgentDirectDialPhone,
                                                    OfficePhone: agent?.OfficePhone,
                                                    Email: agent?.AgentEmail,
                                                    AgentId: agent?.AgentId,
                                                    OfficeId: agent?.OfficeId,
                                                    RegionId: agent?.RegionId
                                                }} />}

                                        </Stack>

                                        {/* Office Information, such as Office Name and Office Address */}
                                        {agent && agent && <ProfileOfficeCardForNameAddressLicense
                                            data={{
                                                agent: agent,
                                                page: PAGE_TYPE.AGENT,
                                                isSeo: true,
                                                hideOfficeLicenseNumber: true,
                                                hideAgentLicenseNumber: true,
                                                hideUrl: true,
                                            }}
                                        />}

                                        {/* Global Team Name and Logo */}
                                        {agent && agent.TeamId !== null && agent.TeamId > 0 && <ProfileTeamNameAndLogo
                                            teamId={agent.TeamId}
                                            teamName={agent.TeamName}
                                            teamRegionId={agent.RegionId}
                                            teamPhotoOrLogo={agent.TeamLogo}
                                            teamUrl={teamShortLink} />}
                                    </Stack>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>

                </Paper>

            </Hidden>
        </>
    );
};

export default ProfileSearchAgentCard;
