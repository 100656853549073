import React, { useEffect, useState } from 'react';
import { Hidden, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MapboxMap from '../utils/MapBoxMap';
import LeafletMap from '../utils/LeafletMap';
import SiteSettings from '../../types/Settings/SiteSettings';
import { MAP_PROVIDER, MAPZOOM } from '../../constants/constants';



interface Props {
    longitude: number;
    latitude: number;
    scrollToElement: (id: string) => void;
    siteSettings?: SiteSettings;
    title: string;
    showBorderBottom?: boolean
}

const Location: React.FC<Props> = ({ longitude, latitude, scrollToElement, siteSettings, title, showBorderBottom = false }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const defaultMap = siteSettings?.DefaultMapProvider ?? MAP_PROVIDER.LEAFLET

    const [borderBottom, setBorderBottom] = useState({});

    useEffect(() => {
        if (showBorderBottom) {
            setBorderBottom({
                borderBottom: 1,
                borderColor: 'divider',
                paddingBottom: '2.5rem !important'
            });
        } else {
            setBorderBottom({});
        }
    }, [showBorderBottom]);


    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 200);
        return () => clearTimeout(timer);
    }, []);

    const DesktopSkeleton = (
        <Stack id="location" direction="column" spacing={3}>
            <Skeleton variant="rectangular" width={200} height={29}></Skeleton>
            <Skeleton variant="rectangular" width="100%" height={500}></Skeleton>
        </Stack>
    );

    const MobileSkeleton = (
        <Stack id="location" direction="column" spacing={3}>
            <Skeleton variant="rectangular" width={200} height={29}></Skeleton>
            <Skeleton variant="rectangular" width="100%" height={500}></Skeleton>
        </Stack>
    );

    if (loading) {
        return (
            <>
                <Hidden lgDown>
                    {DesktopSkeleton}
                </Hidden>
                <Hidden lgUp>
                    {MobileSkeleton}
                </Hidden>
            </>
        );
    }

    if (longitude === null || latitude === null || (longitude === 0.000000000000 && latitude === 0.000000000000)) {
        return null;
    }

    return (
        <div id="mapDiv">

            <Stack id="location" direction="column" spacing={3} sx={{ ...borderBottom }}>

                {title !== "" && (
                    <Typography className="h4" variant="h4">
                        {title}
                    </Typography>
                )}

                {defaultMap === MAP_PROVIDER.MAPBOX && (
                    <MapboxMap longitude={longitude} latitude={latitude} zoom={MAPZOOM.FIFTEEN} height={'500px'} width={'100%'} scrollWheelZoom={false} />
                )}

                {defaultMap === MAP_PROVIDER.LEAFLET && (
                    <LeafletMap longitude={latitude} latitude={latitude} zoom={MAPZOOM.FIFTEEN} scrollWheelZoom={false} height={'500px'} width={'100%'} />
                )}

            </Stack>

        </div>
    );
};

export default Location;
