import axios, { AxiosRequestConfig } from 'axios';
import memoize from 'memoizee';
import SiteSettings from '../types/Settings/SiteSettings';
import ListingSearchFilters from '../types/Listing/ListingSearchFilters';
import { Team } from '../types/TypesIndex';
import { TENANTS } from '../constants/constants';

const fetchOptions: AxiosRequestConfig = {
  headers: {
    'Accept-Encoding': 'gzip, deflate'
  }
};

const cacheDuration = 60 * 60 * 1000; // Cache for 1 hour

// Correctly defining the fetchWithCache function with memoize
const fetchWithCache = memoize(
  async function <T>(url: string): Promise<T> {
    const response = await axios.get<T>(url, fetchOptions);
    return response.data;
  },
  {
    promise: true,
    maxAge: cacheDuration,
  }
);

const fetchSiteSettings = async (): Promise<SiteSettings> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.regionid}/settings.json`;

 if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;

        if (window.tenantid !== TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.tenantid}/${window.regionid}/settings.json`;
        } else {
          url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.regionid}/settings.json`;
        }  
      });
  } else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.tenantid}/${window.regionid}/settings.json`; 
  }

  return fetchWithCache<SiteSettings>(url);
};

const fetchListingFilters = async (): Promise<ListingSearchFilters> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.regionid}/listingfilter.json`;

  if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        if (window.tenantid !== TENANTS.REMAX) {
          url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.tenantid}/${window.macroregionid}/listingfilter.json`;
        } else {
          url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.regionid}/listingfilter.json`;
        }
      });
  } else if (window.tenantid !== TENANTS.REMAX && window.tenantid !== undefined) {
    url = `${process.env.REACT_APP_BLOB_URL}/sitesettings/${window.tenantid}/${window.macroregionid}/listingfilter.json`;
  }

  return fetchWithCache<ListingSearchFilters>(url);
};


const fetchTeamByTeamId = async (teamId: number): Promise<Team> => {
  let url = `${process.env.REACT_APP_BLOB_URL}/teams/${window.tenantid}/${window.macroregionid}/${teamId}.json`;
  if (window.tenantid === undefined) {
    await fetch('/ConfigRegion')
      .then(response => response.json())
      .then(data => {

        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        url = `${process.env.REACT_APP_BLOB_URL}/teams/${data.tenantid}/${data.macroregionid}/${teamId}.json`;
      });

  }
  return fetchWithCache<Team>(url);
};


export { fetchSiteSettings, fetchListingFilters, fetchTeamByTeamId };
