import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Link, Container, Stack, Paper, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { ProfilePropertiesCountWithLink, ProfileContactInfo, ProfilePhoto, ProfileSocialChatIntegration, ProfileSocialMediaLinks, ProfileBreadcrumbs } from '../../components/profile/ProfileComponentsIndex';
import { ContactMePopup } from '../utils';
import { Agent, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { PAGE_TYPE } from '../../constants/constants';


interface ProfileAgentCardProps {
    agent?: Agent;
    lookupList: LookupItem[];
    siteSettings?: SiteSettings;
    listingCount: number;
}

const ProfileAgentCard: React.FC<{ profileAgentCard: ProfileAgentCardProps }> = ({ profileAgentCard }) => {
    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const languageCode = i18n.language;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or perform data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    const displayTitleTranslation = profileAgentCard.agent?.DisplayTitleUID !== null && profileAgentCard.agent?.DisplayTitleUID !== undefined
        ? `${getLookupTranslationById(profileAgentCard.agent?.DisplayTitleUID.toString(), profileAgentCard.lookupList)}`
        : '';

    let officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileAgentCard.agent?.OfficeId;
    if (profileAgentCard.agent && profileAgentCard.agent?.OfficeShortLinks !== null
        && profileAgentCard.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && profileAgentCard.agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + profileAgentCard.agent.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    // Hook Declaration
    const [openDialog, setOpenDialog] = useState(false);

    // Event Handlers
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (isLoading) {
        return (
            <Grid container spacing={8} alignItems="center">
                {/* Left Column */}
                <Grid item xs={12} md={8}>
                    <Stack direction="column" spacing={5} useFlexGap>
                        <Skeleton variant="text" width="60%" />
                        <Stack spacing={3}>
                            <Skeleton variant="text" width="80%" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="rectangular" width={120} height={40} />
                            <Skeleton variant="text" width={180} />
                            <Skeleton variant="text" width={180} />
                            <Skeleton variant="text" width={180} />
                        </Stack>
                    </Stack>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={4}>
                    <Stack spacing={2} alignItems="center">
                        <Skeleton variant="rectangular" width={240} height={320} />
                        <Skeleton variant="text" width="60%" />
                    </Stack>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={8} alignItems="center">

            {/* Left Column */}
            <Grid item xs={12} md={8}>

                <Stack direction="column" spacing={5} useFlexGap>

                    {/* Breadcrumbs */}
                    <ProfileBreadcrumbs profileBreadcrumbs={{ page: PAGE_TYPE.AGENT, name: profileAgentCard.agent?.AgentName.toString(), siteSettings: profileAgentCard.siteSettings }} />

                    <Stack spacing={3}>

                        <Stack spacing={2}>

                            {/* Agent Name */}
                            <Typography className="h2" variant="h2">
                                {profileAgentCard.agent?.AgentName}
                            </Typography>

                            {/* Office Name */}
                            {/* To Do: SEO Micro Data in Phase II */}
                            <Link
                                className="h4 secondary-text"
                                href={officeShortLink}
                                title={profileAgentCard.agent?.OfficeName}
                                aria-label={profileAgentCard.agent?.OfficeName}
                                underline="hover"
                                color="inherit"
                                itemProp="member"
                                itemScope
                                itemType="http://schema.org/LocalBusiness">
                                {profileAgentCard.agent?.OfficeName}
                            </Link>
                            <img style={{ display: 'none' }} itemProp="image" src="" alt="" title="" />
                            <span style={{ display: 'none' }} itemProp="telephone">{profileAgentCard.agent?.OfficePhone}</span>
                            <span style={{ display: 'none' }} itemProp="priceRange">0 - 99999999</span>
                            <span style={{ display: 'none' }} itemProp="currenciesAccepted"></span>
                            <span style={{ display: 'none' }} itemProp="address"></span>
                            <span style={{ display: 'none' }} itemProp="areaServed">{profileAgentCard.agent?.OfficeCountry}</span>
                            <span style={{ display: 'none' }} itemProp="brand"></span>
                            <span style={{ display: 'none' }} itemProp="legalName">{profileAgentCard.agent?.OfficeName}</span>
                            <span style={{ display: 'none' }} itemProp="location">{profileAgentCard.agent?.OfficeCity}</span>
                            <span style={{ display: 'none' }} itemProp="name">{profileAgentCard.agent?.OfficeName}</span>

                        </Stack>

                        <Stack spacing={2}>

                            <Stack useFlexGap
                                direction={{ xs: 'column', md: 'row' }} // Column layout for mobile, row layout for desktop
                                alignItems={{ xs: 'flex-start', md: 'center' }} // Align items to flex-start on mobile
                                spacing={{ xs: 1, md: 2 }} // Spacing between items
                                className="gridItem"
                                sx={{ marginTop: '1.5rem', marginBottom: '1rem', alignItems: 'center' }}
                            >

                                <Button type="button" role="button" aria-label={t('ListingFull_ContactMe')}
                                    className="primary-button" variant="contained" onClick={handleOpenDialog}>
                                    {t('ListingFull_ContactMe')}
                                </Button>

                                {/* Global Chat Integration Links (component) */}
                                {profileAgentCard.agent &&
                                    <ProfileSocialChatIntegration
                                        phone={profileAgentCard.agent?.AgentPhone}
                                        email={profileAgentCard.agent?.AgentEmail}
                                        whatsApp={profileAgentCard.agent?.WhatsApp}
                                        facebookMessengerID={profileAgentCard.agent?.FacebookMessengerID}
                                        viberID={profileAgentCard.agent?.ViberID}
                                        telegramID={profileAgentCard.agent?.TelegramID}
                                        lineChat={profileAgentCard.agent?.LINEChat}
                                        lineChatID={profileAgentCard.agent?.LineChatID}
                                        weChat={profileAgentCard.agent.WeChat}
                                        weChatID={profileAgentCard.agent.WeChatID}
                                        isMobile={isMobile}
                                    />}
                            </Stack>

                            {/* Global Contact Info Section, eg Phone, Email (component) */}
                            {profileAgentCard.agent &&
                                <ProfileContactInfo hitType={'AgentProfileViewDirectPhone_New'}
                                    contactInfo={{
                                        AgentPhone: profileAgentCard.agent?.AgentPhone,
                                        DirectDialPhoneNumber: profileAgentCard.agent?.AgentDirectDialPhone,
                                        OfficePhone: profileAgentCard.agent?.OfficePhone,
                                        Email: profileAgentCard.agent?.AgentEmail,
                                        siteSettings: profileAgentCard.siteSettings,
                                        AgentId: profileAgentCard.agent?.AgentId,
                                        OfficeId: profileAgentCard.agent?.OfficeId,
                                        RegionId: profileAgentCard.agent?.RegionId,
                                    }}
                                />}
                        </Stack>

                    </Stack>

                </Stack>

            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={4}>

                <Stack spacing={2}>

                    {/* Global Profile Photos (component) */}
                    {/* To Do: Seo Micro Data */}
                    {profileAgentCard.agent && <ProfilePhoto profilePhoto={{
                        imagePath: getProfileImagePath(profileAgentCard.agent.RegionId, profileAgentCard.agent.AgentPhotoId),
                        alt: displayTitleTranslation + ' - ' + profileAgentCard.agent?.AgentName + ' - ' + profileAgentCard.agent?.OfficeName,
                        title: profileAgentCard.agent?.AgentName
                    }} />}

                    {/* Global Agent/Office Properties Link (component) */}
                    {profileAgentCard.agent && !profileAgentCard.agent.ExcludeAgentTraining &&
                        <ProfilePropertiesCountWithLink agent={profileAgentCard.agent} page="agent" listingCount={profileAgentCard.listingCount} />}

                    {/* Global Social Media Link (component) */}
                    {profileAgentCard.agent && <ProfileSocialMediaLinks
                        socialMedias={profileAgentCard.agent?.SocialMedias}
                        isSocialMediaEnabled={profileAgentCard.agent?.IsSocialMediaEnabled}
                        lookupList={profileAgentCard.lookupList}
                        siteSettings={profileAgentCard.siteSettings}
                    />}
                </Stack>

            </Grid>

            {/* Hidden Contact Me Popup */}
            {/* To Do: Check the detail once Contact Form is available */}
            <ContactMePopup data={{ agentdata: profileAgentCard.agent, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog }} />

        </Grid>

    );
};

export default ProfileAgentCard;
