import LookupItem from '../../../types/LookupItem';

function getLookupTranslationById(uid: string, lookupList: LookupItem[] ): string {
  
    const lookupItem= lookupList.find(l => l.ItemName === uid);

    return lookupItem ? lookupItem.Translation : uid;   
}

function getLookupTranslationByIds (uids : string[] , lookupList: LookupItem[]) {
    let lst = []; 

    for (let UID of uids) { 
        lst.push({
            "UID" : UID,
            "Value": getLookupTranslationById(UID.toString(), lookupList),
            "DisplayValue": getLookupTranslationById(UID.toString(), lookupList),
        }); 
    }  
    
    return lst;
}
  
function getListingSearchFilterLookupItemList (uidLstStr : string , lookupList: LookupItem[]) {
    let lst = []; 

    for (let UID of uidLstStr.split(',')) { 
        lst.push({
            "UID" : UID,
            "Value": getLookupTranslationById(UID.toString(), lookupList),
            "DisplayValue": getLookupTranslationById(UID.toString(), lookupList),
        }); 
    }  
    
    return lst;
}


function getListingSearchFilterMacroPropertyTypeList(uids: string[], lookupList: LookupItem[]) {
    let lst = [];

    for (let uid of uids) { 
        lst.push({
            "MacroPropertyTypeUID" : uid,
            "Value": getLookupTranslationById(uid.toString(), lookupList),
            "DisplayValue": getLookupTranslationById(uid.toString(), lookupList),            
        }); 
    }  
    
    return lst.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));

}


export { getLookupTranslationById, getLookupTranslationByIds, getListingSearchFilterLookupItemList, getListingSearchFilterMacroPropertyTypeList };