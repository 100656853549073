import { CardMedia, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getProfileDefaultImagePath, getProfileImagePath } from "./utils/helper/ImageHelper";
import { Agent } from '../types/TypesIndex';
import { getFirstNCharacters } from "./utils/helper/DataFormatHelper";
import { t } from 'i18next';
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import CardImageMottoAddressType from '../types/CardImageMottoAddress';
import { PAGE_TYPE } from "../constants/constants";

interface Props {

  data: CardImageMottoAddressType
  mobileWidth?: string
  desktopWidth?: string
}

const CardImageMottoAddress: React.FC<Props> = ({ data, mobileWidth = '100%', desktopWidth = '300px' }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  return (
    <>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} useFlexGap alignItems={{ xs: 'start', md: 'center' }} sx={{ width: '100%' }}>
        <Link className="" href={data.ShortLink} title={data.Name} aria-label={data.Name} underline="hover" color="inherit">
          <CardMedia
            component="img"
            sx={{
              margin: 'unset !important', width: { xs: mobileWidth, md: desktopWidth } // Adjusted width based on breakpoints
            }}
            image={getProfileImagePath(data.RegionId, data.PhotoId)}
            alt={data.PhotoAltText}
            title={data?.Name}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // prevents looping
              target.src = getProfileDefaultImagePath(); // fallback image URL
            }}
          />
        </Link>

        <Stack direction="column" spacing={1}>

          <Typography className="body1-medium" variant="body1">

            {data.Name}

          </Typography>

          {
            data.Motto && (
              <Typography className="body1 secondary-text" variant="body1">{data.Motto}</Typography> //To Do - Add elipsis
            )
          }

          {
            data.Address && (
              <Typography className="body1 secondary-text" variant="body1" dangerouslySetInnerHTML={{ __html: data.Address }}></Typography>
            )
          }
          <Link className="" href={data.ShortLink} title={data.Name} aria-label={data.Name} underline="hover" color="inherit">
            {
              data.Url && (
                <Typography className="body1" variant="body1" mb={3}>
                  <Link className=" secondary-text" target="_blank" href={data.Url} title={t("OfficeFull_MoreInfo")} aria-label={t("OfficeFull_MoreInfo")} underline="hover" color="inherit">
                    {isMobile ? getFirstNCharacters(data.Url, 15) : getFirstNCharacters(data.Url, 35)}
                  </Link>
                </Typography>
              )
            }

            <Typography className="body1-medium" variant="body1" mb={3}>
              <a href={data.ShortLink} style={{ display: 'flex', alignItems: 'center' }}>
                {t("AgentFull_MoreInfo")}
                <KeyboardArrowRightIcon className="flip-text" style={{ fontSize: 'large', marginLeft: '0.25rem' }} />
              </a>
            </Typography>
          </Link >
        </Stack>

      </Stack >

    </>
  );
};

export default CardImageMottoAddress;