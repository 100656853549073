import React, { useState } from 'react';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const MainMenu: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);


    let ProfileSearchUrl = "/ProfileSearch?page=1";
    if (window.macroregionid !== null && window.macroregionid > 0) {
        ProfileSearchUrl = "/ProfileSearch?page=1&countryId=" + window.macroregionid + "&searchType=agent";
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleModalOpen = () => {
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const currentUrl = window.location.href;

    // Check if the current URL is the specific one
    const isSpecificUrl = currentUrl.includes('https://expdemo.realestateplatform.com/'); // Replace 'xyz' with your specific URL condition

    return (
        <>
            <Button className="menu-button" role="button" variant="outlined" aria-label="navigation" onClick={handleMenuOpen}>
                <MenuIcon />
                <AccountCircleIcon />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >

                {/* To Do - This is a hack for EXP DEMO - DO NOT KEEP THIS */}
                {isSpecificUrl ? (
                    <>
                        <MenuItem key="commercial" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="https://www.expsacommprop.co.za/results/all-properties/" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">Commercial</Link>
                        </MenuItem>
                        <MenuItem key="buy" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" href="/listings?TransactionTypeUID=261" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">Buy</Link>
                        </MenuItem>
                        <MenuItem key="sell" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">Sell</Link>
                        </MenuItem>
                        <MenuItem key="developments" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="/developmentsearch" rel="noopener noreferrer">Developments</Link>
                        </MenuItem>
                        <MenuItem key="join-our-team" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="/join-our-team" rel="noopener noreferrer">Join our team</Link>
                        </MenuItem>
                        <MenuItem key="about-us" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">About us</Link>
                        </MenuItem>
                        <MenuItem key="contact-us" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">Contact us</Link>
                        </MenuItem>
                        <MenuItem key="find-an-agent" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="/ProfileSearch?page=1" rel="noopener noreferrer" underline="none" color="inherit" target="_blank">Find an agent</Link>
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem key="buy" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="/listings?TransactionTypeUID=261" rel="noopener noreferrer" title={t('Search_ForSale')} aria-label={t('Search_ForSale')} underline="none" color="inherit">{t('Search_ForSale')}</Link>
                        </MenuItem>
                        <MenuItem key="sell" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" href="https://remax-brazil.prod.gryphtech.com/sellersportal.aspx" rel="noopener noreferrer" title={t('Sell')} aria-label={t('Sell')} underline="none" color="inherit" target="_blank">{t('Sell')}</Link>
                        </MenuItem>
                        <MenuItem key="profile-search" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href={ProfileSearchUrl} rel="noopener noreferrer" title={t('WebSite_AgentsAndOffices')} aria-label={t('WebSite_AgentsAndOffices')} underline="none" color="inherit">{t('WebSite_AgentsAndOffices')}</Link>
                        </MenuItem>
                        <MenuItem key="be-an-agent" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="https://brasil.remaxbrasil.com.br/seja-um-corretor" rel="noopener noreferrer" title={t('WebSite_BeAnAgent')} aria-label={t('WebSite_BeAnAgent')} underline="none" color="inherit" target="_blank">{t('WebSite_BeAnAgent')}</Link>
                        </MenuItem>
                        <MenuItem key="be-a-franchisee" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="https://brasil.remaxbrasil.com.br/franquias-imobiliarias-seja-um-franqueado" rel="noopener noreferrer" title={t('WebSite_BeAFranchisee')} aria-label={t('WebSite_BeAFranchisee')} underline="none" color="inherit" target="_blank">{t('WebSite_BeAFranchisee')}</Link>
                        </MenuItem>
                        <MenuItem key="blog" onClick={handleMenuClose} className="text-align-left">
                            <Link className="text-align-left" sx={{ textDecoration: 'none !important' }} href="http://blog.remax.com.br/" rel="noopener noreferrer" title={t('WebSite_Blog')} aria-label={t('WebSite_Blog')} underline="none" color="inherit" target="_blank">{t('WebSite_Blog')}</Link>
                        </MenuItem>
                    </>
                )}
            </Menu>

        </>
    );
};

export default MainMenu;