import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CircularProgress, Container, Paper } from '@mui/material';
import { fetchOfficeDataByOfficeId, featchAgentListByOfficeId, featchAgentListByTeamId } from '../../services/ProfileService';
import { fetchListingCountByOfficeId, fetchListingCountByMacroOfficeId, fetchListingCountByTeamId } from '../../services/ListingService';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { fetchSiteSettings, fetchTeamByTeamId } from '../../services/SiteSettingService';
import {  TENANTS } from '../../constants/constants';
import { Agent, Team, Office, LookupItem, SiteSettings } from '../../types/TypesIndex';
import { ProfileMetaTags, ProfileOfficeCard, ProfileOfficeMain, ProfileTeamCard, ProfileTeamMain } from '../../components/profile/ProfileComponentsIndex';

// Interface Declaration
interface TeamRouteParam extends Record<string, string | undefined> {
    teamid: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const TeamProfile: React.FC = () => {

    // Parameters Declaration
    const params = useParams<TeamRouteParam>();
    const navigate = useNavigate(); // No Office Found Redirection; To Do in Agent Profile
    const { i18n } = useTranslation();

    // Hook Declaration
    const [team, setTeam] = useState<Team>();
    const [agentList, setAgentList] = useState<Agent[]>([]);
    const [teamListingCount, setTeamListingCount] = useState(0);
    const [spokenLanguages, setSpokenLanguages] = useState<string[]>([]);
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    // UseEffect Data Loading */ }
    useEffect(() => {
        const loadDataSource = async () => {
            try {

                setIsLoading(true);

                await fetchLookupTranslations().then((lookupList) => {
                    if (lookupList == null) {
                        console.log("lookupList loads nothing");
                    } else {
                        setLookupList(lookupList);
                    }
                });

                await fetchSiteSettings().then(async (siteSettings) => {
                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                    } else {
                        setSiteSettings(siteSettings);

                        let teamid = params.teamid ?? params.url;
                        if (teamid !== undefined && teamid !== null) {
                            const teaminfo = await fetchTeamByTeamId(parseFloat(teamid));

                            if (teaminfo === undefined || teaminfo === null) {
                                let navigateUrl = "/ProfileSearch?searchType=office&page=1";
                                if (siteSettings && siteSettings.CountryID > 0)
                                    navigateUrl = navigateUrl + "&countryId=" + siteSettings.CountryID;
                                navigate(navigateUrl);
                                return null;
                            }

                            setTeam(teaminfo);

                            var teamListingCount = await fetchListingCountByTeamId(parseFloat(teamid));
                            setTeamListingCount(teamListingCount['@odata.count']);

                            // Get the agent list by Team Id
                            const aList = await featchAgentListByTeamId(teamid);
                            if (aList !== null && aList.value !== null && aList.value.length > 0) {
                                setAgentList(aList.value?.map(a => a.content));

                                if (aList['@search.facets'] !== null && aList['@search.facets']?.['content/SpokenLanguages'] !== null && typeof (aList['@search.facets']?.['content/SpokenLanguages']) !== 'undefined') {
                                    setSpokenLanguages(aList['@search.facets']?.['content/SpokenLanguages']?.map(item => item.value));
                                }
                            }
                        }

                        setIsLoading(false);
                    }
                });

            } catch (error) {
                console.error('Agent Profile: failed to load site data source', error);
            }
        };

        loadDataSource();
    }, [i18n.language]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
            </div>
        );
    }

    return (
        <div>
            {/* Set Helmet properties */}
            <ProfileMetaTags profileMetaTags={{ team: team, lookupList: lookupList, siteSettings: siteSettings }} />

            {/* Top Container */}
            <div>

                <Container className="text-align-left light" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>

                    <ProfileTeamCard profileTeamCard={{
                        team: team,
                        teamListingCount: teamListingCount, 
                        lookupList: lookupList,
                        siteSettings: siteSettings,
                    }} />
   

                </Container>

            </div>

            {/* Middle Container */}
            <div style={{ backgroundColor: 'white' }}>

                    <Container className="text-align-left" sx={{
                        paddingTop: {
                            xs: '2.5rem',
                            md: '3rem'
                        },
                        paddingBottom: {
                            xs: '2.5rem',
                            md: '3rem'
                        },
                    }}>
                    

                    <ProfileTeamMain profileTeamMain={{
                        team: team,
                        agentList: agentList,
                        spokenLanguages: spokenLanguages,
                        lookupList: lookupList,
                        siteSettings: siteSettings,
                        teamListingCount: teamListingCount, 
                    }} />


                </Container>

            </div>

            {/* Bottom container */}
            {/* Team Office Footer,  It includes Recently sold rented listed carousels component in Phase II*/}

        </div>);
};

export default TeamProfile;