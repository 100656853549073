import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import { Sell as SellIcon, MeetingRoom as MeetingRoomIcon, Work as WorkIcon, Roofing as RoofingIcon, Landscape as LandscapeIcon } from '@mui/icons-material';
import { useSearch } from '../../reducer/searchreducer';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { TRANSACTION_TYPES, PROPERTY_TYPES, PAGE_TYPE, LISTINGSEARCHFILTER, TENANTS } from '../../constants/constants';
import { updateLocationUrl } from '../utils/helper/FunctionHelper';

interface ListingSearchTabFiltersProps {
    page?: string;
}

const ListingSearchTabFilters: React.FC<ListingSearchTabFiltersProps> = ({ page }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useSearch();

    // State variables
    const [transactionType, setTransactionType] = useState<number>(TRANSACTION_TYPES.FORSALE);
    const [listingClass, setListingClass] = useState<number>(PROPERTY_TYPES.NOTSELECTED);

    // Handle transaction type change
    const handleTransactionTypeChange = (event: React.SyntheticEvent, value: number) => {
        // Relationship: The Transaction Type change events includes the Price Range
        // Update Query String URL on Listing Search Page only
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.TRANSACTIONTYPEUID, value.toString());
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMIN, null);
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.PRICEMAX, null);
        }

        // Update Selected Value State
        setTransactionType(value);

        // Trigger the Search State
        const updatedSearchState = {
            ...state.searchState,
            PriceMin: null,
            PriceMax: null,
            TransactionTypeUID: value.toString(),
        };
        dispatch({ type: SET_SEARCH_STATE, payload: updatedSearchState });

    };

    // Handle listing class change
    const handleListincClassChange = (event: React.SyntheticEvent, value: number) => {
        // Relationship: The Listing Class change events includes the Macro Property Types, BedRooms, BathRooms, TotalRooms, Floor Level
        // Update Query String URL on Listing Search Page only
        if (page && page === PAGE_TYPE.LISTINGS) {
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.LISTINGCLASS, value.toString());
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.MACROPROPERTYTYPEUIDS, null);
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BEDROOMS, null);
            updateLocationUrl(new URLSearchParams(window.location.search), LISTINGSEARCHFILTER.BATHROOMS, null);
        }

        setListingClass(value);

        // Update search state
        const updatedSearchState = {
            ...state.searchState,
            MacroPropertyTypeUIDs: null,
            Bedrooms: null,
            Bathrooms: null,
            ListingClass: value.toString(),
        };
        dispatch({ type: SET_SEARCH_STATE, payload: updatedSearchState });
    };

    // Sync state from the Query String URL params on component mount
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const transactionTypeParam = searchParams.get(LISTINGSEARCHFILTER.TRANSACTIONTYPEUID);
        const listingClassParam = searchParams.get(LISTINGSEARCHFILTER.LISTINGCLASS);

        if (transactionTypeParam) {
            setTransactionType(parseInt(transactionTypeParam));
        }

        if (listingClassParam) {
            setListingClass(parseInt(listingClassParam));
        } else {
            setListingClass(PROPERTY_TYPES.NOTSELECTED);
        }
    }, []);

    // Ensure valid tab values
    const validTransactionTypes = [TRANSACTION_TYPES.FORSALE, TRANSACTION_TYPES.FORRENT];
    const validListingClasses = [PROPERTY_TYPES.RESIDENTIAL, PROPERTY_TYPES.COMMERCIAL, PROPERTY_TYPES.LAND];

    return (
        <>
            <Grid item sx={{ maxWidth: '100%', display: 'flex', overflowY: 'auto' }}>
                <Box>
                    <Tabs
                        value={validTransactionTypes.includes(transactionType as 260 | 261) ? transactionType as 260 | 261 : TRANSACTION_TYPES.FORSALE}
                        onChange={handleTransactionTypeChange}
                        aria-label="Transaction type tabs"
                    >
                        <Tab
                            value={TRANSACTION_TYPES.FORSALE}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <SellIcon className="margin-right-8" /> {/* Add margin between icon and text */}
                                    {t('Search_ForSale')}
                                </div>
                            }
                            className="pills-tab"

                            sx={{
                                marginLeft: '.5rem', marginRight: '.5rem', whiteSpace: 'nowrap',
                                '&.Mui-selected': {
                                    borderBottom: 'none !important', // Hide the bottom border when tab is selected
                                    '& .MuiTabs-indicator': {
                                        display: 'none', // Hide the indicator span when tab is selected
                                    },
                                },
                            }}
                        />
                        <Tab
                            value={TRANSACTION_TYPES.FORRENT}
                            sx={{
                                marginLeft: '.5rem', marginRight: '.5.rem', whiteSpace: 'nowrap', // Prevent text wrapping
                            }}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MeetingRoomIcon className="margin-right-8" /> {/* Add margin between icon and text */}
                                    {t('Search_ForRent')}
                                </div>
                            }
                            className="pills-tab"

                        />
                    </Tabs>
                </Box>

                {page && page === PAGE_TYPE.LISTINGS && (
                    <Box>
                        <Tabs
                            value={validListingClasses.includes(listingClass as 1 | 2 | 3) ? listingClass as 1 | 2 | 3 : PROPERTY_TYPES.NOTSELECTED}
                            onChange={handleListincClassChange}
                            aria-label="Listing class tabs"
                        >
                            <Tab
                                value={PROPERTY_TYPES.RESIDENTIAL}
                                sx={{
                                    marginLeft: '.5rem', marginRight: '.5.rem', whiteSpace: 'nowrap', // Prevent text wrapping
                                }}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <RoofingIcon className="margin-right-8" /> {/* Add margin between icon and text */}
                                        {t('Search_Residential')}
                                    </div>
                                }
                                className="pills-tab"

                            />
                            <Tab
                                value={PROPERTY_TYPES.COMMERCIAL}
                                sx={{
                                    marginLeft: '.5rem', marginRight: '.5.rem', whiteSpace: 'nowrap', // Prevent text wrapping
                                }}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <WorkIcon className="margin-right-8" /> {/* Add margin between icon and text */}
                                        {t('Search_Commercial')}
                                    </div>
                                }
                                className="pills-tab"

                            />
                            {window.tenantid !== undefined && window.tenantid !== TENANTS.REMAX && (
                                <Tab
                                    value={PROPERTY_TYPES.LAND}
                                    sx={{
                                        marginLeft: '.5rem', marginRight: '.5.rem', whiteSpace: 'nowrap',
                                    }}
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LandscapeIcon className="margin-right-8" />
                                            {t('Search_Land')}
                                        </div>
                                    }
                                    className="pills-tab"

                                />
                            )}
                        </Tabs>
                    </Box>
                )}
            </Grid>
        </>
    );
};

export default ListingSearchTabFilters;