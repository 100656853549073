import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Listing from '../pages/listing/ListingSearch';
import ListingCardDetail from '../pages/listing/ListingDetails';
import ListingDetails from '../pages/listing/ListingDetails';
import ProfileSearch from '../pages/profilesearch/ProfileSearch';
import AgentProfile from '../pages/profile/AgentProfile';
import OfficeProfile from '../pages/profile/OfficeProfile';
import TeamProfile from '../pages/profile/TeamProfile';
import DevelopmentDetail from '../pages/development/DevelopmentDetail';
import DevelopmentSearch from '../pages/development/DevelopmentSearch';

import About from '../pages/About';
import Contact from '../pages/Contact';
import CMSPage from '../pages/CMSPage';
import Blog from '../pages/Blog';
import BlogPost from '../pages/BlogPost';
import HomeCMS from '../pages/HomeCMS';

interface AppRouteProps {
  toggleDirection: (data: string) => void;
  toggleLanguages: (data: string) => void;
  hideToolBar: (data: boolean) => void;
}

const AppRoute: React.FC<AppRouteProps> = ({
  toggleDirection,
  toggleLanguages,
  hideToolBar
}) => {
  const location = useLocation();
  const [DynamicComponent, setDynamicComponent] = useState<React.ComponentType | null>(null);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch('/ConfigRegion');
        const data = await response.json();
        window.regionDomain = data.regiondomain;
        window.regionid = data.regionid;
        window.macroregionid = data.macroregionid;
        window.tenantid = data.tenantid;
        window.theme = data.theme;
        
      } catch (error) {
        console.error('Error loading config:', error);
      }
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const routesToHideToolBar = ['/listings'];
    if (routesToHideToolBar.includes(location.pathname.toLocaleLowerCase())) {
      hideToolBar(true);
    } else {
      hideToolBar(false);
    }
    const path = location.pathname.toLowerCase().substring(1);
    const mlsidPattern = /^(\d+-\d+)$/;
    if (mlsidPattern.test(path)) {
      setDynamicComponent(() => ListingCardDetail);
    } else {
      setDynamicComponent(() => CMSPage as React.ComponentType);
    }
  }, [location, hideToolBar]);



  return (
    <Routes>
      <Route path="/Home" element={<Home value={0} />} />
      <Route path="/" element={<HomeCMS />} />
      <Route path="/:content" element={DynamicComponent ? <DynamicComponent /> : null} />

      <Route path="/:language/:content" element={DynamicComponent ? <DynamicComponent /> : null} />

      <Route path="/listings" element={<Listing  />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:city/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:transactiontype/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:macropropertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />
      {/* use case where property translations has / */}
      <Route path="/:languagetag/:pagety/:city/:content" element={<ListingCardDetail />} />
      <Route path="/:languagetag/:pagetype/:propertytype/:propertytype2/:macropropertytype/:transactiontype/:city/:listingaddress/:content" element={<ListingCardDetail />} />

      <Route path="/ProfileSearch" element={<ProfileSearch />} />

      <Route path="/office/:url" element={<OfficeProfile />} />
      <Route path="/office/:id" element={<OfficeProfile />} />
      <Route path="/office/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:officename/:officeid" element={<OfficeProfile />} />
      <Route path="/:languagetag/offices/:countryorregion/:cityorlozalzone/:officename/:officeid" element={<OfficeProfile />} />


      <Route path="/team/:url" element={<TeamProfile />} />
      <Route path="/team/:id" element={<TeamProfile />} />
      <Route path="/team/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:teamname/:teamid" element={<TeamProfile />} />

      <Route path="/agent/:url" element={<AgentProfile />} />
      <Route path="/agent/:id" element={<AgentProfile />} />
      <Route path="/agent/:languagetag/:pagetype/:countryorregion/:cityorlozalzone/:agentname/:agentid" element={<AgentProfile />} />
      <Route path="/:languagetag/agents/:countryorregion/:cityorlozalzone/:agentname/:agentid" element={<AgentProfile />} />

      <Route path="/developments/:url/:developmentId" element={<DevelopmentDetail />} />
      <Route path="/developments/:developmentId" element={<DevelopmentDetail />} />
      <Route path="/developments" element={<DevelopmentSearch />} />

      <Route path="/Blog" element={<Blog />} />
      <Route path="/Blog/:id" element={<BlogPost />} />

      <Route path="/Contact" element={<Contact />} />
      <Route path="/v1/:content" element={<CMSPage />} />
      {/* Old code */}
      {/* <Route path="/About" element={<About />} />
      {/* <Route path="/v1/:content" element={<CMSPage />} /> */}
      {/* <Route path="/Properties" element={<Listing toggleDirection={toggleDirection} toggleLanguages={toggleLanguages} />} />
      <Route path="/Blob" element={<Listing toggleDirection={toggleDirection} toggleLanguages={toggleLanguages} />} />
      <Route path="/Faq" element={<ListingDetails />} />*/}
      {/* <Route path="/:mlsid" element={<ListingCardDetail />} /> */}
    </Routes>
  );
};

export default AppRoute;
