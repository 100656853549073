import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { Typography, Stack, Grid, Container, Paper, Pagination, CircularProgress, Fab } from '@mui/material';
import { FormatListBulleted as FormatListBulletedIcon, Map as MapIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSearch } from '../../reducer/searchreducer';
import { useLoading } from '../../components/utils/LoadingContext';
import { LISTING_SEARCH_VIEW, LISTINGSEARCHFILTER, PROPERTY_TYPES, TRANSACTION_TYPES } from '../../constants/constants';
import { getOrderByValue, listingSearch } from '../../components/utils/helper/Filter';
import ListingDetailContent from '@/types/Listing/ListingDetailContent';
import { LookupItem, SiteSettings } from '../../types/TypesIndex';
import { fetchListingDataV2 } from '../../services/ListingService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchLookupTranslations } from '../../services/TranslationService';
import { formatNumberWithRegionSetting, formatShortPrice, getAlternativeCurrencyCode } from '../../components/utils/helper/FormatNumberHelper';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';

import ListingSearchTopBar from '../../components/listing/ListingSearchTopBar';
import ListProperties from '../../components/listing/ListingCardList';
import { de } from 'date-fns/locale';
import { use } from 'i18next';
import MetaTags from '../../components/utils/seo/MetaTags';
import { getLookupTranslationById } from '../../components/utils/helper/LookupHelper';


// Lazy-load components
//const ListProperties = lazy(() => import('../../components/listing/ListingCardList'));
//const ListingSearchTopBar = lazy(() => import('../../components/listing/ListingSearchTopBar'));
const MapboxMap = lazy(() => import('../../components/utils/MapBoxMap'));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface Pin {
    latitude: number;
    longitude: number;
    value: string;
    listingId: string;
    transactionType: string;
    listingCurrency: string;
}



const ListingsSearch: React.FC = (
) => {
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile
    const history = useNavigate();

    // Hook Declaration 
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useSearch(); // Use the search context

    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    const [listings, setListings] = useState<ListingDetailContent | any>();
    const [listingsMap, setListingsMap] = useState<ListingDetailContent | any>();
    const [totalrecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState<string>(LISTING_SEARCH_VIEW.LIST); // State to track the selected view
    const [sort, setSort] = useState<string>('Search_MostRecent'); // Set the initial state to the value of "Most Recent"

    // Event Hanlder
    const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);

        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Use Effect



    // Trigger Search
    const loadData = useCallback(async () => {
        if (window.tenantid !== undefined) {
            const searchParams = new URLSearchParams(location?.search || '');
            const filter = listingSearch(searchParams, state.searchState);
            const orderBy = getOrderByValue(sort);
            const searchRequestBody = {
                count: true,
                skip: (currentPage - 1) * 24,
                top: 24,
                searchMode: 'any',
                queryType: 'simple',
                search: '*',
                filter,
                orderby: orderBy,
            };
            const result = await fetchListingDataV2(searchRequestBody);
            //  setSort('');
            setListings(result);
            console.log("Result:", result);
            if (view === LISTING_SEARCH_VIEW.MAP) {
                const mapSearchRequestBody = {
                    count: true,
                    skip: 0,
                    top: 500,
                    searchMode: 'any',
                    queryType: 'simple',
                    search: '*',
                    filter,
                    select: "content/Location , content/ListingPrice , content/MLSID, content/TransactionTypeUID, content/ListingCurrency",
                };
                const mapResult = await fetchListingDataV2(mapSearchRequestBody);
                setListingsMap(mapResult);
            }

            setTotalRecords(result['@odata.count']);
            setTotalPages(Math.ceil(result['@odata.count'] / 24));

            if (currentPage > Math.ceil(result['@odata.count'] / 24)) {
                setCurrentPage(1);
            }
        }
        else {
            await fetch('/ConfigRegion')
                .then(response => response.json())
                .then(data => {

                    window.regionDomain = data.regiondomain;
                    window.regionid = data.regionid;
                    window.macroregionid = data.macroregionid;
                    window.tenantid = data.tenantid;
                    window.theme = data.theme;
                    loadData();
                });
        }

    }, [sort, view, currentPage, state.searchState, location?.search]);


    //?Bedrooms=1&MacroPropertyTypeUIDs=3246


    useEffect(() => {
        const loadInitialData = async () => {
            // Optional loading indicator setup
            const lookupList = await fetchLookupTranslations();
            const siteSettings = await fetchSiteSettings();

            setLookupList(lookupList);
            setSiteSettings(siteSettings);

            await loadData(); // Ensures `loadData` is invoked with the latest dependencies
            setLoading(false);
        };

        loadInitialData();

    }, [loadData]);  // Notice `loadData` in the dependency array

    useEffect(() => {
        const searchState = { ...state.searchState };
        const searchParams = new URLSearchParams(location?.search || '');
      
        Object.keys(searchState).forEach((key) => {
            const value = searchParams.get(key);
            if (value !== null) {
                searchState[key] = value;
            } else {
                searchParams.append(key, searchState[key]);
            }
        });

        dispatch({ type: SET_SEARCH_STATE, payload: searchState });
    }, []);



    // Map Related Section 
    //TODO change result.content.ListingCurrency for a currency symbol
    const pins: Pin[] = listingsMap?.value.map((result: ListingDetailContent, index: number) => ({
        latitude: result.content.Location.coordinates[1], // Latitude is at index 1
        longitude: result.content.Location.coordinates[0], // Longitude is at index 0
        value: result.content.ListingPrice === null || result.content.ListingPrice === 0 ? '' :
            `${result.content.ListingCurrency === siteSettings?.CurrencyCode ? "" : getAlternativeCurrencyCode(result.content.ListingCurrency, siteSettings)} ${formatShortPrice(result.content.ListingPrice.toString())}`, // TODO: fix the hardcode currency and the short version of the values
        listingId: result.content.MLSID,
        transactionType: result.content.TransactionTypeUID,
    }));


    return (
        <div>
            <MetaTags metaTags={{
                title: `${siteSettings?.CountryName ?? ""} ${state.searchState.ListingClass === PROPERTY_TYPES.COMMERCIAL.toString() ? t('Search_Commercial') :
                    state.searchState.ListingClass === PROPERTY_TYPES.RESIDENTIAL.toString() ? t('Search_Residential') :
                        state.searchState.ListingClass === PROPERTY_TYPES.NOTSELECTED.toString() ? "" :
                            ""} ${t('SEO_RealEstate')} ${t('ListingList_AllPropertyTypes')} ${state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORRENT.toString() ? getLookupTranslationById(TRANSACTION_TYPES.FORRENT.toString(), lookupList) :
                                state.searchState.TransactionTypeUID === TRANSACTION_TYPES.FORSALE.toString() ? getLookupTranslationById(TRANSACTION_TYPES.FORSALE.toString(), lookupList) :
                                    state.searchState.TransactionTypeUID === TRANSACTION_TYPES.NOTSELECTED.toString() ? "" :
                                        ""}`,
            }}
            />
            <Fab
                variant="extended"
                sx={{
                    position: 'fixed',
                    bottom: '1.5rem',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    whiteSpace: 'nowrap',
                }}
                onClick={() => setView(view === LISTING_SEARCH_VIEW.LIST ? LISTING_SEARCH_VIEW.MAP : LISTING_SEARCH_VIEW.LIST)}
            >
                {view === LISTING_SEARCH_VIEW.LIST ? (
                    <>
                        <MapIcon className="margin-right-8" />
                        {t('ListingList_HybridView')}
                    </>
                ) : (
                    <>
                        <FormatListBulletedIcon className="margin-right-8" />
                        {t('ListingList_GalleryView')}
                    </>
                )}
            </Fab>

            <div>
                {view === LISTING_SEARCH_VIEW.LIST ? (
                    loading ? (

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                            <CircularProgress sx={{ color: 'var(--primary-color)' }} />
                        </div>
                    ) : (
                        <Suspense fallback={<CircularProgress />}>

                            <Container className="full-width-container" sx={{
                                paddingTop: {
                                    xs: '2.5rem',
                                    md: '3rem'
                                },
                                paddingBottom: {
                                    xs: '2.5rem',
                                    md: '3rem'
                                },
                            }}>

                                <ListingSearchTopBar setSortOption={setSort} sortOption={sort} lookupList={lookupList} />

                                {Array.isArray(listings?.value) && listings.value.length === 0 && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            background: 'white',
                                            width: '100%',
                                            padding: '3rem',
                                        }}
                                    >
                                        <Typography className="h5 rtl-text-center" variant="h5">
                                            {t('ListingList_SorryNoListings')}
                                        </Typography>
                                    </div>
                                )}

                                {Array.isArray(listings?.value) && listings.value.length > 0 && (
                                    <ListProperties
                                        data={listings}
                                        LookupItem={lookupList}
                                        currency={siteSettings?.CurrencyCode || ''}
                                        gridSize={3}
                                        siteSettings={siteSettings}
                                    />
                                )}
                                {/* {Array.isArray(listings?.value) && listings.value.length === 0 && (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            background: 'white',
                                            width: '100%',
                                            padding: '3rem',
                                        }}
                                    >
                                        <Typography className="h5 rtl-text-center" variant="h5">
                                            {t('ListingList_SorryNoListings')}
                                        </Typography>
                                    </div>
                                )} */}

                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    sx={{ marginTop: '1.5rem' }}
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    {totalPages > 1 && (
                                        <Pagination
                                            count={totalPages}
                                            shape="rounded"
                                            onChange={handleChangePagination}
                                        />
                                    )}

                                    {totalrecords > 0 && (
                                        <Typography className="body1-medium" variant="body1">
                                            {formatNumberWithRegionSetting(
                                                totalrecords,
                                                siteSettings,
                                                true
                                            )}{' '}
                                            {t('ListingList_TotalMatches')}
                                        </Typography>
                                    )}
                                </Stack>
                            </Container>
                        </Suspense>
                    )
                ) : (
                    <Suspense fallback={<CircularProgress />}>
                        <Container className="mapbox-full-width">
                            <Grid
                                container
                                spacing={0}
                                sx={{ overflow: 'hidden', height: 'calc(100vh - 178px)' }}
                            >
                                {!isMobile && (

                                    <Grid item xs={6} md={6} className="scrollbars-hidden mapview-left-column" sx={{ overflow: 'auto', height: '100%' }} style={{ paddingTop: '3rem', paddingRight: '2rem', paddingBottom: '3rem', maxHeight: '100%' }}>

                                        <ListingSearchTopBar setSortOption={setSort} sortOption={sort} lookupList={lookupList} />

                                        {Array.isArray(listings?.value) && listings.value.length > 0 && (
                                            <ListProperties
                                                data={listings}
                                                LookupItem={lookupList}
                                                currency={siteSettings?.CurrencyCode || ''}
                                                showMapAndImage={false}
                                                gridSize={6}
                                                siteSettings={siteSettings}
                                            />
                                        )}
                                        {Array.isArray(listings?.value) && listings.value.length === 0 && (
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    background: 'white',
                                                    width: '100%',
                                                    padding: '3rem',
                                                }}
                                            >
                                                <Typography className="h5 rtl-text-center" variant="h5">
                                                    {t('ListingList_SorryNoListings')}
                                                </Typography>
                                            </div>
                                        )}

                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                            sx={{ marginTop: '1.5rem' }}
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="space-between"
                                            useFlexGap
                                            flexWrap="wrap"
                                        >
                                            {totalPages > 1 && (
                                                <Pagination
                                                    count={totalPages}
                                                    shape="rounded"
                                                    onChange={handleChangePagination}
                                                />
                                            )}
                                            {totalrecords > 0 && (
                                                <Typography className="body1-medium" variant="body1">
                                                    {formatNumberWithRegionSetting(
                                                        totalrecords,
                                                        siteSettings,
                                                        true
                                                    )}{' '}
                                                    {t('ListingList_TotalMatches')}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    xs={12}
                                    sm={isMobile ? 12 : 6}
                                    sx={{ paddingTop: '0px', paddingLeft: '0px' }}
                                >
                                    <Item className="gridItem">
                                        <MapboxMap
                                            longitude={siteSettings?.Longitude || 0}
                                            latitude={siteSettings?.Latitude || 0}
                                            zoom={siteSettings?.MacroRegionID === 55 ? 3 : 5}
                                            height={'calc(100vh - 178px)'}
                                            width={'100%'}
                                            pins={pins}
                                            lookupList={lookupList}
                                            currency={siteSettings?.CurrencyCode || ''}
                                            siteSettings={siteSettings}
                                            isListingSearch
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Container>
                    </Suspense>
                )}
            </div>
        </div>
    );
};

export default ListingsSearch;
