import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ListingCard from '../listing/ListingCard';
import LookupItem from '../../types/LookupItem';
import { fetchListingDataById, fetchListingDataV2 } from '../../services/ListingService';
import { useLocation } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import SearchResponse from '@/types/AgentSearchResponse';
import { formatShortPrice } from './helper/FormatNumberHelper';
import SiteSettings from '../../types/Settings/SiteSettings';
import { useSearch } from '../../reducer/searchreducer';
import { getRadius, listingSearch } from './helper/Filter';
import { SET_SEARCH_STATE } from '../../reducer/actionTypes';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { TRANSACTION_TYPES } from '../../constants/constants';

mapboxgl.accessToken = process.env.REACT_APP_API_MAPBOX_KEY as string;
interface Pin {
  latitude: number;
  longitude: number;
  value: string;
  listingId: string;
  transactionType: string;
}

interface MapProps {
  latitude: number;
  longitude: number;
  zoom: number;
  height: string;
  width: string;
  zoomControl?: boolean;
  scrollWheelZoom?: boolean;
  pins?: { latitude: number; longitude: number; value: string; listingId: string, transactionType: string }[];
  lookupList?: LookupItem[];
  currency?: string;
  siteSettings?: SiteSettings;
  isListingSearch?: boolean
}

const MapboxMap: React.FC<MapProps> = ({ latitude, longitude, zoom, height, width, zoomControl = true, scrollWheelZoom = true, pins, lookupList, currency, siteSettings, isListingSearch = false }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null!);
  const mapRef = useRef<mapboxgl.Map | undefined>();
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const [selectedPin, setSelectedPin] = useState<string | null>(null);
  const [popup, setPopup] = useState<mapboxgl.Popup | null>(null);
  const { state, dispatch } = useSearch();
  const centerRef = useRef({ lng: longitude, lat: latitude });
  const zoomRef = useRef(zoom);
  const initialZoomDoneRef = useRef(false);
  const previousPinsRef = useRef<string>(JSON.stringify(pins));

  const onZoomEnd = useRef(debounce(() => {
    handleZoomEnd();
  }, 100)).current;


  const handleZoomEnd = () => {
    if (isListingSearch === true && mapRef.current) {
      removePopups();

      initialZoomDoneRef.current = true;
      const center = mapRef.current.getCenter();
      const currentZoom = mapRef.current.getZoom();
      const dynamicRadius = getRadius(Math.floor(currentZoom));

      centerRef.current = center;
      zoomRef.current = currentZoom;

      dispatch({
        type: SET_SEARCH_STATE,
        payload: {
          ...state.searchState,
          DynamicRadius: dynamicRadius,
          CenterLng: center.lng,
          CenterLat: center.lat
        }
      });
    }


  };

  useEffect(() => {
    if (!mapContainerRef.current) return;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [centerRef.current.lng, centerRef.current.lat],
      zoom: zoomRef.current,
      scrollZoom: scrollWheelZoom,
    });
    mapRef.current = map;
    mapRef.current.addControl(new mapboxgl.NavigationControl({ showZoom: zoomControl, showCompass: zoomControl }), 'top-right');


    return () => map.remove();
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.on('zoomend', onZoomEnd);

    const currentPinsSerialized = JSON.stringify(pins);

    if (currentPinsSerialized !== previousPinsRef.current) {
      initialZoomDoneRef.current = false;
      removePopups();
      previousPinsRef.current = currentPinsSerialized;
    }

    const updateMarkerStyle = (
      el: HTMLDivElement,
      pinValue: HTMLDivElement,
      value: string,
      zoomLevel: number | undefined
    ) => {
      const isZoomedOut = zoomLevel && zoomLevel <= 10;
      const isEmptyValue = value === '';

      if (isEmptyValue || isZoomedOut) {
        el.style.width = '15px';
        el.style.height = '15px';
        el.style.borderRadius = '50%'; // Ensure it remains circular
        pinValue.style.display = 'none';
      } else {
        el.style.width = '65px';
        el.style.height = '28px';
        el.style.borderRadius = '28px'; // Adjust border radius for the larger pin design
        pinValue.style.display = 'block';
      }
    };

    const addUpdateMarkers = (newPins: Pin[]) => {
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];

      newPins.forEach(pin => {
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundColor = 'var(--map-sale-dot-color)';
        el.style.position = 'absolute';
        if (pin.transactionType == TRANSACTION_TYPES.FORRENT.toString()) {
          el.style.backgroundColor = 'var(--map-rent-dot)';
        } else {
          el.style.backgroundColor = 'var(--map-sale-dot)';
        }

        const pinValue = document.createElement('div');
        pinValue.textContent = pin.value;
        pinValue.style.color = 'white';
        pinValue.style.position = 'absolute';
        pinValue.style.top = '50%';
        pinValue.style.left = '50%';
        pinValue.style.transform = 'translate(-50%, -50%)';
        pinValue.style.display = 'none';
        el.appendChild(pinValue);

        el.addEventListener('click', () => handlePinClick(pin));

        if (mapRef.current) {
          const marker = new mapboxgl.Marker(el)
            .setLngLat([pin.longitude, pin.latitude])
            .addTo(mapRef.current);

          markersRef.current.push(marker);

          const zoomLevel = mapRef.current.getZoom();
          updateMarkerStyle(el, pinValue, pin.value, zoomLevel);
        }
      });

      if (newPins.length === 1 && !initialZoomDoneRef.current && mapRef.current) {
        const singlePin = newPins[0];
        mapRef.current.flyTo({
          center: [singlePin.longitude, singlePin.latitude],
          zoom: 15,
          essential: true,
        });
        initialZoomDoneRef.current = true;
      }
    };

    if (!isListingSearch) {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = `url('/images/${window.theme !== undefined ? window.theme : ''}/pointer.svg')`;
      el.style.width = '48px';
      el.style.height = '48px';
      el.style.backgroundSize = '100%';
      if (mapRef.current) {
        new mapboxgl.Marker(el).setLngLat([longitude, latitude]).addTo(mapRef.current);
      }
    } else {
      if (pins) {
        addUpdateMarkers(pins);
      }
    }

    mapRef.current.on('zoom', () => {
      const zoomLevel = mapRef.current?.getZoom();
      markersRef.current.forEach(marker => {
        const el = marker.getElement() as HTMLDivElement;
        const pinValue = el.querySelector('div') as HTMLDivElement;
        const pin = pins?.find(p => p.longitude === marker.getLngLat().lng && p.latitude === marker.getLngLat().lat);
        if (pinValue && pin) {
          updateMarkerStyle(el, pinValue, pin.value, zoomLevel);
        }
      });
    });
  }, [latitude, longitude, JSON.stringify(pins), zoom, mapRef, removePopups, onZoomEnd, isListingSearch]);


  const handlePinClick = async (pin: { latitude: number; longitude: number; listingId: string }) => {
    setSelectedPin(pin.listingId);
    const response = await fetchListingDataById(pin.listingId);
    const data = response?.value[0];
    if (data && mapRef.current) {
      let popupNode = document.createElement('div');
      popupNode.className = 'popup-content';
      const root = createRoot(popupNode);
      root.render(<ListingCard listingDetail={data.content} lookupList={lookupList ?? []} currency={currency ?? ""} showMapAndImage={false} siteSettings={siteSettings} />);
      const newPopup = new mapboxgl.Popup()
        .setLngLat([pin.longitude, pin.latitude])
        .setDOMContent(popupNode)
        .addTo(mapRef.current);
      setPopup(newPopup);
    }
  };

  function removePopups() {
    document.querySelectorAll('.mapboxgl-popup').forEach(popupElement => {
      popupElement.remove();
    });
  }

  return (
    <div ref={mapContainerRef} style={{ width: width, height: height }} />
  );
};

export default MapboxMap;