import React, { useState, useEffect } from 'react';
import { Avatar, BottomNavigation, Button, Link, Hidden, Stack, Paper, Typography, Skeleton } from '@mui/material';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ContactMePopup from '../utils/ContactMePopup';
import ContactMobileDrawer from '../utils/ContactMobileDrawer';
import { getProfileImagePath } from '../utils/helper/ImageHelper';
import { getFirstNCharacters } from '../utils/helper/DataFormatHelper';
import { PAGE_TYPE } from '../../constants/constants';
import Agent from '../../types/Profile/AgentFull';
import { ProfileSocialChatIntegration } from '../profile/ProfileComponentsIndex';
import ListingDetail from '../../types/Listing/ListingDetail';


interface Props {
    agent?: Agent;
    property?: ListingDetail;
}

const ListingNavigationAndCta: React.FC<Props> = ({ agent, property }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const languageCode = i18n.language;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    let agentShortLink = `/${PAGE_TYPE.AGENT}/` + agent?.AgentId;
    if (agent && agent?.AgentShortLinks !== null
        && agent?.AgentShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && agent?.AgentShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        agentShortLink = `/${PAGE_TYPE.AGENT}/` + agent.AgentShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    let officeShortLink = `/${PAGE_TYPE.OFFICE}/` + agent?.OfficeId;
    if (agent && agent?.OfficeShortLinks !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && agent?.OfficeShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        officeShortLink = `/${PAGE_TYPE.OFFICE}/` + agent.OfficeShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;


    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const MobileSkeleton = () => (
        <div className="full-width-container" style={{ paddingLeft: isXs ? '1rem' : undefined, paddingRight: isXs ? '1rem' : undefined, backgroundColor: 'white', position: 'sticky', bottom: '0', right: '0', borderTop: '1px solid rgba(0, 0, 0, 0.12)', zIndex: '2' }}>
            <Paper sx={{ borderRadius: '0 !important', paddingTop: '1rem', paddingBottom: '1rem', boxShadow: 'none!important' }}>
                <Stack spacing={2} direction="row" alignItems="center" useFlexGap justifyContent="space-between">
                    <Stack spacing={2} direction="row" alignItems="center" useFlexGap>
                        <Skeleton variant="circular" width={52} height={52} />
                        <Stack spacing={0.5} direction="column">
                            <Skeleton variant="text" width={100} height={28} />
                            <Skeleton variant="text" width={150} height={20} />
                        </Stack>
                    </Stack>
                    <Skeleton variant="text" width={150} height={30} />
                </Stack>
            </Paper>
        </div>
    );

    const DesktopSkeleton = () => (
        <div className="full-width-container" style={{ paddingLeft: isXs ? '1rem' : undefined, paddingRight: isXs ? '1rem' : undefined, backgroundColor: 'white', position: 'sticky', bottom: '0', right: '0', borderTop: '1px solid rgba(0, 0, 0, 0.12)', zIndex: '2' }}>
            <Paper sx={{ borderRadius: '0!important', paddingTop: '1.5rem', paddingBottom: '1.5rem', boxShadow: 'none!important' }}>
                <BottomNavigation>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" width="100%" useFlexGap>
                        <Stack spacing={2} direction="row" alignItems="center" useFlexGap>
                            <Skeleton variant="circular" width={52} height={52} />
                            <Stack spacing={0.5} direction="column">
                                <Skeleton variant="text" width={100} height={28} />
                                <Skeleton variant="text" width={150} height={20} />
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2} useFlexGap alignItems="center">
                            <Skeleton variant="circular" width={24} height={24} />
                            <Skeleton variant="circular" width={24} height={24} />
                            <Skeleton variant="rectangular" width={150} height={52} />
                        </Stack>
                    </Stack>
                </BottomNavigation>
            </Paper>
        </div>
    );

    if (isLoading) {
        return (
            <>
                <Hidden smUp>
                    <MobileSkeleton />
                </Hidden>
                <Hidden smDown>
                    <DesktopSkeleton />
                </Hidden>
            </>
        );
    }

    return (
        <>
            <div className="full-width-container" style={{ paddingLeft: isXs ? '1rem' : undefined, paddingRight: isXs ? '1rem' : undefined, backgroundColor: 'white', position: 'sticky', bottom: '0', right: '0', borderTop: '1px solid rgba(0, 0, 0, 0.12)', zIndex: '9999' }} >

                {/* Previous / Next Listing Buttons - Hidden Until Phase 2 */}
                <Stack direction="row" justifyContent="space-between" pt={3} pb={3} sx={{ display: 'none' }}>

                    <Button role="button" type="button" aria-label={t('ListingFull_Next')} sx={{ color: 'var(--body-text-color) !important ' }} variant="text" startIcon={<KeyboardArrowLeftIcon sx={{ color: 'var(--body-text-color) !important ' }} />}>
                        {t('ListingFull_Previous')}
                    </Button>

                    <Button role="button" type="button" aria-label={t('ListingFull_Next')} sx={{ color: 'var(--body-text-color) !important ' }} variant="text" endIcon={<KeyboardArrowRightIcon sx={{ color: 'var(--body-text-color) !important ' }} />}>
                        {t('ListingFull_Next')}
                    </Button>

                </Stack>

                {/* Mobile Sticky Contact Agent CTA */}
                <Hidden mdUp>

                    <Paper className='contact-agent-footer-landscape' sx={{ borderRadius: '0 !important', paddingTop: '1rem', paddingBottom: '1rem', position: 'sticky', bottom: 0, boxShadow: 'none!important' }}>

                        <Stack spacing={2} direction="row" alignItems="center" useFlexGap justifyContent="space-between">

                            <Stack spacing={2} direction="row" alignItems="center" useFlexGap>

                                {/* <Avatar sx={{ width: 52, height: 52 }}
                                    title={agent?.AgentName}
                                    alt={agent?.AgentName}
                                    src={getProfileImagePath(agent?.RegionId, agent?.AgentPhotoId)} /> */}

                                <Stack spacing={.5} direction="column">

                                    {agent?.AgentName !== null && agent?.AgentName !== '' && (
                                        <Typography className="text-align-left body1-medium" variant="body1">
                                            <Link underline="hover" href={agentShortLink}>
                                                {/* {getFirstNCharacters(agent?.AgentName, 10)} */}
                                                {agent?.AgentName}
                                            </Link>
                                        </Typography>
                                    )}

                                    <Typography className="text-align-left body2 secondary-text" variant="body2">
                                        <Link className="text-align-left secondary-text" title={agent?.OfficeName} aria-label={agent?.OfficeName} underline="hover" href={officeShortLink}>
                                            {agent?.OfficeName}
                                        </Link>
                                    </Typography>

                                </Stack>

                            </Stack>

                            {/* Global Contact Me Mobile Drawer (component) */}
                            {/*<ContactMobileDrawer data={{ agentdata: agent, open: openDrawer, toggleDrawer: toggleDrawer }} />*/}

                            {/* Global Contact Me Popup (component) */}
                            <ContactMePopup data={{ agentdata: agent, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog, page: PAGE_TYPE.LISTING_DETAIL, property: property }} />

                            <Button style={{ whiteSpace: 'nowrap', backgroundColor: 'var(--primary-color) !important', padding: '0.75rem 1.5rem !important', color: 'white !important' }} className="primary-button" variant="contained" onClick={handleOpenDialog}>{t('ListingFull_ContactMe')}</Button>

                        </Stack>

                    </Paper>

                </Hidden>

                {/* Desktop Sticky Contact Agent CTA */}
                <Hidden mdDown>
                    <Paper sx={{ borderRadius: '0!important', position: 'sticky', bottom: 0, paddingTop: '1.8rem', paddingBottom: '1.8rem', boxShadow: 'none!important', }}>
                        <BottomNavigation>
                            <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" width="100%" useFlexGap>
                                <Stack spacing={2} direction="row" alignItems="center" useFlexGap>
                                    <Avatar sx={{ width: 52, height: 52 }} title={agent?.AgentName}
                                        alt={agent?.AgentName}
                                        src={getProfileImagePath(agent?.RegionId, agent?.AgentPhotoId)} />

                                    <Stack spacing={.5} direction="column">
                                        <Typography className="text-align-left h5" variant="h5">
                                            <Link className="text-align-left" underline="hover" href={agentShortLink}>
                                                {agent?.AgentName}
                                            </Link>
                                        </Typography>
                                        <Typography className="text-align-left body2 secondary-text" variant="body2">
                                            <Link className="text-align-left secondary-text" title={agent?.OfficeName} aria-label={agent?.OfficeName} underline="hover" href={officeShortLink}>
                                                {agent?.OfficeName}
                                            </Link>
                                        </Typography>
                                    </Stack>
                                </Stack>

                                {/* Global Contact Me Mobile Drawer (component) */}
                                {/*<ContactMobileDrawer data={{ agentdata: agent, open: openDrawer, toggleDrawer: toggleDrawer }} />*/}

                                {/* Global Contact Me Popup (component) */}
                                <ContactMePopup data={{ agentdata: agent, open: openDialog, handleOpenDialog: handleOpenDialog, handleCloseDialog: handleCloseDialog, page: PAGE_TYPE.LISTING_DETAIL, property: property }} />

                                <Stack direction="row" spacing={2} useFlexGap alignItems="center">
                                    <Hidden smDown>
                                        {/* Global Chat Integration Links (component) */}
                                        {agent && <ProfileSocialChatIntegration
                                            phone={agent?.AgentPhone}
                                            email={agent?.AgentEmail}
                                            whatsApp={agent?.WhatsApp}
                                            facebookMessengerID={agent?.FacebookMessengerID}
                                            viberID={agent?.ViberID}
                                            telegramID={agent?.TelegramID}
                                            lineChat={agent?.LINEChat}
                                            lineChatID={agent?.LineChatID}
                                            weChat={agent.WeChat}
                                            weChatID={agent.WeChatID}
                                            isMobile={isMobile}
                                        />}
                                    </Hidden>
                                    <Button style={{ whiteSpace: 'nowrap' }} className="primary-button" variant="contained" onClick={handleOpenDialog}>{t('ListingFull_ContactMe')}</Button>
                                </Stack>
                            </Stack>
                        </BottomNavigation>
                    </Paper>
                </Hidden>

            </div>
        </>
    );
};

export default ListingNavigationAndCta;
