import HitType from '../types/Hit'
const locationOrigin = window?.location?.origin ?? ''; // Fallback for environments where window might be undefined
const hitService = async (input: HitType): Promise<boolean> => {
  const apiHitEndpoint = `${locationOrigin}/hits/InsertHit`;
  const body = {
    TenantId: input.TenantId,
    RegionId: input.RegionId,
    HitName: input.HitName,
    HitType: input.HitType,
    LanguageCode: input.LanguageCode,
    AgentId: input.AgentId,
    OfficeId: input.OfficeId,
    TeamId: input.TeamId,
    UserId: input.UserId,
    ListingId: input.ListingId,
    SiteId: input.SiteId,
    OccurenceInUTC: input.OccurenceInUTC,
    IpAddress: input.IpAddress,
    SiteType: input.SiteType,
    CreationTime: input.CreationTime


  };

  const response = await fetch(apiHitEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

export { hitService };
