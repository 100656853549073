import React, { useEffect, useState } from 'react';
import { Button, IconButton, Hidden, Stack, Typography, Tooltip, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { SquareFoot as SquareFootIcon, Attachment as AttachmentIcon, Info as InfoIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon, Place as PlaceIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SearchResponse from '../../types/SearchResponse';
import SearchResult from '../../types/SearchResult';
import { formatCurrency } from '../utils/helper/FormatNumberHelper';
import { onClickFloorPlan } from '../utils/helper/FloorPlanHelper';
import { format } from 'date-fns';
import ListingDetail from '../../types/Listing/ListingDetail';
import ListingDescription from '@/types/Listing/ListingDescription';
import LookupItem from '../../types/LookupItem';
import { url } from 'inspector';
import SiteSettings from '../../types/Settings/SiteSettings';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { DESCRIPTION, LANGUAGE, TENANTS, TRANSACTION_TYPES } from '../../constants/constants';
import { useRegion } from '../../pages/RegionContext';


interface Props {
    ListingDetail: ListingDetail;
    LookupList: LookupItem[];
    siteSettings?: SiteSettings;
    scrollToElement: (id: string) => void;
}

const decodeHtmlEntities = (str: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
};

const ListingTitleAndDescription: React.FC<Props> = ({ ListingDetail, LookupList, siteSettings, scrollToElement }) => {

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const languageCode = i18n.language;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Hook Declaration
    const [showMore, setShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState<Date | null>(null);

    // useEffect Declaration
    useEffect(() => {
        // Simulate loading delay or data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const date = new Date();
        if (!isNaN(date.getTime())) {
            setCurrentDate(date);
        } else {
            setCurrentDate(null);
        }
    }, []);

    // event handlers declaration
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleFloorPlanView = (url: string | undefined): void => {
        window.open(`https://${url}`, '_blank');
    };

    // Listing Description Data Handle
    const siteDefaultLanguage = siteSettings?.RegionSupportedLanguages.find(
        lang => lang.IsDefault.trim() === DESCRIPTION.ISDEFAULT.toString()
    );
    const siteDefaultLanguageCode = siteDefaultLanguage?.FullLanguageCode.trim() ?? LANGUAGE.DEFAULT;

    const filterDescriptionByUIDAndLanguage = (
        data: ListingDescription[],
        descriptionTypeUID: string
    ): string => {

        if (data == null || data.length <= 0)
            return "";

        let currentLanguageDescrption = data.filter(
            (item) =>
                item.DescriptionTypeUID == descriptionTypeUID &&
                item.LanguageCode === i18n.language
        );

        if (currentLanguageDescrption !== null && currentLanguageDescrption.length > 0)
            return currentLanguageDescrption[0].Description;

        let siteDefaultLanguageDescription = data.filter(
            (item) =>
                item.DescriptionTypeUID == descriptionTypeUID &&
                item.LanguageCode === siteDefaultLanguageCode
        );

        if (siteDefaultLanguageDescription !== null && siteDefaultLanguageDescription.length > 0)
            return siteDefaultLanguageDescription[0].Description;

        return "";
    };

    const listingDescriptionWithoutFormat = filterDescriptionByUIDAndLanguage(ListingDetail.ListingDescriptions, DESCRIPTION.ID.toString());
    const listingTitle = filterDescriptionByUIDAndLanguage(ListingDetail.ListingDescriptions, DESCRIPTION.TITLE.toString());
    const communityDescriptionWithoutFormat = filterDescriptionByUIDAndLanguage(ListingDetail.ListingDescriptions, DESCRIPTION.COMMUNITY.toString());

    const listingDescription = decodeHtmlEntities(listingDescriptionWithoutFormat);
    const communityDescription = decodeHtmlEntities(communityDescriptionWithoutFormat);

    let listingFullAddress = ListingDetail.FullAddress;
    let listingTitleAddress = ListingDetail.TitleAddress;
    if (ListingDetail.GeoDatas !== null
        && ListingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null
        && ListingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0) {
        listingFullAddress = ListingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].FullAddress;
        listingTitleAddress = ListingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].TitleAddress;
    }


    const getMLSID = (data: ListingDetail): string => {
        if (data.TenantId === TENANTS.REMAX) {
            return `${ListingDetail.AgentId}-${ListingDetail.ListingId}`
        } else {
            return data.MLSID;
        }

    };

    if (isLoading) {
        return (
            <Stack direction="column" spacing={2}>
                <Skeleton variant="text" height={75} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" width={100} height={30} />
                <Skeleton variant="text" height={50} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
            </Stack>
        );
    }

    return (
        <div itemScope itemType="http://schema.org/Product">

            <Stack direction="column" spacing={4}>

                <Stack direction="column" spacing={2}>

                    {/* H1 Title */}
                    {/* To Do - a. Global Site has different Formatting, b. Site Setting includeMLSID show MLSID at end */}
                    <Typography id="h1-title" className={`${isMobile ? "h4" : "h3"}`} variant={isMobile ? "h4" : "h1"}>
                        {ListingDetail.PropertyTypeUID !== null && getLookupTranslationById(ListingDetail.PropertyTypeUID.toString(), LookupList)}
                        {ListingDetail.TransactionTypeUID !== null && ListingDetail.PropertyCategoryUID !== "2612" && (" - " + getLookupTranslationById(ListingDetail.TransactionTypeUID.toString(), LookupList))}
                        {listingTitleAddress !== undefined && listingTitleAddress !== null && (" - " + listingTitleAddress)}
                        {/* CommercialResidential Type Translation (Only Global, Phase II) */}
                        {/* PropertyType (eg, Condo/Apartment) with Translation */}
                        {/* TransactionType (eg, For Sale, for Rent) with Translations */}
                        {/* RegionalFormattedAddressSingleLine */}
                        {/* Country Name (Only Global, Phase II) */}
                        {/* MLSID (It is siesetting. Remax Israel shows but Remax Brazil does not) */}
                    </Typography>

                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" useFlexGap flexWrap="wrap">

                        {/* Listing Price, Price Type/Rental Price Granularity, Currency Exchange Link, and MLS ID */}
                        {/* To Do: Price will be spearate re-usable component in Listing List Page */}
                        <Stack direction="column" spacing={1} alignItems="flex-start">

                            {/* Listing Price, Price Type/Rental Price Granularity */}
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography id="listing-price" variant='h4' className="h4 brand-secondary" sx={{ color: 'var(--secondary-color) !important;' }}>
                                    {ListingDetail.ListingPrice === 0 ? t("ListingList_UpOnRequest") : <span dangerouslySetInnerHTML={{ __html: formatCurrency(ListingDetail.ListingPrice, ListingDetail.ListingCurrency, siteSettings) }} />}
                                    {' '}
                                    {ListingDetail.ListingPrice !== 0 && ListingDetail.RentalPriceGranularityUID !== null && ListingDetail.TransactionTypeUID == TRANSACTION_TYPES.FORRENT.toString()
                                        ? (getLookupTranslationById(ListingDetail.RentalPriceGranularityUID.toString(), LookupList)) : ''}
                                </Typography>

                            </Stack>

                            {/* To Do - Currency Exchange Link Hidden Until Phase 2 */}
                            <Typography sx={{ display: 'none' }} id="currency-exchange-link" className="body2 secondary-text" variant="body2">
                                {/* Currency Exhange Link */}
                            </Typography>

                        </Stack>

                        {/* Listing MLSID, To Do: Label Translation */}
                        <Typography id="mls-id" className="body2 secondary-text" variant="body2">
                            ID: {getMLSID(ListingDetail)}
                        </Typography>

                    </Stack>

                    {/* Address and Date Available */}
                    {listingFullAddress || ListingDetail.AvailabilityDate ? (

                        <Stack direction="column" spacing={1}>

                            {listingFullAddress && listingFullAddress !== null && (

                                //Address
                                <Stack id="listing-address" direction={{ xs: 'row-reverse', sm: 'row-reverse', md: 'row' }} spacing={1} useFlexGap alignItems="normal">
                                    <PlaceIcon className="brand-primary" onClick={() => scrollToElement('mapDiv')} style={{ cursor: 'pointer' }} />
                                    <Typography className="body1" variant="body1" onClick={() => scrollToElement('mapDiv')} style={{ color: 'var(--primary-color) !important', cursor: 'pointer' }}>
                                        {listingFullAddress}
                                    </Typography>
                                </Stack>

                            )}

                            {/* To Do - Date Available Needs Site Setting for Date/Time Format */}
                            {ListingDetail.AvailabilityDate && (
                                <Typography id="date-available" className="body1" variant="body1">
                                    {t("Listing_DateAvailableForSale")}: {format(new Date(ListingDetail.AvailabilityDate), 'dd/MM/yy')}
                                </Typography>
                            )}

                        </Stack>

                    ) : null}

                </Stack>

                {/* Listing Title, Description, Show More Buttons, and Toolbar */}
                <Stack id="listing-title-description" direction="column" spacing={{ xs: 2, md: 3 }}>

                    {/* Listing Title*/}
                    {listingTitle !== "" && (
                        <Typography
                            id="ilist-listing-title"
                            className={isMobile ? "body1-medium" : "h4"}
                            variant={isMobile ? "body1" : "h4"}
                            dangerouslySetInnerHTML={{ __html: listingTitle }}
                        />
                    )}

                    {/* Listing Description */}
                    {listingDescription !== "" && (
                        <>
                            <Typography
                                id="listing-description"
                                className="body1"
                                variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: showMore || listingDescription.length <= DESCRIPTION.SHOWMORE
                                        ? listingDescription  // Show full description or short description if it's within the SHOWMORE limit
                                        : listingDescription.slice(0, DESCRIPTION.SHOWMORE)  // Show truncated description
                                }}
                            />

                            {/* Bottom Bar - Show More Buttons, and Toolbar - should be hidden if no data */}
                            {(ListingDetail.HasPublicDocument !== null || ListingDetail.AlternateURL !== null || ListingDetail.FloorPlanURL !== null)
                                && listingDescription.length > DESCRIPTION.SHOWMORE && (
                                    <Stack
                                        id="description-bottom-bar"
                                        direction={{ xs: 'column', md: 'row' }}
                                        spacing={2}
                                        useFlexGap
                                        flexWrap="wrap"
                                        justifyContent="space-between"
                                        alignItems={{ xs: 'flex-start', md: 'center' }}
                                    >

                                        {/* Show More Description Button */}
                                        {!showMore && listingDescription.length > DESCRIPTION.SHOWMORE && (
                                            <Button
                                                onClick={toggleShowMore}
                                                fullWidth={false}
                                                sx={{
                                                    backgroundColor: 'white !important',
                                                    width: "auto",
                                                    maxWidth: "fit-content",
                                                    justifyContent: "left",
                                                    color: "var(--primary-color) !important",
                                                    border: '1px solid var(--primary-color) !important',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                variant="outlined"
                                                endIcon={<KeyboardArrowRightIcon className="flip-text" sx={{ color: "var(--primary-color) !important " }} />}
                                            >
                                                {t('ListingFull_SeeMoreDescription')}
                                            </Button>
                                        )}

                                        {/* Collapse Description Button */}
                                        {showMore && listingDescription.length > DESCRIPTION.SHOWMORE && (
                                            <Button
                                                onClick={toggleShowMore}
                                                sx={{
                                                    backgroundColor: 'white !important',
                                                    width: "auto",
                                                    maxWidth: "fit-content",
                                                    justifyContent: "left",
                                                    color: "var(--primary-color) !important",
                                                    border: '1px solid var(--primary-color) !important',
                                                    '&:hover': {
                                                        backgroundColor: 'var(--button-active-color) !important',
                                                    }
                                                }}
                                                variant="outlined"
                                                endIcon={<KeyboardArrowRightIcon sx={{ color: "var(--primary-color) !important " }} />}
                                            >
                                                {t('ListingFull_CollapseDescription')}
                                            </Button>
                                        )}

                                        {/* ----- Toolbar Container ----- */}
                                        <Stack
                                            id="description-toolbar"
                                            direction={{ xs: 'column', md: 'row' }}
                                            spacing={2}
                                            useFlexGap
                                            flexWrap="wrap"
                                            justifyContent="space-between"
                                            alignItems={{ xs: 'flex-start', md: 'center' }}
                                        >

                                            {/* Toolbar - should be hidden if no data */}
                                            {ListingDetail.AlternateURL !== null || ListingDetail.FloorPlanURL !== null ? (
                                                <Stack
                                                    id="toolbar"
                                                    direction={{ xs: 'column', md: 'row' }}
                                                    spacing={1}
                                                    useFlexGap
                                                    flexWrap="wrap"
                                                    alignItems="center"
                                                >
                                                    {/* Mobile Toolbar */}
                                                    <Hidden mdUp>

                                                        {/* More Info */}
                                                        {ListingDetail.AlternateURL !== null && (
                                                            <Button aria-label={t("ListingFull_MoreInfo")} href={ListingDetail.AlternateURL} target='_blank' variant="text" startIcon={<InfoIcon sx={{ color: "var(--body-text-color) !important" }} />}>
                                                                {t("ListingFull_MoreInfo")}
                                                            </Button>
                                                        )}

                                                        {/* Floor Plan */}
                                                        {ListingDetail.FloorPlanURL !== null && (
                                                            <Button onClick={onClickFloorPlan(`http://${siteSettings.RegionDomain}${ListingDetail.FloorPlanURL}`)} aria-label={t("ListingFull_FloorPlanButton")} variant="text" startIcon={<AttachmentIcon sx={{ color: "var(--body-text-color) !important" }} />}>
                                                                {t("ListingFull_FloorPlanButton")}
                                                            </Button>
                                                        )}

                                                    </Hidden>

                                                    {/* Desktop Toolbar */}
                                                    <Hidden mdDown>

                                                        {/* More Info */}
                                                        {ListingDetail.AlternateURL !== null && (
                                                            <Tooltip title={t("ListingFull_MoreInfo")} placement="bottom">
                                                                <IconButton href={ListingDetail.AlternateURL} target='_blank' aria-label={t("ListingFull_MoreInfo")}>
                                                                    <InfoIcon sx={{ color: "var(--body-text-color) !important" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}

                                                        {/* Floor Plan */}
                                                        {ListingDetail.FloorPlanURL !== null && (
                                                            <div>
                                                                <Tooltip title={t("ListingFull_FloorPlanButton")} placement="bottom" >
                                                                    <IconButton aria-label={t("ListingFull_FloorPlanButton")} href={'http://' + siteSettings.RegionDomain + ListingDetail.FloorPlanURL} target="_blank">
                                                                        <SquareFootIcon sx={{ color: "var(--body-text-color) !important" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        )}

                                                    </Hidden>

                                                </Stack>

                                            ) : null}


                                        </Stack>

                                    </Stack>
                                )}
                        </>
                    )}



                </Stack>

                {/* Community Description */}
                {communityDescription !== "" && (
                    <Stack direction="column" spacing={2}>
                        <Typography className="body1-medium" variant="body1">
                            {t('ListingFull_DescriptionCommunity')}
                        </Typography>
                        <Typography className="body1 community-description-text" variant="body1" dangerouslySetInnerHTML={{ __html: communityDescription }} >
                        </Typography>
                    </Stack>
                )}

            </Stack>

        </div >

    );
};

export default ListingTitleAndDescription;