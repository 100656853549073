import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, Container, Card, CardContent, Typography, Pagination, Paper, Tabs, Tab, MenuItem, FormControl, InputLabel, Stack, TextField, CircularProgress, SelectChangeEvent, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../components/utils/LoadingContext';
import { DESCRIPTION, PAGE_TYPE, TENANTS } from '../../constants/constants';
import { formatNumberWithRegionSetting } from '../../components/utils/helper/FormatNumberHelper';
import { getLookupTranslationById, getLookupTranslationByIds } from '../../components/utils/helper/LookupHelper';
import { fetchLookupTranslations, featchCountryList } from '../../services/TranslationService';
import { fetchSiteSettings } from '../../services/SiteSettingService';
import { fetchAgentSpokenLanguagesByRegionId } from '../../services/ProfileService';
import { ProfileSearchAgentList, ProfileSearchOfficeList } from '../../components/profilesearch/ProfileSearchComponentsIndex';
import { Country, LookupItem, SiteSettings, SearchFilterLookupItem } from '../../types/TypesIndex';
import ProfileSearchFreeTextSearch from '../../components/profilesearch/ProfileSearchFreeTextSearch';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`agents-offices-tabpanel-${index}`}
            aria-labelledby={`agents-offices-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `agents-offices-tab-${index}`,
        'aria-controls': `agents-offices-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
}));


const ProfileSearch: React.FC = () => {

    const theme = createTheme({
        direction: 'rtl', // Specify RTL direction
    });

    // Parameters Declaration
    const { t, i18n } = useTranslation();
    const { showLoading, hideLoading } = useLoading();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate loading delay or perform data fetching
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust delay as needed
        return () => clearTimeout(timer);
    }, []);

    // Hook Declaration 
    const [searchType, setSearchType] = useState<string>(PAGE_TYPE.AGENT);
    const [searchTypeValue, setSearchTypeValue] = useState(0); // 0: Agent, 1: Office, 2: Team
    const [countries, setCountries] = useState<Country[]>([]); // Country list base on Site Region ID
    const [countryName, setCountryName] = useState(''); // The selected country name via country select list or default country name via sitesetting
    const [countryId, setCountryId] = useState(0); // The selected country id via country select list or default country id via sitesetting
    const [agentName, setAgentName] = useState(''); // agent name text in input textbox
    const [debouncedAgentName, setDebouncedAgentName] = useState<string>(''); // search filter with agent name in state
    const [officeName, setOfficeName] = useState('');
    const [debouncedOfficeName, setDebouncedOfficeName] = useState<string>('');
    const [spokenLanguageUID, setSpokenLanguageUID] = useState(''); // selected spoken language UID in query string
    const [spokenLanguageList, setSpokenLanguageList] = useState<SearchFilterLookupItem[]>([]); // all agents' spoken languages in search filter
    const [pageNumber, setPageNumber] = useState(1);
    const [totalMatchCount, setTotalMatchCount] = useState(-1); // breadcrumb total match cont
    const [totalPageCount, setTotalPageCount] = useState(0); // pagination page count   
    const [lookupList, setLookupList] = useState<LookupItem[]>([]);
    const [siteSettings, setSiteSettings] = useState<SiteSettings>();
    // const [tabValue, setTabValue] = React.useState(0);  // List vs Map View Mode in Phase II
    // const [tabOfficeValue, setOfficeTabValue] = React.useState(0); // List vs Map View Mode in Phase II
    // const [location, setLocation] = React.useState(''); // not in use for now
    const [clearSearch, setClearSearch] = useState(false);
    // UseEffect Data Loading 
    useEffect(() => {

        const loadSiteDataSource = async () => {
            try {

                await fetchLookupTranslations().then((lookupList) => {
                    if (lookupList == null) {
                        console.log("lookupList loads nothing");
                    } else {

                        setLookupList(lookupList);

                        // let spokenLanguageCountryId = (countryId && typeof (countryId) !== 'undefined' && countryId > 0) ? countryId : window.macroregionid;

                        fetchAgentSpokenLanguagesByRegionId().then((spokenLanguagesResult) => {
                            if (spokenLanguagesResult && spokenLanguagesResult !== null && typeof (spokenLanguagesResult['@search.facets']?.['content/SpokenLanguages']) !== 'undefined') {
                                let slLst = getLookupTranslationByIds(spokenLanguagesResult['@search.facets']?.['content/SpokenLanguages']?.map(item => item.value), lookupList).sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue));
                                setSpokenLanguageList(slLst);
                            }
                        });
                    }

                });

                await fetchSiteSettings().then((siteSettings) => {
                    if (siteSettings == null) {
                        console.log("siteSettings loads nothing");
                    } else {
                        setSiteSettings(siteSettings);

                        featchCountryList(i18n.language).then((countries) => {
                            if (countries == null) {
                                console.log("country loads nothing");
                            } else {
                                setCountries(countries);
                                initalSearchParaemters(countries);
                            }
                        });
                    }
                });


            } catch (error) {
                console.error('Profile Search : failed to load site data source', error);
            }
        };

        loadSiteDataSource();

    }, [i18n.language, countryId]);

    useEffect(() => {
        showLoading();
        // Simulate fetching data
        setTimeout(() => {
            hideLoading();
        }, 200);
    }, []);


    // Inital Search Parameters  
    const initalSearchParaemters = (countryList: Country[]) => {

        const searchParams = new URLSearchParams(window.location.search);
        let initalSearchUrl = `${window.location.pathname}?`;

        let searchCountryId = searchParams.get('countryId');
        const searchCountry = (searchCountryId && searchCountryId !== 'undefined' && parseInt(searchCountryId) > 0) ?
            countryList.find(c => c.CountryID.toString() === searchCountryId) : countryList.find(c => c.CountryID.toString() === siteSettings?.CountryID.toString());
        if (searchCountry && searchCountry !== null && typeof (searchCountry) !== 'undefined') {
            initalSearchUrl += `&countryId=${searchCountry?.CountryID}&country=${searchCountry?.CountryName}`;
            setCountryId(searchCountry.CountryID);
            setCountryName(searchCountry?.CountryName);

            if (countryId === searchCountry.CountryID) {
                searchParams.forEach((value, key) => {
                    if (key === 'province' || key === 'city' || key === 'localzone' || key === 'streetName') {
                        initalSearchUrl += `&${key}=${value}`; // keep the location search filter
                    }
                });
            }
        }

        let searchSearchType = searchParams.get('searchType');
        if (searchSearchType !== null && typeof (searchSearchType) !== 'undefined') {
            if (searchSearchType === PAGE_TYPE.OFFICE) {
                setSearchType(PAGE_TYPE.OFFICE);
                setSearchTypeValue(1);
                initalSearchUrl = initalSearchUrl + `&searchType=${PAGE_TYPE.OFFICE}`;
            } else {
                setSearchType(PAGE_TYPE.AGENT);
                setSearchTypeValue(0);
                initalSearchUrl = initalSearchUrl + `&searchType=${PAGE_TYPE.AGENT}`;
            }
        } else {
            setSearchType(PAGE_TYPE.AGENT);
            setSearchTypeValue(0);
            initalSearchUrl = initalSearchUrl + `&searchType=${PAGE_TYPE.AGENT}`;
        }

        let searchName = searchParams.get('name');
        if (searchName !== null && typeof (searchName) !== 'undefined') {
            if (searchType === PAGE_TYPE.OFFICE) {
                setOfficeName(searchName);
            } else {
                setAgentName(searchName);
            }
            initalSearchUrl = initalSearchUrl + `&name=${searchName}`;
        }

        let searchSpokenLanguageUID = searchParams.get('spokenLanguageUID');
        if (searchSpokenLanguageUID !== null && typeof (searchSpokenLanguageUID) !== 'undefined') {
            setSpokenLanguageUID(searchSpokenLanguageUID);
            initalSearchUrl = initalSearchUrl + `&spokenLanguageUID=${searchSpokenLanguageUID}`;
            let searchSpokenLanguage = searchParams.get('spokenLanguage');
            if (searchSpokenLanguageUID !== null && typeof (searchSpokenLanguageUID) !== 'undefined') {
                initalSearchUrl = initalSearchUrl + `&spokenLanguage=${searchSpokenLanguage}`;
            }
        }

        initalSearchUrl = initalSearchUrl + `&page=1`;
        window.history.replaceState(null, '', initalSearchUrl);
    };

    const handleClearSearch = () => {
        setClearSearch(true);
        setTimeout(() => setClearSearch(false), 0);

    };

    // Events Handlers  
    // Start Search Event Search Type  
    const handleSearchTypeChange = (event: React.SyntheticEvent, selectedSearchTypeValue: number) => {
        /*
            Toggle the Search Type Tab Filter
            a. Update the URL 
            b. Tigger the search by using the newly selected Search Type with pre-selected country
            c. Clean up others search paraemters: location, name and spoken languages
        */
        event.preventDefault();
        setSearchTypeValue(selectedSearchTypeValue);

        let searchTypeUrl = `${window.location.pathname}?`;

        const searchParams = new URLSearchParams(window.location.search);


        let searchCountryId = searchParams.get('countryId');
        const searchCountry = (searchCountryId && searchCountryId !== 'undefined' && parseInt(searchCountryId) > 0) ?
            countries.find(c => c.CountryID.toString() === searchCountryId) : countries.find(c => c.CountryID.toString() === siteSettings?.CountryID.toString());
        if (searchCountry && searchCountry !== null && typeof (searchCountry) !== 'undefined') {
            searchTypeUrl += `countryId=${searchCountry?.CountryID}&country=${searchCountry?.CountryName}`;
            setCountryId(searchCountry.CountryID);
            setCountryName(searchCountry?.CountryName);
        }

        if (selectedSearchTypeValue === 0) {
            setSearchType(PAGE_TYPE.AGENT);
            searchTypeUrl += `&searchType=${PAGE_TYPE.AGENT}`;
        } else if (selectedSearchTypeValue === 1) {
            setSearchType(PAGE_TYPE.OFFICE);
            searchTypeUrl += `&searchType=${PAGE_TYPE.OFFICE}`;
        }

        searchTypeUrl += `&page=1`;
        window.history.replaceState(null, '', searchTypeUrl);

        handleClearFilterWithoutCountryAndSearchType();
    };
    // End Search Event Search Type

    // Start Search Event Country
    const handleCountryChange = (event: SelectChangeEvent<string>) => {
        /*
            a. Change Country Id and Country Name in State
            b. Keep the Search Type
            c. Clear all other search filters
        */
        const selectedCountryName = event.target.value;
        const selectedCountryID = countries.find(c => c.CountryName === selectedCountryName)?.CountryID;

        if (selectedCountryID !== null && typeof (selectedCountryID) !== 'undefined') {
            setCountryId(selectedCountryID);
            setCountryName(selectedCountryName);
        } else {
            return;
        }

        handleClearFilterWithoutCountryAndSearchType();

        let changedCountryUrl = `${window.location.pathname}?country=${selectedCountryName}&countryId=${selectedCountryID}&searchType=${searchType}`;
        window.history.replaceState(null, '', changedCountryUrl);
    };
    // End Search Event Country

    // Start Search Events Agent/Office Name 
    // Agent Name
    const handleAgentNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        setAgentName(event.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            if (agentName.length > 2 || agentName === "") {
                onPageChange(1);
                setDebouncedAgentName(agentName);
                handleUpdateQueryString("name", agentName);
            }

        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [agentName]);

    // Office Name
    const handleOfficeNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        event.stopPropagation();
        setOfficeName(event.target.value);
    };

    useEffect(() => {
        const handler = setTimeout(() => {
            if (officeName.length > 2 || officeName === "") {
                onPageChange(1);
                setDebouncedOfficeName(officeName);
                handleUpdateQueryString("name", officeName);
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [officeName]);
    // End Events for Search Filter Name 

    // Start Event via Spoken Language 
    const handleSpokenLanguageChange = (event: SelectChangeEvent<string>) => {
        event.stopPropagation();
        const spokenLanguageUID = event.target.value;
        if (spokenLanguageUID === "-1") {
            setSpokenLanguageUID('');
            handleUpdateQueryString("spokenLanguageUID", "");
            handleUpdateQueryString("spokenLanguage", "");
            onPageChange(1);
        } else {
            setSpokenLanguageUID(spokenLanguageUID);
            handleUpdateQueryString("spokenLanguageUID", spokenLanguageUID);
            handleUpdateQueryString("spokenLanguage", getLookupTranslationById(spokenLanguageUID, lookupList));
            onPageChange(1);
        }
    };
    // End Event via Spoken Language  

    // Start Event for Page
    const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        onPageChange(page);

        // Determine scroll position based on device type
        const isMobile = window.matchMedia('(max-width: 600px)').matches;
        const scrollPosition = isMobile ? 600 : 0;

        // Scroll to the specified position
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const onPageChange = (page: number) => {
        setPageNumber(page);
        handleUpdateQueryString("page", page.toString());
    };

    const handleTotalMatchCount = (matchCount: number) => {
        setTotalMatchCount(matchCount);
    }

    const handleTotalPageCount = (pageCount: number) => {
        setTotalPageCount(pageCount);
    };

    // End Event for Page

    // Start Events to clear filters    
    const handleClearFilterWithoutCountryAndSearchType = () => {
        // Clear Filters: Location, Name, Spoken Languages and Page        
        // setLocation('');

        setAgentName('');
        setDebouncedAgentName('');
        setOfficeName('');
        setDebouncedOfficeName('');
        setSpokenLanguageUID('');
        setPageNumber(1);
        handleClearSearch();
    }


    const handleClearAll = () => {
        handleClearFilterWithoutCountryAndSearchType();

        //setSearchType(PAGE_TYPE.AGENT);
        //setSearchTypeValue(0);

        const defaultCountryID = siteSettings?.CountryID;

        if (defaultCountryID && defaultCountryID > 0) {
            let defaultCountryName = countries.find(c => c.CountryID.toString() === defaultCountryID?.toString()) ?
                countries.find(c => c.CountryID.toString() === defaultCountryID?.toString())?.CountryName
                : siteSettings?.CountryName;

            setCountryId(defaultCountryID);
            if (defaultCountryName && defaultCountryName !== null)
                setCountryName(defaultCountryName);
        }

        handleGetDefaultQueryString(defaultCountryID);
    };
    // End Events to clear filters 

    const handleUpdateQueryString = (key: string, value: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(key);
        if (value !== '') {
            searchParams.set(key, value);
        }
        const searchWithNameUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', searchWithNameUrl);
    }

    const handleGetDefaultQueryString = (defaultCountryID: number | undefined) => {

        if (typeof (defaultCountryID) === 'undefined' || defaultCountryID <= 0)
            defaultCountryID = siteSettings?.CountryID;


        const urlParams = new URLSearchParams(window.location.search);
        const searchType = urlParams.get('searchType');

        const searchTypeDefault = searchType === PAGE_TYPE.OFFICE ? PAGE_TYPE.OFFICE : PAGE_TYPE.AGENT;

        let changedCountryUrl = `${window.location.pathname}?searchType=${searchTypeDefault}&countryId=${defaultCountryID}&page=1`;
        window.history.replaceState(null, '', changedCountryUrl);
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{ color: 'var(--primary-color) !important' }} />
            </div>
        );
    }

    return (
        <>
            {/* Meta Data */}
            <Helmet>
                <title>{t('WebSite_AgentsAndOffices')}</title>
                <meta name="title" content={t('WebSite_AgentsAndOffices')} />
                <meta name="description" content={t('WebSite_AgentsAndOffices')} />
            </Helmet>

            <div>

                {/* Container */}
                <Container className="text-align-left" maxWidth="xl" sx={{
                    paddingTop: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                    paddingBottom: {
                        xs: '2.5rem',
                        md: '3rem'
                    },
                }}>

                    {/* Two column grid */}
                    <Grid container spacing={{ xs: 5, sm: 8 }}>

                        {/* Left column - filters */}
                        <Grid item xs={12} sm={4}>

                            <Box sx={{ position: 'sticky', top: '146px' }}>

                                <Item style={{ padding: '1rem', borderRadius: '1rem', boxShadow: 'none !important' }}>

                                    <Card className="gridItem customBox" sx={{ boxShadow: 'none !important' }}>

                                        <CardContent sx={{ boxShadow: 'none !important' }}>

                                            {/* Search for Agents & Offices */}
                                            <Typography className="h6" variant="h6" sx={{ marginBottom: '1.5rem!important', textAlign: 'center', fontFamily: 'var(--font-family) !important', fontWeight: '500 !important' }}>
                                                {t("ProfileSearch_SearchForAgentsAndOffices")}
                                            </Typography>

                                            {/* Agent Office Toggle Tabs */}
                                            <Box sx={{ width: '100%', justifyContent: 'center' }}>
                                                <Tabs className="pills-tab-container" variant="fullWidth" value={searchTypeValue} onChange={handleSearchTypeChange} aria-label={t('WebSite_AgentsAndOffices')}>
                                                    <Tab className="pills-tab" label={t("ProfileSearch_Agent")} />
                                                    <Tab className="pills-tab" label={t("ProfileSearch_Offices")} />
                                                </Tabs>
                                            </Box>

                                            {/* Agents tab */}
                                            <CustomTabPanel value={searchTypeValue} index={0}>

                                                <Stack spacing={2} mt={3}>

                                                    {/* Country */}
                                                    <FormControl fullWidth>
                                                        <InputLabel id="country" style={{ fontFamily: 'var(--font-family)' }}>{t('ProfileSearch_Country')}</InputLabel>
                                                        <Select
                                                            labelId="country"
                                                            id="country"
                                                            value={countryName}
                                                            label={t('ProfileSearch_Country')}
                                                            onChange={handleCountryChange}
                                                        >
                                                            {countries && countries !== null && countries.map((country) => (
                                                                <MenuItem key={country.CountryID} value={country.CountryName}>{country.CountryName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {/* Location Free Text Search (component) */}
                                                    <ProfileSearchFreeTextSearch page={PAGE_TYPE.AGENT} clearSearch={clearSearch} countryId={countryId} />

                                                    {/* Agent Name */}
                                                    <FormControl>
                                                        <TextField
                                                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                            id="agent-name"
                                                            value={agentName}
                                                            label={t('ProfileSearch_AgentName')}
                                                            aria-label={t('ProfileSearch_AgentName')}
                                                            type="search"
                                                            onChange={handleAgentNameChange} />
                                                    </FormControl>

                                                    {/* Agent Spoken Languages */}
                                                    <FormControl fullWidth>
                                                        <InputLabel id="agent-spoken-languages" style={{ fontFamily: 'var(--font-family)' }}>{t('ProfileSearch_ChooseALanguage')}</InputLabel>
                                                        <Select
                                                            labelId="agent-spoken-languages"
                                                            id="agent-spoken-language-list"
                                                            label={t('ProfileSearch_ChooseALanguage')}
                                                            value={spokenLanguageUID}
                                                            onChange={handleSpokenLanguageChange}
                                                        >
                                                            <MenuItem value="-1">{t('ProfileSearch_ChooseALanguage')}</MenuItem>
                                                            {spokenLanguageList && spokenLanguageList.length > 0 && spokenLanguageList.map((language) => (
                                                                <MenuItem key={language.UID} value={language.UID}>{language.DisplayValue}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                </Stack>

                                                {/* Clear all button */}
                                                <Typography className="secondary-medium" variant="body1" sx={{ color: 'var(--secondary-color) !important', textAlign: 'center', marginTop: '1.5rem', cursor: 'pointer' }}
                                                    onClick={handleClearAll} role="button" aria-label={t('ProfileSearch_Reset')}>
                                                    {t('ProfileSearch_Reset')}
                                                </Typography>

                                            </CustomTabPanel>

                                            {/* Offices tab */}
                                            <CustomTabPanel value={searchTypeValue} index={1}>

                                                <Stack spacing={2} mt={3}>

                                                    {/* Country */}
                                                    <FormControl fullWidth>
                                                        <InputLabel id="country" style={{ fontFamily: 'var(--font-family)' }}>{t('ProfileSearch_Country')}</InputLabel>
                                                        <Select
                                                            labelId="country"
                                                            id="country"
                                                            value={countryName}
                                                            label={t('ProfileSearch_Country')}
                                                            onChange={handleCountryChange}
                                                        >
                                                            {countries && countries !== null && countries.map((c) => (
                                                                <MenuItem key={c.CountryID} value={c.CountryName} data-id={c.CountryID} data-value={c.CountryName}>{c.CountryName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    {/* Location Free Text Search (component) */}
                                                    <ProfileSearchFreeTextSearch page={PAGE_TYPE.OFFICE} clearSearch={clearSearch} countryId={countryId} />

                                                    {/* Office Name */}
                                                    <FormControl>
                                                        <TextField
                                                            InputLabelProps={{ style: { fontFamily: 'var(--font-family)' } }}
                                                            id="office-name"
                                                            value={officeName}
                                                            label={t('ProfileSearch_OfficeName')}
                                                            aria-label={t('ProfileSearch_OfficeName')}
                                                            type="search"
                                                            onChange={handleOfficeNameChange} />
                                                    </FormControl>

                                                    {/* Office Spoken Languages */}
                                                    <FormControl fullWidth>
                                                        <InputLabel id="office-spoken-languages" style={{ fontFamily: 'var(--font-family)' }}>{t('ProfileSearch_ChooseALanguage')}</InputLabel>
                                                        <Select
                                                            labelId="office-spoken-languages"
                                                            id="office-spoken-languages-list"
                                                            label={t('ProfileSearch_ChooseALanguage')}
                                                            value={spokenLanguageUID}
                                                            onChange={handleSpokenLanguageChange}
                                                        >
                                                            <MenuItem value="-1">{t('ProfileSearch_ChooseALanguage')}</MenuItem>
                                                            {spokenLanguageList && spokenLanguageList.length > 0 && spokenLanguageList.map((language) => (
                                                                <MenuItem key={language.UID} value={language.UID}>{language.DisplayValue}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                </Stack>

                                                {/* Clear all button */}
                                                <Typography className="secondary-medium" variant="body1" sx={{ color: 'var(--secondary-color) !important', textAlign: 'center', marginTop: '1.5rem', cursor: 'pointer' }} onClick={handleClearAll} role="button" aria-label={t('ProfileSearch_Reset')}>
                                                    {t('ProfileSearch_Reset')}
                                                </Typography>

                                            </CustomTabPanel>

                                        </CardContent>

                                    </Card>

                                </Item>
                            </Box>
                        </Grid>

                        {/* Right column - agent search results */}
                        <Grid item xs={12} sm={8}>

                            <Item className="gridItem" sx={{ boxShadow: 'none !important' }}>

                                {/* Agents tab */}
                                <CustomTabPanel value={searchTypeValue} index={0}>

                                    {searchType === PAGE_TYPE.AGENT && (

                                        <>

                                            <Stack direction="column" mb={2} sx={{ width: '100%' }}>

                                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>

                                                    <Typography className="body1-medium" variant="body1" aria-live="polite" role="status" sx={{ width: '100%' }}>
                                                        {(totalMatchCount === 0) ? (

                                                            //No Results
                                                            <div style={{ textAlign: 'center', background: 'white', width: '100%', padding: '3rem' }}>
                                                                <Typography className="h5 rtl-text-center" variant="h5" sx={{ width: '100%' }}>{t('ProfileSearch_NoResultsInAgentSearch')}</Typography>
                                                            </div>

                                                        ) : (

                                                            //Total Matches
                                                            <Typography className="body1-medium" variant="body1" sx={{ fontFamily: 'var(--font-family) !important' }}>
                                                                {t('ProfileSearch_AgentsIn')} {siteSettings?.CountryName}: {formatNumberWithRegionSetting(totalMatchCount, siteSettings)} {t('ProfileSearch_TotalMatches')}
                                                            </Typography>
                                                        )}
                                                    </Typography>

                                                    {/* Agents tab */}
                                                    <Stack direction="row">

                                                        {/* List View Button - Hidden Until Phase 2 */}
                                                        {/*<IconButton sx={{ display: 'none' }} role="button" aria-label="Search Result button" className="listings-map-tabs" onClick={() => setTabValue(0)} color={tabValue === 0 ? 'primary' : 'default'}>
                                                            <ListIcon />
                                                        </IconButton>*/}

                                                        {/* Map View Button - Hidden Until Phase 2 */}
                                                        {/*<IconButton sx={{ display: 'none' }} role="button" aria-label="Search Result button" className="listings-map-tabs" onClick={() => setTabValue(1)} color={tabValue === 1 ? 'primary' : 'default'}>
                                                            <MapIcon />
                                                        </IconButton>*/}

                                                    </Stack>

                                                </Stack>

                                            </Stack>

                                            {/* Agents List (component) */}
                                            <ProfileSearchAgentList
                                                searchType={searchType}
                                                country={countryName}
                                                countryId={countryId}
                                                name={debouncedAgentName}
                                                spokenLanguageUID={spokenLanguageUID}
                                                page={pageNumber}
                                                onTotalMatchCount={handleTotalMatchCount}
                                                onTotalPageCount={handleTotalPageCount}
                                                siteSettings={siteSettings}
                                                onPageChange={onPageChange}
                                            />

                                            {/* Pagination (component) To Do: Set Page Count as Dynamic*/}
                                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" useFlexGap flexWrap="wrap">
                                                {totalMatchCount > 10 && (
                                                    <Pagination count={totalPageCount} shape="rounded" onChange={handleChangePagination} />
                                                )}
                                            </Stack>

                                        </>
                                    )}

                                </CustomTabPanel>

                                {/* Offices tab */}
                                <CustomTabPanel value={searchTypeValue} index={1}>

                                    {searchType === PAGE_TYPE.OFFICE && (
                                        <>
                                            <Stack direction="column" mb={2} sx={{ width: '100%' }}>

                                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>

                                                    <Typography className="body1-medium" variant="body1" aria-live="polite" role="status" sx={{ width: '100%' }}>
                                                        {(totalMatchCount === 0) ? (

                                                            //No Results
                                                            <div style={{ textAlign: 'center', background: 'white', width: '100%', padding: '3rem' }}>
                                                                <Typography className="h5" variant="h5" sx={{ width: '100%' }}>{t('ProfileSearch_NoResultsInOfficeSearch')}</Typography>
                                                            </div>

                                                        ) : (

                                                            //Total Matches
                                                            <Typography className="body1-medium" variant="body1">
                                                                {t('ProfileSearch_OfficesIn')} {siteSettings?.CountryName}: {formatNumberWithRegionSetting(totalMatchCount, siteSettings)} {t('ProfileSearch_TotalMatches')}
                                                            </Typography>

                                                        )}
                                                    </Typography>

                                                    <Stack direction="row">

                                                        {/* List View Button - Hidden Until Phase 2 */}
                                                        {/*<IconButton sx={{ display: 'none' }} role="button" aria-label="Search Result button" className="listings-map-tabs" onClick={() => setOfficeTabValue(0)} color={tabOfficeValue === 0 ? 'primary' : 'default'}>
                                                            <ListIcon />
                                                        </IconButton>*/}

                                                        {/* Map View Button - Hidden Until Phase 2 */}
                                                        {/*<IconButton sx={{ display: 'none' }} role="button" aria-label="Search Result button" className="listings-map-tabs" onClick={() => setOfficeTabValue(1)} color={tabOfficeValue === 1 ? 'primary' : 'default'}>
                                                            <MapIcon />
                                                        </IconButton>*/}

                                                    </Stack>

                                                </Stack>

                                            </Stack>


                                            {/* Offices List (component) */}
                                            <ProfileSearchOfficeList
                                                searchType={searchType}
                                                country={countryName}
                                                countryId={countryId}
                                                name={debouncedOfficeName}
                                                spokenLanguageUID={spokenLanguageUID}
                                                page={pageNumber}
                                                onTotalMatchCount={handleTotalMatchCount}
                                                onTotalPageCount={handleTotalPageCount}
                                                siteSettings={siteSettings}
                                            />

                                            {/* Pagination (component) To Do: Set Page Count as Dynamic */}
                                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" useFlexGap flexWrap="wrap">
                                                {totalMatchCount > 10 && (
                                                    <Pagination count={totalPageCount} shape="rounded" onChange={handleChangePagination} />
                                                )}
                                            </Stack>
                                        </>
                                    )}

                                </CustomTabPanel>

                            </Item>

                        </Grid>

                    </Grid>

                </Container>

            </div>

        </>

    );
};

export default ProfileSearch;
