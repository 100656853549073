import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Card, CardContent, CardMedia, Chip, IconButton, Hidden, Link, Paper, Stack, Typography, Tooltip, Box, Skeleton } from '@mui/material';
import { SquareFoot as SquareFootIcon, ViewQuilt as ViewQuiltIcon, Panorama as PanoramaIcon, ThreeSixty as ThreeSixtyIcon, Image as ImageIcon, YouTube as YouTubeIcon, Bed as BedIcon, Shower as ShowerIcon, Favorite as FavoriteIcon, Share as ShareIcon, Map as MapIcon, Favorite, FavoriteBorder, Height } from '@mui/icons-material';
import { formatCurrency } from '../utils/helper/FormatNumberHelper';
import LeafletMap from '../utils/LeafletMap';
import { getListingImagePath, getListingDefaultImagePath } from '../utils/helper/ImageHelper';
import ListingDetail from '../../types/Listing/ListingDetail';
import ListingStatusChips from '../../components/utils/ListingStatusChips';
import LookupItem from '../../types/LookupItem';
import SiteSettings from '../../types/Settings/SiteSettings';
import { getLookupTranslationById } from '../utils/helper/LookupHelper';
import { MAP_PROVIDER, MAPZOOM, PAGE_TYPE, PROPERTY_TYPES, TRANSACTION_TYPES } from '../../constants/constants';
import MapBoxMap from '../utils/MapBoxMap';
import { use } from 'i18next';
import VideocamIcon from '@mui/icons-material/Videocam';



interface FavoriteItem {
    id: string;
    price: string;
    fullAddress: string;
    imagePath: string;
    propertyType: string;
}

interface CustomImageGalleryItem extends ReactImageGalleryItem {
    original: string;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const ListingCard: React.FC<{ listingDetail: ListingDetail, lookupList: LookupItem[], currency: string, showMapAndImage?: boolean, singleImage?: boolean, siteSettings?: SiteSettings }> = (
    { listingDetail, lookupList, currency, showMapAndImage = true, singleImage = false, siteSettings }) => {


    // Parameters Declaration
    const imagepath = process.env.REACT_APP_USERIMAGES;
    const { t } = useTranslation();
    const ListingLocation = listingDetail?.Location;
    let numberOfImages = 10;
    const { i18n } = useTranslation();
    const languageCode = i18n.language;
    if (singleImage === true) {
        numberOfImages = 1;
    }
    const defaultMap = siteSettings?.DefaultMapProvider ?? MAP_PROVIDER.LEAFLET

    // State Declaration 
    const [isHovered, setIsHovered] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [favoriteTooltip, setFavoriteTooltip] = useState(t('ListingFull_AddToFavouritesToolTip'));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [hideMap, setHideMap] = useState(false);

    useEffect(() => {
        ListingLocation?.coordinates[0] === 0 && ListingLocation?.coordinates[1] === 0 ? setHideMap(true) : setHideMap(false);
    }
        , [ListingLocation]);

    // Event Handler 
    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Stop propagation of the click event to the parent link
        event.stopPropagation();

    };
    const handleShowMap = () => setShowMap(!showMap);

    const handleFavoriteClick = (id: string, price: number, fullAddress: string, imagePath: string, propertyType?: string, listingShortLink?: string, listingCurrency?: string, rentalPriceGranularityUID?: string, transactionTypeUID?: string) => {
        // Get existing favorites from localStorage
        const existingFavorites: any[] = JSON.parse(localStorage.getItem('favorites') || '[]');
        // Check if the current item is already in the favorites list
        const isCurrentlyFavorite = existingFavorites.some((item: any) => item.id === id);
        if (isCurrentlyFavorite) {
            // If the item is already in the favorites list, remove it
            const updatedFavorites = existingFavorites.filter((item: any) => item.id !== id);
            // Save the updated favorites back to localStorage
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
            setIsFavorite(false); // Set isFavorite to false
            setFavoriteTooltip(t('ListingFull_AddToFavouritesToolTip')); // Reset tooltip message
        } else {
            // If the item is not in the favorites list, add it
            const favoriteItem = { id, price, fullAddress, imagePath, propertyType, listingShortLink, listingCurrency, rentalPriceGranularityUID, transactionTypeUID };
            const updatedFavorites = [favoriteItem, ...existingFavorites];
            // Save the updated favorites back to localStorage
            localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
            setIsFavorite(true); // Set isFavorite to true
            setFavoriteTooltip(t('ListingFull_RemoveToFavouritesToolTip')); // Update tooltip message
        }
    };

    const handleSlide = (index: number) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const checkFavoriteStatus = () => {
            const favorites: FavoriteItem[] = JSON.parse(localStorage.getItem('favorites') || '[]');
            const isCardFavorite = favorites.some((item: FavoriteItem) => item.id === listingDetail?.MLSID);
            setIsFavorite(isCardFavorite);
            if (isCardFavorite) {
                setFavoriteTooltip(t('ListingFull_RemoveToFavouritesToolTip')); // Set tooltip message if already a favorite
            }
            setLoading(true); // Set loading to true when fetching favorites
            setTimeout(() => setLoading(false), 1000); // Simulate loading delay of 1500ms
        };
        checkFavoriteStatus();
    }, [listingDetail?.MLSID]);

    const galleryItems = (listingDetail.ListingImages == null || listingDetail.ListingImages.length === 0) ?
        [{ original: getListingDefaultImagePath() }] :
        listingDetail.ListingImages.slice(0, numberOfImages).map(item => ({
            original: getListingImagePath(item.FileName, listingDetail.RegionId, item.HasLargeImage, item.IsWatermarked),
        }));

    const currentUrl = window.location.href; 
    const url = new URL(currentUrl);
    const hostUrl = `${url.protocol}//${url.host}/`; 

    let listingShortLink = hostUrl + listingDetail.MLSID;
    if (listingDetail.ShortLinks !== null
        && listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode) !== null
        && listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode).length > 0)
        listingShortLink = hostUrl + listingDetail.ShortLinks.filter(s => s.LanguageCode === languageCode)[0].ShortLink;

    let listingFullAddress = listingDetail.FullAddress;
    if (listingDetail.GeoDatas !== null
        && listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode) !== null
        && listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode).length > 0)
        listingFullAddress = listingDetail.GeoDatas.filter(geo => geo.LanguageCode === languageCode)[0].FullAddress;

    const renderCustomItem = (item: CustomImageGalleryItem) => {
        const currentIndex = galleryItems.findIndex(img => img.original === item.original);
        return (
            <div className="image-gallery-image" style={{ position: 'relative' }}>
                <Link title={listingDetail.StreetName} aria-label={listingDetail.StreetName} target="_blank" href={`${listingShortLink}`} >
                    <Box
                        component="img"
                        sx={{
                            aspectRatio: '3 / 2',
                            width: { xs: '100%' },
                            height: 'auto',
                            borderRadius: '1rem'
                        }}
                        src={item.original}
                        title={listingDetail.StreetName}
                        alt={listingDetail.StreetName}
                        loading="lazy"
                    />
                </Link>
            </div>
        );
    };

    return (
        <div>

            <Box position="relative">

                <Card sx={{ background: 'transparent!important', boxShadow: 'none', position: 'relative' }}>

                    {loading ? (

                        <>
                            <Skeleton variant="rectangular" width="100%" height={260} animation="wave" sx={{ borderRadius: '1rem' }} />

                            <CardContent>

                                <Stack direction="column" spacing={1}>

                                    <Skeleton variant="text" width="100%" height={20} animation="wave" />
                                    <Skeleton variant="text" width="100%" height={20} animation="wave" />
                                    <Skeleton variant="text" width="100%" height={20} animation="wave" />

                                </Stack>

                            </CardContent>

                        </>

                    ) : (

                        <div>
                            {/* Map or Images Container */}
                            {galleryItems.length > 0 && (

                                <div style={{ position: 'relative', borderRadius: '1rem !important' }}>


                                    <CardMedia>

                                        {showMap ? (

                                            <Link underline="none" target="_blank" href={`${listingShortLink}`} sx={{ textDecoration: 'none !important' }} className='listing-search-card-map-container'>

                                                {defaultMap === MAP_PROVIDER.MAPBOX && (
                                                    <MapBoxMap longitude={ListingLocation?.coordinates[0]} latitude={ListingLocation?.coordinates[1]} zoom={MAPZOOM.TWELVE} height={'250px'} width={'100%'} zoomControl={false} />
                                                )}

                                                {defaultMap === MAP_PROVIDER.LEAFLET && (
                                                    <LeafletMap longitude={ListingLocation?.coordinates[1]}
                                                        latitude={ListingLocation?.coordinates[0]}
                                                        zoom={MAPZOOM.TWELVE} scrollWheelZoom={true} height={'250px'} width={'100%'} zoomControl={false} />
                                                )}

                                            </Link>

                                        ) : (

                                            <div
                                                style={{ position: 'relative' }}
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                            >
                                                <ImageGallery
                                                    items={galleryItems}
                                                    renderItem={renderCustomItem}
                                                    showNav={isHovered} // Show navigation arrows only when hovered
                                                    showPlayButton={false}
                                                    showFullscreenButton={false}
                                                    showThumbnails={false}
                                                    onSlide={handleSlide}
                                                    lazyLoad={true}
                                            
                                                />

                                                {/* Image Slider Pagination */}
                                                {singleImage === false && (
                                                    <Stack direction="row" position="absolute" bottom={16} left={16} right={16} spacing={1} zIndex={2} width="auto" display="flex" justifyContent="space-between" alignItems="flex-end" >
                                                        <Chip sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)!important', color: 'white' }} label={`${currentIndex + 1}/${galleryItems.length}`} />
                                                        {/* <Tooltip title={t('')}> */}
                                                            {/* <Box style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', height: '40px', width: '40px' , borderRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <VideocamIcon />
                                                            </Box> */}
                                                        {/* </Tooltip> */}
                                                    </Stack>
                                                )}

                                            </div>

                                        )}

                                    </CardMedia>

                                </div>

                            )
                            }

                            <Link title={listingDetail.StreetName} aria-label={listingDetail.StreetName} underline="none" target="_blank" href={`${listingShortLink}`} sx={{ textDecoration: 'none !important' }}>

                                <CardContent>

                                    <Stack direction="column" spacing={1}>

                                        {/* Price, Bedrooms, and Bathrooms */}
                                        <Stack direction="row" spacing={2} justifyContent="space-between" useFlexGap flexWrap="wrap">

                                            {/* Price */}
                                            <Typography variant='body1' className="body1-medium text-align-left">
                                                {listingDetail.ListingPrice === 0 ? t("ListingFull_UponRequest") : <span dangerouslySetInnerHTML={{ __html: formatCurrency(listingDetail.ListingPrice, listingDetail.ListingCurrency, siteSettings) }} />}
                                                {' '}
                                                {listingDetail.ListingPrice !== 0 && listingDetail.RentalPriceGranularityUID !== null && listingDetail.TransactionTypeUID == TRANSACTION_TYPES.FORRENT.toString()
                                                    ? (getLookupTranslationById(listingDetail.RentalPriceGranularityUID.toString(), lookupList)) : ''}
                                            </Typography>

                                            {/* Listing Attributes */}
                                            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">

                                                {/* Bathrooms */}
                                                {listingDetail.NumberOfBedrooms !== null && listingDetail.ListingClass.toString() === PROPERTY_TYPES.RESIDENTIAL.toString() && (
                                                    <Stack direction="row" spacing={0.5} useFlexGap>

                                                        <Tooltip title={t('ListingFull_Bedrooms')}>
                                                            <BedIcon sx={{ color: 'var(--body-text-color)' }} />
                                                        </Tooltip>
                                                        <Typography className="body1 text-align-left" variant="body1">{listingDetail.NumberOfBedrooms}</Typography>
                                                    </Stack>
                                                )}

                                                {/* Bathrooms */}
                                                {listingDetail.NumberOfBathrooms !== null && listingDetail.ListingClass.toString() === PROPERTY_TYPES.RESIDENTIAL.toString() && (
                                                    <Stack direction="row" spacing={0.5} useFlexGap>

                                                        <Tooltip title={t('ListingFull_TotalBathrooms')}>
                                                            <ShowerIcon sx={{ color: 'var(--body-text-color)' }} />
                                                        </Tooltip>
                                                        <Typography className="body1 text-align-left" variant="body1">{listingDetail.NumberOfBathrooms}</Typography>

                                                    </Stack>
                                                )}

                                                {/* Total Rooms */}
                                                {listingDetail.TotalNumOfRooms !== null && listingDetail.ListingClass.toString() === PROPERTY_TYPES.RESIDENTIAL.toString() && (
                                                    <Stack direction="row" spacing={0.5} useFlexGap>

                                                        <Tooltip title={t('ListingFull_TotalRooms')}>
                                                            <ViewQuiltIcon sx={{ color: 'var(--body-text-color)' }} />
                                                        </Tooltip>
                                                        <Typography className="body1 text-align-left" variant="body1">{listingDetail.TotalNumOfRooms}</Typography>
                                                    </Stack>
                                                )}

                                                {/* Total Area */}
                                                {listingDetail.TotalArea !== null && listingDetail.TotalArea > 0 && (
                                                    <Stack direction="row" spacing={0.5} useFlexGap>

                                                        <Tooltip title={t('ListingFull_LivingSqM')}>
                                                            <SquareFootIcon sx={{ color: 'var(--body-text-color)' }} />
                                                        </Tooltip>
                                                        <Typography className="body1 text-align-left" variant="body1">{listingDetail.TotalArea}</Typography>
                                                    </Stack>
                                                )}

                                                {(listingDetail.TotalArea === null || listingDetail.TotalArea <= 0) && (listingDetail.LotSize2 !== null && listingDetail.LotSize2 > 0 ) && (
                                                    <Stack direction="row" spacing={0.5} useFlexGap>

                                                        <Tooltip title={t('ListingFull_LotSizeM2')}>
                                                            <SquareFootIcon sx={{ color: 'var(--body-text-color)' }} />
                                                        </Tooltip>
                                                        <Typography className="body1 text-align-left" variant="body1">{listingDetail.LotSize2}</Typography>
                                                    </Stack>
                                                )}

                                            </Stack>

                                        </Stack>

                                        {/* Property Type */}
                                        <Stack direction="column" spacing={1}>

                                            <Typography variant="body2" className="body2 secondary-text text-align-left">
                                                {getLookupTranslationById(listingDetail.PropertyTypeUID.toString(), lookupList)}
                                            </Typography>

                                            {/* Full Address */}
                                            {listingDetail.FullAddress !== null && (
                                                <Typography variant="body2" className="body2 secondary-text text-align-left">
                                                    {listingFullAddress}
                                                </Typography>
                                            )}

                                        </Stack>

                                    </Stack>

                                </CardContent>

                            </Link>

                        </div>

                    )}

                </Card>

                {/* Chips and Buttons On Image */}
                <div className="listing-card-buttons" style={{ position: 'absolute', top: '0', padding: '1rem', zIndex: 2 }}>

                    <Stack direction="row" alignItems="normal" justifyContent="space-between">

                        {/* Global Listing Status Chips (component) */}
                        <Link underline="none" target="_blank" href={`${listingShortLink}`} sx={{ textDecoration: 'none !important' }}>
                            <ListingStatusChips listingDetail={listingDetail} lookupList={lookupList} siteSettings={siteSettings} />
                        </Link>

                    </Stack>

                </div>

                {/* Favourite and Map Button */}
                <div className="favourite-map-buttons-container">

                    <Stack direction="column" spacing={1}>

                        {/* Favourite Button */}
                        <Tooltip title={isFavorite ? t('ListingFull_RemoveToFavouritesToolTip') : t('ListingFull_AddToFavouritesToolTip')} placement="left">
                            <IconButton className="card-button-icons" aria-label={t('ListingFull_AddToFavouritesToolTip')}
                                onClick={() =>
                                    handleFavoriteClick(
                                        listingDetail?.MLSID
                                        , listingDetail?.ListingPrice
                                        , listingDetail?.FullAddress
                                        , (listingDetail.ListingImages == null || listingDetail.ListingImages.length == 0) ?
                                            getListingDefaultImagePath() :
                                            getListingImagePath(
                                                listingDetail.ListingImages[0].FileName
                                                , listingDetail.RegionId
                                                , listingDetail.ListingImages[0].HasLargeImage
                                                , listingDetail.ListingImages[0].IsWatermarked
                                            )
                                        , getLookupTranslationById(listingDetail?.PropertyTypeUID.toString(), lookupList)
                                        , listingShortLink
                                        , listingDetail?.ListingCurrency
                                        , listingDetail?.RentalPriceGranularityUID
                                        , listingDetail?.TransactionTypeUID
                                    )}>
                                {isFavorite ? <Favorite color="error" /> : <Favorite />}
                            </IconButton>
                        </Tooltip>

                        {/* Map Button Hidden on Mobile */}
                        <Hidden smDown>
                            {hideMap === false && showMapAndImage && !showMap && (
                                <Tooltip title={t('ListingFull_Map')} placement="left">
                                    <IconButton className="card-button-icons" aria-label={t('ListingFull_Map')} onClick={handleShowMap}>
                                        <MapIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                        </Hidden>

                        {/* Images Button */}
                        {showMapAndImage && showMap && (
                            <Tooltip title={t('ListingFull_Photo')} placement="left">
                                <IconButton className="card-button-icons" aria-label={t('ListingFull_Photo')} onClick={handleShowMap}>
                                    <ImageIcon />
                                </IconButton>
                            </Tooltip>
                        )}

                    </Stack>

                </div>

            </Box>

        </div>
    );
};

export default ListingCard;
